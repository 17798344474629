import { useNavigate } from "react-router-dom";
import PTButton from "../../../components/GenericComponents/PTButton";
import LeftLogo from '../../../components/LeftLogo';

// Main landing page to redirect to vc and propech
function VCLanding() {
  const navigate = useNavigate()

  return (
    <div className="w-screen flex proptech-layout-wrapper flex-wrap">
      <div className="w-full md:w-3/5">
        <LeftLogo />
      </div>
      <div className="md:min-h-screen w-full md:w-2/5  items-center inline-flex relative">
        <div className="wrapper-container">
          <div className="flex flex-col">
            <div className="text-wrapper"><h2>Let’s get started!</h2></div>
            <PTButton label='Sign Up' className="my-5 normal-case fill-orange btn " size='large' handleOnClickEvent={() => navigate('/investor/signup')} />
            <PTButton label='Login' variant='outlined' className="normal-case btn" size='large' color='primary' handleOnClickEvent={() => window.location.href = '/investor/login'} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VCLanding;
