import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Landing from '../pages/Landing';

// VC
import VCPublicRoute from '../vc/routes/PublicRoute';
import VCPrivateRoute from '../vc/routes/PrivateRoute';
import VCLanding from '../vc/pages/Landing';
import Interest from '../vc/pages/Interest';
import Login from '../vc/pages/Login';
import Signup from '../vc/pages/Signup';
import ResetPassword from '../vc/pages/ResetPassword';
import ForgotPassword from '../vc/pages/ForgotPassword';
import CheckEmail from '../vc/pages/CheckEmail';
import VCEmailVerification from '../vc/pages/EmailVerification';
import Dashboard from '../vc/pages/Dashboard';
import CompanyProfile from '../vc/pages/CompanyProfile';

// Proptech
import ProptechPublicRoute from '../proptech/routes/PublicRoute';
import ProptechPrivateRoute from '../proptech/routes/PrivateRoute';
import ProptechLanding from '../proptech/pages/Landing';
import ProptechSignup from '../proptech/pages/Signup';
import ProptechSignupDetails from '../proptech/pages/SignupDetails';
import ProptechLogin from '../proptech/pages/Login';
import ProptechForgotPassword from '../proptech/pages/ForgotPassword';
import ProptechResetPassword from '../proptech/pages/ResetPassword';
import ProptechCheckEmail from '../proptech/pages/CheckEmail';
import ProptechEmailVerification from '../proptech/pages/EmailVerification';
import ProptechDashboard from '../proptech/pages/Dashboard';
import { getProptechLocalStorageUserData } from '../utils/session';

// Venture Scout
import VsPublicRoute from '../ventureScout/routes/PublicRoute';
import VsPrivateRoute from '../ventureScout/routes/PrivateRoute';
import VsLanding from '../ventureScout/pages/Landing';
import VsLogin from '../ventureScout/pages/Login';
import VsSignup from '../ventureScout/pages/Signup';
import VsForgotPassword from '../ventureScout/pages/ForgotPassword';
import VsResetPassword from '../ventureScout/pages/ResetPassword';
import VsCheckEmail from '../ventureScout/pages/CheckEmail';
import VsEmailVerification from '../ventureScout/pages/EmailVerification';
import VsDashboard from '../ventureScout/pages/VsDashboard';

// Admin
import AdminLogin from '../_admin/pages/Login';
import AdminDashboard from '../_admin/pages/Dashboard';
import AdminPrivateRoute from '../_admin/routes/PrivateRoute';
import AdminPublicRoute from '../_admin/routes/PublicRoute';
import { useSelector } from 'react-redux';
import Loader from '../components/Loading';
import ProptechDetail from '../vc/pages/ProptechDetail';
import NotFound from '../pages/NotFound';

function Router() {
  const user = getProptechLocalStorageUserData()
  const { isLoading } = useSelector(state => state.loader);

  return (
    <BrowserRouter>
      {isLoading ? <Loader /> : null}
      <Routes >
        <Route path="/" exact element={<Landing />} />
        {/* Investor Routes */}
        <Route path="/investor" exact element={<VCPublicRoute><VCLanding /></VCPublicRoute>} />
        <Route path="/investor/login" exact element={<VCPublicRoute><Login /></VCPublicRoute>} />
        <Route path="/investor/signup" exact element={<VCPublicRoute><Signup /></VCPublicRoute>} />
        <Route path="/investor/thanksforinterest" exact element={<VCPublicRoute><Interest /></VCPublicRoute>} />
        <Route path="/investor/forgotpassword" exact element={<VCPublicRoute><ForgotPassword /></VCPublicRoute>} />
        <Route path="/investor/resetpassword" exact element={<VCPublicRoute><ResetPassword /></VCPublicRoute>} />
        <Route path="/investor/checkemail" exact element={<VCPublicRoute><CheckEmail /></VCPublicRoute>} />
        <Route path="/investor/emailverification" exact element={<VCPublicRoute><VCEmailVerification /></VCPublicRoute>} />
        <Route path="/investor/dashboard" exact element={<VCPrivateRoute><Dashboard /></VCPrivateRoute>} />
        <Route path="/investor/companyprofile" exact element={<VCPrivateRoute><CompanyProfile /></VCPrivateRoute>} />
        <Route path="/investor/proptech/:proptechId" exact element={<VCPrivateRoute><ProptechDetail /></VCPrivateRoute>} />
        {/* Proptech Routes */}
        <Route path="/proptech" exact element={<ProptechPublicRoute><ProptechLanding /></ProptechPublicRoute>} />
        <Route path="/proptech/signup" exact element={<ProptechPublicRoute><ProptechSignup /></ProptechPublicRoute>} />
        <Route path="/proptech/login" exact element={<ProptechPublicRoute><ProptechLogin /></ProptechPublicRoute>} />
        <Route path="/proptech/forgotpassword" exact element={<ProptechPublicRoute><ProptechForgotPassword /></ProptechPublicRoute>} />
        <Route path="/proptech/resetpassword" exact element={<ProptechPublicRoute><ProptechResetPassword /></ProptechPublicRoute>} />
        <Route path="/proptech/checkemail" exact element={<ProptechPublicRoute><ProptechCheckEmail /></ProptechPublicRoute>} />
        <Route path="/proptech/emailverification" exact element={<VCPublicRoute><ProptechEmailVerification /></VCPublicRoute>} />
        <Route path="/proptech/dashboard" exact element={<ProptechPrivateRoute>{user?.isSignupCompleted ? <ProptechDashboard /> : <ProptechSignupDetails />}</ProptechPrivateRoute>} />
        {/* Venture Scout Routes */}
        <Route path="/venture-scout" exact element={<VsPublicRoute><VsLanding /></VsPublicRoute>} />
        <Route path="/venture-scout/login" exact element={<VsPublicRoute><VsLogin /></VsPublicRoute>} />
        <Route path="/venture-scout/signup" exact element={<VsPublicRoute><VsSignup /></VsPublicRoute>} />
        <Route path="/venture-scout/forgotpassword" exact element={<VsPublicRoute><VsForgotPassword /></VsPublicRoute>} />
        <Route path="/venture-scout/resetpassword" exact element={<VsPublicRoute><VsResetPassword /></VsPublicRoute>} />
        <Route path="/venture-scout/checkemail" exact element={<VsPublicRoute><VsCheckEmail /></VsPublicRoute>} />
        <Route path="/venture-scout/thanksforinterest" exact element={<VsPublicRoute><Interest /></VsPublicRoute>} />
        <Route path="/venture-scout/emailverification" exact element={<VCPublicRoute><VsEmailVerification /></VCPublicRoute>} />
        <Route path="/venture-scout/dashboard" exact element={<VsPrivateRoute><VsDashboard /></VsPrivateRoute>} />
        {/* Admin Routes*/}
        <Route path="/admin" exact element={<AdminPublicRoute><AdminLogin /></AdminPublicRoute>} />
        <Route path="/admin/dashboard" exact element={<AdminPrivateRoute><AdminDashboard /></AdminPrivateRoute>} />
        <Route path='*' element={<NotFound />} />
      </Routes >
    </BrowserRouter >
  );
}

export default Router;
