import React from 'react';
import PTButton from '../../../components/GenericComponents/PTButton';
import LeftLogo from '../../../components/LeftLogo';
import { CheckMailLabels } from '../../constants/general';

export default function CheckEmail() {
  return (
    <div className="w-screen flex proptech-layout-wrapper flex-wrap">
      <div className="w-full md:w-3/5">
        <LeftLogo />
      </div>
      <div className="md:min-h-screen w-full md:w-2/5 items-center inline-flex relative">
        <div className="wrapper-container">
          <div className="text-wrapper">
            <h2>{CheckMailLabels.title}</h2>
            <p className='p-0'>{CheckMailLabels.text}</p>
          </div>
          <div className='pt-[20px]'>
            <PTButton
              label={CheckMailLabels.backToLogin}
              className="normal-case fill-orange btn"
              size='large'
              handleOnClickEvent={() => window.location.href = '/venture-scout/login'}
            />
          </div>
          <div className="not-have-account">
            <span>
              {CheckMailLabels.needHand}
              <a href='mailto:support@theproptechconnection.com' className='block'>
                {CheckMailLabels.email}
              </a>
            </span>
          </div>
        </div>

      </div>
    </div>)
}
