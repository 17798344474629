import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Chart from '../../Chart';
import { KeyFeatureLabel, KeyFeatureLabelTooltip } from '../../Label';
import { raiseChartData, raiseChartLabels } from '../../../utils/chartData';
import InfoIcon from '../../../../components/InfoIcon';
import { Radar_Chart_Labels } from '../../../constants';
import { TooltipText } from '../../../../constants/General';

export default function RaiseTab() {
  // Store
  const { raiseDetails } = useSelector(state => state.vcProptechDetails)

  const [handleInfo, setHandleInfo] = useState([])

  return (
    <>
      {raiseDetails ?
        <div className="tabpanel-wrapper market-tab">
          <div className="left_col">
            <h2>Uniqueness</h2>
            <p>{raiseDetails?.proptechCompanyDetails?.investabilityAdmin || 'Not provided.'}</p>
            {/*Nmbers, round offering and alignment block */}
            <div className="detail-sector-wrapper">
              <div className="detail-sectors">
                <div className='flex items-start'>
                  <h2 className='pr-4'>Numbers</h2>
                  <InfoIcon
                    label='Numbers'
                    title='Numbers'
                    info={handleInfo}
                    handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                    handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                  />
                </div>
                <ul className="menu-item">
                  <KeyFeatureLabelTooltip title='Investment Round' icon={raiseDetails?.proptechCompanyDetails?.investmentRound?.icon} label={raiseDetails?.proptechCompanyDetails?.investmentRound?.name} />
                  <KeyFeatureLabelTooltip title='Investment Range' icon={raiseDetails?.proptechCompanyDetails?.investmentRange?.icon} label={raiseDetails?.proptechCompanyDetails?.investmentRange?.name} />
                  <KeyFeatureLabelTooltip title='Valuation Range' icon={raiseDetails?.proptechCompanyDetails?.valuationRange?.icon} label={raiseDetails?.proptechCompanyDetails?.valuationRange?.name} />
                </ul>
              </div>
              <div className="detail-sectors">
                <div className='flex items-start'>
                  <h2 className='pr-4'>Round offering</h2>
                  <InfoIcon
                    label='Round offering'
                    title='Round offering'
                    info={handleInfo}
                    handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                    handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                  />
                </div>
                <ul className="menu-item">
                  <KeyFeatureLabelTooltip title='Investment Type' icon={raiseDetails?.proptechCompanyDetails?.investmentType?.icon} label={raiseDetails?.proptechCompanyDetails?.investmentType?.name} />
                  <KeyFeatureLabelTooltip title='No. Of Round' icon={raiseDetails?.proptechCompanyDetails?.noOfRound?.icon} label={raiseDetails?.proptechCompanyDetails?.noOfRound?.name} />
                  <KeyFeatureLabelTooltip title='Raise Date' icon={raiseDetails?.raiseDateIcon} label={raiseDetails?.proptechCompanyDetails?.raiseDate ? moment(raiseDetails?.proptechCompanyDetails?.raiseDate, 'YYYY-MM-DD').format('DD MMM YYYY') : '-'} />
                </ul>
              </div>
              <div className="detail-sectors">
                <div className='flex items-start'>
                  <h2 className='pr-4'>Alignment</h2>
                  <InfoIcon
                    label={TooltipText.alignment}
                    title={TooltipText.alignment}
                    info={handleInfo}
                    handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                    handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                  />
                </div>
                <ul className="menu-item">
                  <KeyFeatureLabel icon={raiseDetails?.proptechAnalysisStyle1?.icon} label={raiseDetails?.proptechAnalysisStyle1?.name} />
                  <KeyFeatureLabel icon={raiseDetails?.proptechAnalysisTrait1?.icon} label={raiseDetails?.proptechAnalysisTrait1?.name} />
                  <KeyFeatureLabel icon={raiseDetails?.proptechAnalysisTrait2?.icon} label={raiseDetails?.proptechAnalysisTrait2?.name} />
                </ul>
              </div>
            </div>
          </div>
          {/*Raise chart block */}
          <div className="right_col" onClick={(e) => e.stopPropagation()}>
            <div className='flex items-start'>
              <h2 className='pr-4'>{Radar_Chart_Labels.raise}</h2>
              <InfoIcon
                label={TooltipText.raiseRadar}
                info={handleInfo}
                handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
              />
            </div>
            <div className="chart-wrapper">
              <Chart
                items={raiseChartData.map((val) => raiseDetails[val])}
                labels={raiseChartLabels}
                step={2}
                label={'Rating'}
                maximumValue={10}
              />
            </div>
          </div>

        </div>
        : null}
    </>
  )
}
