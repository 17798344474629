import { ADMIN_PROPTECHS, } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { dispatch } from "../../../utils/utility/store"
import { handleError, handleSuccess, } from "../../../utils/utility/snackbar"
import { numbersTabDTO } from "../dtos/numbersTab"
import { getGrossMarginData, getGrowthBlockersData, getNoOfCountriesData, getNoOfCustomersData } from "../dropdowns"

export const loadNumbersTabData = (id, adminAnalysisTab, adminDropdowns) => {
  if (!adminAnalysisTab.numbersTabData) {
    getNumbersDetails(id)
  }
  loadNumbersTabDropdowns(adminDropdowns.noOfCustomers, adminDropdowns.noOfCountries, adminDropdowns.grossMargins, adminDropdowns.growthBlockers)
}

export const getNumbersDetails = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/numbers`)
    .then(res => {
      dispatch(Actions.AdminUser.SetLoading, false)
      dispatch(Actions.AdminAnalysisTab.SetNumbersTabData, numbersTabDTO(res.data.data))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err)
    })
}

export const updateNumbersDetails = (id, data) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  return ADMIN_API().put(ADMIN_PROPTECHS + `/${id}/numbers`, data)
    .then(res => {
      handleSuccess(res)
      getNumbersDetails(id)
      return res?.status;
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err);
      return err?.response?.status;
    })
}

export const loadNumbersTabDropdowns = (noOfCustomers, noOfCountries, grossMargins, growthBlockers) => {
  if (!noOfCustomers.length) {
    getNoOfCustomersData();
  }
  if (!noOfCountries.length) {
    getNoOfCountriesData();
  }
  if (!grossMargins.length) {
    getGrossMarginData();
  }
  if (!growthBlockers.length) {
    getGrowthBlockersData();
  }
}