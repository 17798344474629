export const CheckMailLabels = {
    title: 'Check your email',
    text: 'If we have an account for this email address, you’ll receive an email with a reset link soon.',
    needHand: 'Need a hand? Email our support team at',
    email: 'support@theproptechconnection.com',
    backToLogin: 'Back to login',
}

export const ForgotPasswordLabels = {
    title: 'Reset your password',
    text: 'We’ll email you a secure link to change your password.',
    rememberYourPassword: 'Remembered your password?',
    backToLogin: 'Back to login',
    email: 'Email',
    sendResetLink: 'Send reset link',
    mail: 'email'
}

export const ResetPasswordLabels = {
    title: 'Reset password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm new password',
    submit: 'Submit'
}

export const LandingLabels = {
    title: 'Let’s get started!',
    signUp: 'Sign Up',
    login: 'Login'
}