import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { MenuItem, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PTButton from '../../../../components/GenericComponents/PTButton';
import PTInput from '../../../../components/GenericComponents/PTInput';
import { AnalysisLabelStyle, LabelStyle } from '../../../../proptech/constants/General';
import DropdownColumn from '../../DropdownColumn';
import { updateTeamDetails } from '../../../utils/middleware/teamTab';
import { uploadImage } from '../../../utils/middleware/companyTab';
import Loader from '../../../../components/Loading';
import ConfirmModal from '../../ConfirmModal';
import { handleError } from '../../../../utils/utility/snackbar';
import placeholderImage from '../../../../assets/Icons/placeholder.png';
import { TEAM_TAB_ERRORS, TEAM_TAB_PAYLOAD } from '../../../utils/payloads/payloads';
import { dispatch } from '../../../../utils/utility/store';
import { Actions } from '../../../../store/actions';
import { PlaceTextField } from '../../PlacesTextField';
import { trimQuery, urlValidate } from '../../../../utils/utility/utility';
import { COMPANY_ERROR_TEXT } from '../../../constants';
import { isUnsaveChanges } from '../../../utils/middleware/analysisTab';

export default function TeamTab(props) {
  const { setUnsaveChange } = props

  // Store
  const teamsTabData = useSelector(state => state.adminAnalysisTab.teamsTabData)
  const selectedProptech = useSelector(state => state.adminUser.selectedProptech)
  const isLoading = useSelector(state => state.adminUser.isLoading)
  const { noOfEmployees, teamSkills, keyHireMappings } = useSelector(state => state.adminDropdowns)

  // State
  const [editedDetails, setEditedDetails] = useState(TEAM_TAB_PAYLOAD);
  const [errors, setErrors] = useState(TEAM_TAB_ERRORS);
  const [selectedKey, setSelectedKey] = useState('');
  const [previewField, setPreviewField] = useState('');
  const [files, setFiles] = useState({
    award1Image: null,
    award2Image: null,
    award3Image: null,
  })
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false)
  const inputRef = useRef();

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    isUnsaveChanges(editedDetails, setUnsaveChange)
  }, [editedDetails]);  // eslint-disable-line react-hooks/exhaustive-deps

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails))
    Object.keys(data).forEach(error => {
      data = {
        ...data, [error]: editedDetails?.[error] !== null ?
          error === 'productVideo' ? !urlValidate(editedDetails?.[error]) : !editedDetails?.[error] :
          error === 'productVideo' ? !urlValidate(teamsTabData?.[error]) : !teamsTabData?.[error]
      }
    })
    setErrors(data)
    return Object.keys(data).some(k => data[k])
  }

  const handleImage = (e, key) => {
    if (e.target.files && e.target.files[0]) {
      setLoading(true)
      setFiles({ ...files, [key]: e.target.files[0] })
      uploadImage(e.target.files[0], 'proptech-company-award')
        .then(res => {
          setLoading(false);
          setEditedDetails({ ...editedDetails, [key]: res.data.fileName })
        })
        .catch((err) => {
          setLoading(false)
          handleError(err)
        })
    }
  }

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => {
        if (files.hasOwnProperty(key)) {
          newData[key] = teamsTabData?.[key]?.split("/").slice(-2).join("/")
        } else {
          newData[key] = teamsTabData?.[key]
        }
      });

    handleCloseModal()
    try {
      await updateTeamDetails(
        selectedProptech?.id,
        {
          ...Object.fromEntries(Object.entries(editedDetails).filter(([_, v]) => !!v)),
          ...Object.fromEntries(Object.entries(newData).filter(([_, v]) => !!v)),
        })
    } catch {
      dispatch(Actions.AdminUser.SetLoading, false)
      console.log('error')
    }
  }

  const handleSelect = async (address) => {
    let cityLatLng = {}
    await geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        cityLatLng = latLng
      })
      .catch((error) => console.error("Error", error))
    return cityLatLng
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className='h-full flex flex-col overflow-y-auto overflow-x-hidden'>
        <div className='w-full flex company-two-col-block'>
          <input
            style={{ display: 'none' }}
            id="file"
            ref={inputRef}
            type="file"
            className="form-control"
            value=''
            accept={['jpg', 'jpeg', 'png']
              .map(function (x) {
                return '.' + x;
              })
              .join(',')}
            onChange={(e) => handleImage(e, selectedKey)}
          />
          <div className='left-form-block'>
            <span className="admin-portal-title">Supporting Awards</span>
            <div className='flex justify-between form-item-wrap items-end'>
              <PTInput
                labelStyle={LabelStyle}
                variant="standard"
                label="Award 1"
                className="grow"
                placeholder=''
                disabled={loading}
                value={editedDetails?.award1 !== null ? editedDetails?.award1 : teamsTabData?.award1 || ''}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, award1: e.target.value })}
              />
              <div className='flex grow justify-center'>
                <PTButton
                  variant="contained"
                  label={!(loading && selectedKey === 'award1Image') && <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">Upload</span>}
                  className='w-[120px] h-[44px] !mr-4 !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]'
                  loading={loading && selectedKey === 'award1Image'}
                  handleOnClickEvent={() => {
                    inputRef.current.click()
                    setSelectedKey('award1Image')
                  }}
                />
                <PTButton
                  variant="contained"
                  label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Preview</span>}
                  className='w-[120px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]'
                  handleOnClickEvent={() => {
                    setPreviewField('award1Image')
                  }} />
              </div>
            </div>
            <div className='flex justify-between form-item-wrap items-end'>
              <PTInput
                labelStyle={LabelStyle}
                variant="standard"
                label="Award 2"
                className="grow"
                placeholder=''
                disabled={loading}
                value={editedDetails?.award2 !== null ? editedDetails?.award2 : teamsTabData?.award2 || ''}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, award2: e.target.value })}
              />
              <div className='flex grow justify-center'>
                <PTButton
                  variant="contained"
                  label={!(loading && selectedKey === 'award2Image') && < span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">Upload</span>}
                  className='w-[120px] h-[44px] !mr-4 !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]'
                  loading={loading && selectedKey === 'award2Image'}
                  handleOnClickEvent={() => {
                    inputRef.current.click()
                    setSelectedKey('award2Image')
                  }} />
                <PTButton
                  variant="contained"
                  label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Preview</span>}
                  className='w-[120px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]'
                  handleOnClickEvent={() => {
                    setPreviewField('award2Image')
                  }} />
              </div>
            </div>
            <div className='flex justify-between form-item-wrap items-end'>
              <PTInput
                labelStyle={LabelStyle}
                variant="standard"
                label="Award 3"
                className="grow"
                placeholder=''
                disabled={loading}
                value={editedDetails?.award3 !== null ? editedDetails?.award3 : teamsTabData?.award3 || ''}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, award3: e.target.value })}
              />
              <div className='flex grow justify-center'>
                <PTButton
                  variant="contained"
                  label={!(loading && selectedKey === 'award3Image') && <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">Upload</span>}
                  className='w-[120px] h-[44px] !mr-4 !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]'
                  loading={loading && selectedKey === 'award3Image'}
                  handleOnClickEvent={() => {
                    inputRef.current.click()
                    setSelectedKey('award3Image')
                  }} />
                <PTButton
                  variant="contained"
                  label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Preview</span>}
                  className='w-[120px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]'
                  handleOnClickEvent={() => {
                    setPreviewField('award3Image')
                  }} />
              </div>
            </div>
            <PTInput
              labelStyle={LabelStyle}
              variant="standard"
              label="Team video url"
              fullWidth
              placeholder=''
              className='mt-4'
              disabled={loading}
              value={editedDetails?.productVideo !== null ? editedDetails?.productVideo : teamsTabData?.productVideo || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, productVideo: e.target.value })}
              error={errors.productVideo}
              helperText={errors.productVideo && COMPANY_ERROR_TEXT.url}
            />
          </div>
          <div className='right-image-block'>
            {previewField && !loading && (files?.[previewField] || teamsTabData?.[previewField]) ?
              <div className='inner-image-block w-full'><img src={files?.[previewField] ? URL.createObjectURL(files?.[previewField]) : teamsTabData?.[previewField]} alt="Award" width={'100%'} height={'100%'} /></div> :
              <div className='inner-image-block'><img src={placeholderImage} alt="placeholder" /></div>}
          </div>
        </div>
        <div className='mt-12'>
          <span className="admin-portal-title">Locations</span>
          <div className='analysis-dropdown-wrapper horizontal mt-4 mx-0'>
            <div className='dropdown-items flex'>
              <div className="dropdown-inner-item">
                <PlaceTextField
                  handleSelect={(address) => {
                    handleSelect(address).then(res => setEditedDetails({ ...editedDetails, location1: address?.split(",")[0], location1Latitude: res?.lat, location1Longitude: res?.lng }))
                  }}
                  label="Location 1"
                  value={editedDetails?.location1 !== null ? editedDetails?.location1 : teamsTabData?.location1 || ''}
                  handleChange={(address) => setEditedDetails({ ...editedDetails, location1: address })}
                  error={errors.location1}
                  handleClearField={() => setEditedDetails({ ...editedDetails, location1: '' })}
                />
                <div className="mt-[3px] flex justify-between items-center">
                  <span className='font-sans font-medium text-xs text-left tracking-wide text-[#d32f2f]'>{errors.location1 && "Location 1 Required."}</span>
                </div>
              </div>
              <div className="dropdown-inner-item">
                <PlaceTextField
                  handleSelect={(address) => {
                    handleSelect(address).then(res => setEditedDetails({ ...editedDetails, location2: address?.split(",")[0], location2Latitude: res?.lat, location2Longitude: res?.lng }))
                  }}
                  label="Location 2"
                  value={editedDetails?.location2 !== null ? editedDetails?.location2 : teamsTabData?.location2 || ''}
                  handleChange={(address) => setEditedDetails({ ...editedDetails, location2: address })}
                  handleClearField={() => setEditedDetails({ ...editedDetails, location2: '' })}
                />
              </div>
              <div className="dropdown-inner-item">
                <PlaceTextField
                  handleSelect={(address) => {
                    handleSelect(address).then(res => setEditedDetails({ ...editedDetails, location3: address?.split(",")[0], location3Latitude: res?.lat, location3Longitude: res?.lng }))
                  }}
                  label="Location 3"
                  value={editedDetails?.location3 !== null ? editedDetails?.location3 : teamsTabData?.location3 || ''}
                  handleChange={(address) => setEditedDetails({ ...editedDetails, location3: address })}
                  handleClearField={() => setEditedDetails({ ...editedDetails, location3: '' })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-between analysis-dropdown-wrapper'>
          <div className="dropdown-items">
            <div className='dropdown-title not-italic text-[#111D43]'>Team Overview</div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Year Founded"
                inputFormat="yyyy"
                animateYearScrolling
                className='custom-date-picker'
                views={["year"]}
                maxDate={moment().format('YYYY')}
                value={editedDetails?.yearFounded !== null ? editedDetails?.yearFounded : teamsTabData?.yearFounded}
                onChange={(date) => setEditedDetails({ ...editedDetails, yearFounded: moment.tz(date, "Australia/Sydney").format('YYYY') })}
                renderInput={(params) => <TextField fullWidth {...params} variant="standard" InputProps={{ ...params.InputProps, style: { fontSize: '12px' } }} InputLabelProps={{ ...params.InputLabelProps, style: AnalysisLabelStyle }} sx={{
                  svg: { color: '#111D43' },
                  input: { color: '#111D43' },
                  label: { color: '#111D43' }
                }}
                  error={errors.yearFounded}
                  helperText={errors.yearFounded && "Year Founded Required."}
                />}
              />
            </LocalizationProvider>
            <PTInput
              labelStyle={AnalysisLabelStyle}
              className='custom-dropdown-icon'
              variant="standard"
              label="No. of Employees"
              InputPropsStyle={{ fontSize: '12px' }}
              fullWidth
              select
              disabled={loading}
              selectChildren={noOfEmployees.map(option => <MenuItem value={option.id}>{option.name}</MenuItem>)}
              placeholder=''
              value={editedDetails?.noOfEmployees !== null ? editedDetails?.noOfEmployees : teamsTabData?.noOfEmployees}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, noOfEmployees: e.target.value })}
              error={errors.noOfEmployees}
              helperText={errors.noOfEmployees && "No. of Employees Required."} />
            <PTInput
              labelStyle={AnalysisLabelStyle}
              className='custom-dropdown-icon'
              variant="standard"
              label="No. of Offices"
              InputPropsStyle={{ fontSize: '12px' }}
              fullWidth
              placeholder=''
              type="number"
              disabled={loading}
              value={editedDetails?.noOfOffices !== null ? editedDetails?.noOfOffices : teamsTabData?.noOfOffices || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, noOfOffices: e.target.value })}
              error={errors.noOfOffices}
              helperText={errors.noOfOffices && "No. of Offices Required."} />
          </div>
          <DropdownColumn
            header="Team Skills"
            dropdown1Label="skill 1"
            disabled={loading}
            dropdown1Options={teamSkills}
            dropdown1Value={editedDetails?.skill1 || teamsTabData?.skill1}
            dropdown1HandleChange={(e) => setEditedDetails({ ...editedDetails, skill1: e.target.value })}
            dropdown1Error={errors.skill1}
            dropdown1HelperText={errors.skill1 && "Skill 1 Required."}
            dropdown2Label="skill 2"
            dropdown2Options={teamSkills}
            dropdown2Value={editedDetails?.skill2 || teamsTabData?.skill2}
            dropdown2HandleChange={(e) => setEditedDetails({ ...editedDetails, skill2: e.target.value })}
            dropdown2Error={errors.skill2}
            dropdown2HelperText={errors.skill2 && "Skill 2 Required."}
            dropdown3Label="skill 3"
            dropdown3Options={teamSkills}
            dropdown3Value={editedDetails?.skill3 || teamsTabData?.skill3}
            dropdown3HandleChange={(e) => setEditedDetails({ ...editedDetails, skill3: e.target.value })}
            dropdown3Error={errors.skill3}
            dropdown3HelperText={errors.skill3 && "Skill 3 Required."}
          />
          <DropdownColumn
            header="Key Hire Mappings"
            dropdown1Label="Key hire mapping 1"
            disabled={loading}
            dropdown1Options={keyHireMappings}
            dropdown1Value={editedDetails?.keyHireMapping1 || teamsTabData?.keyHireMapping1}
            dropdown1HandleChange={(e) => setEditedDetails({ ...editedDetails, keyHireMapping1: e.target.value })}
            dropdown1Error={errors.keyHireMapping1}
            dropdown1HelperText={errors.keyHireMapping1 && "Key Hire Mapping 1 Required."}
            dropdown2Label="Key hire mapping 2"
            dropdown2Options={keyHireMappings}
            dropdown2Value={editedDetails?.keyHireMapping2 || teamsTabData?.keyHireMapping2}
            dropdown2HandleChange={(e) => setEditedDetails({ ...editedDetails, keyHireMapping2: e.target.value })}
            dropdown2Error={errors.keyHireMapping2}
            dropdown2HelperText={errors.keyHireMapping2 && "Key Hire Mapping 2 Required."}
            dropdown3Label="Key hire mapping 3"
            dropdown3Options={keyHireMappings}
            dropdown3Value={editedDetails?.keyHireMapping3 || teamsTabData?.keyHireMapping3}
            dropdown3HandleChange={(e) => setEditedDetails({ ...editedDetails, keyHireMapping3: e.target.value })}
            dropdown3Error={errors.keyHireMapping3}
            dropdown3HelperText={errors.keyHireMapping3 && "Key Hire Mapping 3 Required."}
          />
        </div>
        <div className='w-full flex justify-end bottom-btn-wrapper'>
          <PTButton variant="contained"
            label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Revert</span>}
            type="submit" className='w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]'
            handleOnClickEvent={() => setEditedDetails(TEAM_TAB_PAYLOAD)}
          />
          <PTButton
            variant="contained"
            label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider text-[#FFFFFF]">Save & Continue</span>}
            type="submit"
            className='w-[142px] h-[44px] rounded-xl border-solid bg-[#111D43]'
            handleOnClickEvent={() => !checkValidation() && handleOpenModal()}
          />
        </div>

        {openModal && <ConfirmModal open={openModal} handleCloseModal={handleCloseModal} handleSucces={updateData} />}
      </div>
    </>
  )
}
