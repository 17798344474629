import React, { useEffect, useState } from 'react';
import { Avatar, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PTButton from '../../../components/GenericComponents/PTButton';
import { getVsLocalStorageUserData, removeVsAccessToken, removeVsLocalStorageUserData } from '../../../utils/session';
import Logo from '../../../assets/Icons/ic-tringle-proptech.svg';
import { DateFormat } from '../../../constants/General';
import './VsNavbar.scss'
import { DASHBOARD, NAVBAR_MENU_STYLE } from '../../constants/dashboard';
import ChangePassword from '../../../components/ChangePassword';
import { changePassword } from '../../utils/middleware/Dashboard';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { formatTime } from '../../../utils/utility/utility';
import HelpMenu from '../../../components/HelpMenu';
import VideoModal from '../../../components/VideoModal';
import { INTRODUCTRY_VIDEO_URL } from '../../../vc/constants';

export default function VsNavbar(props) {
  // State
  const [userData, setUserData] = useState({})
  const [userMenu, setUserMenu] = useState()
  const [changePasswordModal, setChangePasswordModal] = useState(false)
  const [openVideoModal, setIsOpenVideoModal] = React.useState(false);
  const [helpMenu, setHelpMenu] = useState()
  const open = Boolean(helpMenu);
  const openUser = Boolean(userMenu);

  // Handle help menu
  const handleClick = (event) => {
    setHelpMenu(event.currentTarget);
  };
  const handleClose = () => {
    setHelpMenu(null);
  };

  // Set user data from local storage 
  useEffect(() => setUserData(getVsLocalStorageUserData()), [])

  const handleClick_u = (event) => {
    setUserMenu(event.currentTarget);
  };

  const handleClose_u = () => {
    setUserMenu(null);
  };

  const handleLogoutClick = () => {
    removeVsAccessToken()
    removeVsLocalStorageUserData()
    window.location.href = '/venture-scout/login';
  }

  const handleChangePassword = (payload, resetForm) => {
    changePassword(payload, resetForm, setChangePasswordModal)
  }

  return (
    <>
      <div className='bg-[#111D43] flex flex-row items-center justify-between lg:h-[120px] sm:h-[82px] h-[70px] pr-12 fixed inset-x-0 w-full z-[99] proptech-dashboard-header'>
        <div className='flex items-center'>
          <img src={Logo} alt="" className="lg:h-[120px] sm:h-[82px] h-[70px]" />
          <div className='sm:ml-7 ml-2 proptech-nav-main-title'>
            <h2 className='lg:text-[32px] md:text-[26px] sm:text-[22px] text-[20px] text-white'>{DASHBOARD.navbar.welcome} <span>{userData?.name}</span></h2>
            <p className='text-[#70778E] sm:text-[14px] text-[12px]'>{userData?.lastLoginAt ? formatTime(userData?.lastLoginAt, DateFormat.DayMonthFormat2) : '-'}</p>
          </div>
        </div>
        {/* Help menu */}
        <div className='flex items-center'>
          <Tooltip title="Help" className='mr-2 xsm:mr-4 lg:mr-6'>
            <IconButton
              onClick={(e) => handleClick(e)}
              size="small"
              sx={{ ml: 2, width: 40, height: 40 }}
              aria-controls={open ? 'help-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              className={open ? "open-menu" : " "}
            >
              <HelpOutlineIcon className='text-white' />
            </IconButton>
          </Tooltip>
          <div onClick={(e) => handleClick_u(e)} className='user-icon'>
            {userData?.name?.split(' ').map(word => word[0]).join('')}
          </div>
        </div>
      </div >
      {/* User menu */}
      {openUser && <Menu
        anchorEl={userMenu}
        id="user-menu"
        className="dropdown_menu"
        open={openUser}
        onClose={handleClose_u}
        onClick={handleClose_u}
        PaperProps={{
          elevation: 0,
          sx: NAVBAR_MENU_STYLE,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className="profile-wrapper">
          <div className="user-wrapper">
            <Avatar sx={{ width: 80, height: 80 }}>{userData?.name?.split(' ').map(word => word[0]).join('')}</Avatar>
            <h2>{userData?.name}</h2>
            <a href={`mailto:${userData?.email}`}>{userData?.email}</a>
            <div className="user-contact">
              {DASHBOARD.navbar.contactUs}
              <a href="mailto:hello@theproptechconnection.com">hello@theproptechconnection.com</a>
            </div>
            <PTButton label='Log Out' className="normal-case fill-gray btn" size='large' handleOnClickEvent={handleLogoutClick} />
          </div>
        </div>
        <MenuItem onClick={() => setChangePasswordModal(true)}>
          <em className='img-icon'><ManageAccountsOutlinedIcon style={{ color: "#70778E" }} /></em>
          <span>{DASHBOARD.navbar.changePass}</span>
        </MenuItem>
      </Menu>
      }

      {/* Help menu */}
      {open && <HelpMenu
        helpMenu={helpMenu}
        open={open}
        handleClose={handleClose}
        handleWatchMenuClick={() => setIsOpenVideoModal(true)}
      />}

      {/* <NotificationModal
        open={openNotification}
        loading={isLoading}
        anchor={notificationMenu}
        notifications={notifications}
        handleClose={closeNotificationModal}
        readNotification={readNotification}
      /> */}
      {changePasswordModal &&
        <ChangePassword
          open={changePasswordModal}
          handleClose={() => setChangePasswordModal(false)}
          handleSuccess={handleChangePassword}
          cancelButtonLabel="Cancel"
          cancelButtonStyle="modal-cancel-button"
          changeButtonLabel="Change"
          changeButtonStyle='modal-change-button' />}

      {openVideoModal && <VideoModal
        videoUrl={INTRODUCTRY_VIDEO_URL}
        openVideoModal={openVideoModal}
        closeVideoModal={() => setIsOpenVideoModal(false)}
      />}
    </>
  )
}
