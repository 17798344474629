import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { Tooltip } from '@mui/material';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import { KeyFeatureLabel, KeyFeatureLabelTooltip } from '../../Label';
import TeamMap from '../../TeamMap';
import InfoIcon from '../../../../components/InfoIcon';
import './style.scss';
import { TooltipText } from '../../../../constants/General';

export default function TeamTab() {
  // Store
  const { teamDetails } = useSelector(state => state.vcProptechDetails)
  const [handleInfo, setHandleInfo] = useState([])

  return (
    <>
      {teamDetails ?
        <div className="tabpanel-wrapper team-tab">
          <div className="left_col">
            <h2>About the team</h2>
            <p>{teamDetails?.proptechCompanyDetails?.teamStrengthAdmin}</p>
            {/*Team overview, Team skills, Key hire mapping and Supporting awards block */}
            <div className="detail-sector-wrapper">
              <div className="detail-sectors">
                <div className='flex items-start'>
                  <h2 className='pr-4'>Team overview</h2>
                  <InfoIcon
                    label='Team overview'
                    title='Team overview'
                    info={handleInfo}
                    handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                    handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                  />
                </div>
                <ul className="menu-item">
                  <KeyFeatureLabelTooltip title='Year founded' icon={teamDetails?.foundedYearIcon} label={teamDetails?.yearFounded} />
                  <KeyFeatureLabelTooltip title='No. of Employees' icon={teamDetails?.proptechAnalysisNoOfEmployee?.icon} label={teamDetails?.proptechAnalysisNoOfEmployee?.name} />
                  <KeyFeatureLabelTooltip title='No. of Offices' icon={teamDetails?.noOfOfficesIcon} label={teamDetails?.noOfOffices} />
                </ul>
              </div>
              <div className="detail-sectors">
                <div className='flex items-start'>
                  <h2 className='pr-4'>Team skills</h2>
                  <InfoIcon
                    label={TooltipText.teamSkills}
                    title={TooltipText.teamSkills}
                    info={handleInfo}
                    handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                    handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                  />
                </div>
                <ul className="menu-item">
                  <KeyFeatureLabel icon={teamDetails?.proptechAnalysisTeamSkill1?.icon} label={teamDetails?.proptechAnalysisTeamSkill1?.name} />
                  <KeyFeatureLabel icon={teamDetails?.proptechAnalysisTeamSkill2?.icon} label={teamDetails?.proptechAnalysisTeamSkill2?.name} />
                  <KeyFeatureLabel icon={teamDetails?.proptechAnalysisTeamSkill3?.icon} label={teamDetails?.proptechAnalysisTeamSkill3?.name} />
                </ul>
              </div>
              <div className="detail-sectors">
                <div className='flex items-start'>
                  <h2 className='pr-4'>Key hire mapping</h2>
                  <InfoIcon
                    label={TooltipText.keyHireMappings}
                    title={TooltipText.keyHireMappings}
                    info={handleInfo}
                    handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                    handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                  />
                </div>
                <ul className="menu-item">
                  <KeyFeatureLabel icon={teamDetails?.proptechAnalysisKeyHireMapping1?.icon} label={teamDetails?.proptechAnalysisKeyHireMapping1?.name} />
                  <KeyFeatureLabel icon={teamDetails?.proptechAnalysisKeyHireMapping2?.icon} label={teamDetails?.proptechAnalysisKeyHireMapping2?.name} />
                  <KeyFeatureLabel icon={teamDetails?.proptechAnalysisKeyHireMapping3?.icon} label={teamDetails?.proptechAnalysisKeyHireMapping3?.name} />
                </ul>
              </div>
              <div className="supporting-award w-full">
                <div className='flex items-start'>
                  <h2 className='pr-4'>Supporting awards</h2>
                  <InfoIcon
                    label='Supporting awards'
                    title='Supporting awards'
                    info={handleInfo}
                    handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                    handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                  />
                </div>
                <ul className="menu-item">
                  <Tooltip title={teamDetails?.award1} arrow >
                    {teamDetails?.award1Image ?
                      <li><img src={teamDetails?.award1Image} alt="Icon" /></li>
                      : <li><HideImageOutlinedIcon /></li>
                    }
                  </Tooltip>
                  {teamDetails?.award2Image && <Tooltip title={teamDetails?.award2} arrow >
                    <li><img src={teamDetails?.award2Image} alt="Icon" /></li></Tooltip>
                  }
                  {teamDetails?.award3Image && <Tooltip title={teamDetails?.award3} arrow >
                    <li><img src={teamDetails?.award3Image} alt="Icon" /></li></Tooltip>
                  }
                </ul>
              </div>
            </div>
          </div>
          {/*Product Video block */}
          <div className="right_col">
            <div className="detail-sectors">
              <h2>Video</h2>
              {
                teamDetails?.productVideo ?
                  <div className="video-wrapper">
                    <ReactPlayer controls url={teamDetails?.productVideo} width="100%" height="100%" />
                  </div>
                  : 'No video available'
              }
            </div>
          </div>
          {/*Map block */}
          {
            teamDetails?.locations.length ?
              <>
                <div className="detail-sectors supporting-award w-full pr-[12px]">
                  <h2>Locations</h2>
                  <TeamMap locations={teamDetails?.locations} />
                </div>
              </>
              : null
          }
        </div>
        : null}
    </>
  )
}
