import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { MenuItem, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PTButton from '../../../../components/GenericComponents/PTButton';
import PTInput from '../../../../components/GenericComponents/PTInput';
import Loader from '../../../../components/Loading';
import { AnalysisLabelStyle } from '../../../../proptech/constants/General';
import { updateRaiseOverviewDetails } from '../../../utils/middleware/raiseOverviewTab';
import { RAISE_OVERVIEW_TAB_ERRORS, RAISE_OVERVIEW_TAB_PAYLOAD } from '../../../utils/payloads/payloads';
import ConfirmModal from '../../ConfirmModal';
import { dispatch } from '../../../../utils/utility/store';
import { Actions } from '../../../../store/actions';
import { trimQuery } from '../../../../utils/utility/utility';
import { isUnsaveChanges } from '../../../utils/middleware/analysisTab';

export default function RaiseOverviewTab(props) {
  const { setUnsaveChange } = props

  // Store
  const raiseOverviewTabData = useSelector(state => state.adminAnalysisTab.raiseOverviewTabData)
  const selectedProptech = useSelector(state => state.adminUser.selectedProptech)
  const isLoading = useSelector(state => state.adminUser.isLoading)
  const { keyBizModels, investmentRounds, investmentRanges, valuationRanges, investmentTypes, grossMargins } = useSelector(state => state.adminDropdowns)

  // State
  const [editedDetails, setEditedDetails] = useState(RAISE_OVERVIEW_TAB_PAYLOAD);
  const [errors, setErrors] = useState(RAISE_OVERVIEW_TAB_ERRORS);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    isUnsaveChanges(editedDetails, setUnsaveChange)
  }, [editedDetails]);  // eslint-disable-line react-hooks/exhaustive-deps

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails))
    Object.keys(data).forEach(error => {
      data = {
        ...data, [error]: editedDetails?.[error] !== null ? !editedDetails?.[error] : !raiseOverviewTabData?.[error]
      }
    })
    setErrors(data)
    return Object.keys(data).some(k => data[k])
  }

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => (newData[key] = raiseOverviewTabData?.[key]));

    handleCloseModal()
    try {
      await updateRaiseOverviewDetails(
        selectedProptech?.id,
        {
          ...Object.fromEntries(Object.entries(editedDetails).filter(([_, v]) => v !== null)),
          ...Object.fromEntries(Object.entries(newData).filter(([_, v]) => !!v)),
        })
    } catch {
      dispatch(Actions.AdminUser.SetLoading, false)
      console.log('error')
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className='h-full flex flex-col overflow-y-auto'>
        <div className='w-full company-two-col-block'>
          <div className='left-form-block'>
            <div className='input-dropdown-field form-item-wrap'>
              <span>Current Pivotal Metric</span>
              <PTInput
                variant="standard"
                label="Current Pivotal Metric"
                className="custom-dropdown-icon"
                select
                selectChildren={keyBizModels.map(model => <MenuItem value={model.id}>{model.name}</MenuItem>)}
                placeholder=''
                value={editedDetails?.keyBizModelId || raiseOverviewTabData?.keyBizModelId}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, keyBizModelId: e.target.value })}
                error={errors.keyBizModelId}
                helperText={errors.keyBizModelId && "Current Pivotal Metric Required."}
              />
            </div>
            <div className='input-dropdown-field form-item-wrap'>
              <span>Committed</span>
              <PTInput
                variant="standard"
                label="Committed"
                className="custom-dropdown-icon"
                select
                selectChildren={grossMargins.map(model => <MenuItem value={model.id}>{model.name}</MenuItem>)}
                placeholder=''
                value={editedDetails?.committedId || raiseOverviewTabData?.committedId}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, committedId: e.target.value })}
                error={errors.committedId}
                helperText={errors.committedId && "Committed Required."}
              />
            </div>
            <div className='input-dropdown-field form-item-wrap'>
              <span>Prior Invest Round</span>
              <PTInput
                variant="standard"
                label="Prior Invest Round"
                className="custom-dropdown-icon"
                select
                selectChildren={investmentRounds.map(round => <MenuItem value={round.id}>{round.name}</MenuItem>)}
                placeholder=''
                value={editedDetails?.priorInvestmentRoundId || raiseOverviewTabData?.priorInvestmentRoundId}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, priorInvestmentRoundId: e.target.value })}
                error={errors.priorInvestmentRoundId}
                helperText={errors.priorInvestmentRoundId && "Prior Invest Round Required."}
              />
            </div>
            <div className='input-dropdown-field form-item-wrap'>
              <span>Prior Invest Range</span>
              <PTInput
                variant="standard"
                label="Prior Invest Range"
                className="custom-dropdown-icon"
                select
                selectChildren={investmentRanges.map(range => <MenuItem value={range.id}>{range.name}</MenuItem>)}
                placeholder=''
                value={editedDetails?.priorInvestmentRangeId || raiseOverviewTabData?.priorInvestmentRangeId}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, priorInvestmentRangeId: e.target.value })}
                error={errors.priorInvestmentRangeId}
                helperText={errors.priorInvestmentRangeId && "Prior Invest Range Required."} />
            </div>
            <div className='input-dropdown-field form-item-wrap'>
              <span>Prior Val Range</span>
              <PTInput
                variant="standard"
                label="Prior Val Range"
                className="custom-dropdown-icon"
                select
                selectChildren={valuationRanges.map(range => <MenuItem value={range.id}>{range.name}</MenuItem>)}
                placeholder=''
                value={editedDetails?.priorValuationRangeId || raiseOverviewTabData?.priorValuationRangeId}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, priorValuationRangeId: e.target.value })}
                error={errors.priorValuationRangeId}
                helperText={errors.priorValuationRangeId && "Prior Val Range Required."} />
            </div>
            <div className='input-dropdown-field form-item-wrap'>
              <span>Prior Invest Type</span>
              <PTInput
                variant="standard"
                label="Prior Invest Type"
                className="custom-dropdown-icon"
                select
                selectChildren={investmentTypes.map(type => <MenuItem value={type.id}>{type.name}</MenuItem>)}
                placeholder=''
                value={editedDetails?.priorInvestmentTypeId || raiseOverviewTabData?.priorInvestmentTypeId}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, priorInvestmentTypeId: e.target.value })}
                error={errors.priorInvestmentTypeId}
                helperText={errors.priorInvestmentTypeId && "Prior Invest Type Required."} />
            </div>
            <div className='input-dropdown-field form-item-wrap'>
              <span>Prior Pivotal Metric</span>
              <PTInput
                variant="standard"
                label="Prior Pivotal Metric"
                className="custom-dropdown-icon"
                select
                selectChildren={keyBizModels.map(model => <MenuItem value={model.id}>{model.name}</MenuItem>)}
                placeholder=''
                value={editedDetails?.priorKeyBizModelId || raiseOverviewTabData?.priorKeyBizModelId}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, priorKeyBizModelId: e.target.value })}
                error={errors.priorKeyBizModelId}
                helperText={errors.priorKeyBizModelId && "Prior Pivotal Metric Required."} />
            </div>
            <div className='input-dropdown-field form-item-wrap'>
              <span>Prior Raise Date</span>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Prior Raise Date"
                  className='custom-date-picker'
                  views={['year', 'month']}
                  inputFormat="MM/yyyy"
                  value={editedDetails?.priorRaiseDate || raiseOverviewTabData?.priorRaiseDate}
                  onChange={(date) => setEditedDetails({ ...editedDetails, priorRaiseDate: moment.tz(date, "Australia/Sydney").format('YYYY-MM-DD') })}
                  renderInput={(params) => <TextField fullWidth {...params} variant="standard" InputProps={{ ...params.InputProps, style: { fontSize: '12px' } }} InputLabelProps={{ ...params.InputLabelProps, style: AnalysisLabelStyle }} sx={{
                    svg: { color: '#111D43' },
                    input: { color: '#111D43' },
                    label: { color: '#111D43' }
                  }}
                    error={errors.priorRaiseDate}
                    helperText={errors.priorRaiseDate && "Prior Raise Date Required."}
                  />}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
        <div className='bottom-btn-wrapper flex justify-end'>
          <PTButton variant="contained"
            label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Revert</span>}
            type="submit" className='w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]'
            handleOnClickEvent={() => setEditedDetails(RAISE_OVERVIEW_TAB_PAYLOAD)}
          />
          <PTButton
            variant="contained"
            label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider text-[#FFFFFF]">Save & Continue</span>}
            type="submit"
            className='w-[142px] h-[44px] rounded-xl border-solid bg-[#111D43]'
            handleOnClickEvent={() => !checkValidation() && handleOpenModal()}
          />
        </div>
        {openModal && <ConfirmModal open={openModal} handleCloseModal={handleCloseModal} handleSucces={updateData} />}
      </div>
    </>
  )
}
