import { VC_PROPTECH_LISTING } from "../../../../constants/APIs"
import { Actions } from "../../../../store/actions"
import { Store } from "../../../../store/configureStore"
import { VC_API } from "../../../../utils/utility/API-call"
import { handleError } from "../../../../utils/utility/snackbar"
import { apiTimeoutTime } from "../../../constants"
import { companyProfileTabVCDTO } from "../../dtos/companyProfileDTO"
import { designReviewTabVCDTO } from "../../dtos/designReviewVC"
import { marketInsightsTabVCDTO } from "../../dtos/marketInsightsVC"
import { marketTabVCDTO } from "../../dtos/marketVC"
import { numbersTabVCDTO } from "../../dtos/numbersVC"
import { overviewTabVCDTO } from "../../dtos/overviewVC"
import { proptechDetailsDTO } from "../../dtos/proptechDetailsDTO"
import { raiseOverviewTabVCDTO } from "../../dtos/raiseOverviewVC"
import { raiseTabVCDTO } from "../../dtos/raiseVc"
import { teamTabVCDTO } from "../../dtos/teamTabVC"

export const getProptechDetailsAPI = (proptechCompanyDetailsId) => {
  return VC_API().get(`${VC_PROPTECH_LISTING}/${proptechCompanyDetailsId}`).then((res) => {
    Store.dispatch({ type: Actions.VCProptechDetails.setProptechDetails, payload: proptechDetailsDTO(res?.data?.data) });
    Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { proptechDetails: false } });
    setTimeout(() => {
      Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { proptechDetails: true } });
    }, apiTimeoutTime)
    if (res?.data?.data) {
      return res?.data?.data
    }
    if (res?.data?.code !== 200) {
      handleError(res?.data);
    }
  }).catch((err) => {
    handleError(err)
    return err?.status;
  })
}

export const getOverviewAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return VC_API().get(`${VC_PROPTECH_LISTING}/${proptechCompanyDetailsId}/overview`).then((res) => {
    setIsLoading(false)
    Store.dispatch({ type: Actions.VCProptechDetails.setOverviewDetails, payload: overviewTabVCDTO(res?.data?.data) });
    Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { overview: false } });
    setTimeout(() => {
      Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { overview: true } });
    }, apiTimeoutTime)
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    handleError(err)
    setIsLoading(false)
    return err?.status;
  })
}

export const getMarketAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return VC_API().get(`${VC_PROPTECH_LISTING}/${proptechCompanyDetailsId}/market`).then((res) => {
    setIsLoading(false)
    Store.dispatch({ type: Actions.VCProptechDetails.setMarketDetails, payload: marketTabVCDTO(res?.data?.data) });
    Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { market: false } });
    setTimeout(() => {
      Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { market: true } });
    }, apiTimeoutTime)
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    setIsLoading(false)
    handleError(err)
    return err?.status;
  })
}

export const getNumbersAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return VC_API().get(`${VC_PROPTECH_LISTING}/${proptechCompanyDetailsId}/numbers`).then((res) => {
    setIsLoading(false)
    Store.dispatch({ type: Actions.VCProptechDetails.setNumbersDetails, payload: numbersTabVCDTO(res?.data?.data) });
    Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { numbers: false } });
    setTimeout(() => {
      Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { numbers: true } });
    }, apiTimeoutTime)
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    setIsLoading(false)
    handleError(err)
    return err?.status;
  })
}

export const getRaiseAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return VC_API().get(`${VC_PROPTECH_LISTING}/${proptechCompanyDetailsId}/raise`).then((res) => {
    setIsLoading(false)
    Store.dispatch({ type: Actions.VCProptechDetails.setRaiseDetails, payload: raiseTabVCDTO(res?.data?.data) });
    Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { raise: false } });
    setTimeout(() => {
      Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { raise: true } });
    }, apiTimeoutTime)
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    setIsLoading(false)
    handleError(err)
    return err?.status;
  })
}

export const getTeamAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return VC_API().get(`${VC_PROPTECH_LISTING}/${proptechCompanyDetailsId}/team`).then((res) => {
    setIsLoading(false)
    Store.dispatch({ type: Actions.VCProptechDetails.setTeamsDetails, payload: teamTabVCDTO(res?.data?.data) });
    Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { team: false } });
    setTimeout(() => {
      Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { team: true } });
    }, apiTimeoutTime)
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    setIsLoading(false)
    handleError(err)
    return err?.status;
  })
}

export const getRaiseOverviewAPI = (proptechCompanyDetailsId) => {
  return VC_API().get(`${VC_PROPTECH_LISTING}/${proptechCompanyDetailsId}/raiseOverview`).then((res) => {
    Store.dispatch({ type: Actions.VCProptechDetails.setRaiseOverview, payload: raiseOverviewTabVCDTO(res?.data?.data) });
    Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { raiseOverview: false } });
    setTimeout(() => {
      Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { raiseOverview: true } });
    }, apiTimeoutTime)
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    handleError(err)
    return err?.status;
  })
}

export const getDesignReviewAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return VC_API().get(`${VC_PROPTECH_LISTING}/${proptechCompanyDetailsId}/designReview`).then((res) => {
    setIsLoading(false)
    Store.dispatch({ type: Actions.VCProptechDetails.setDesignReviewDetails, payload: designReviewTabVCDTO(res?.data?.data) });
    Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { designReview: false } });
    setTimeout(() => {
      Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { designReview: true } });
    }, apiTimeoutTime)
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    setIsLoading(false)
    handleError(err)
    return err?.status;
  })
}

export const getMarketInsightsAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return VC_API().get(`${VC_PROPTECH_LISTING}/${proptechCompanyDetailsId}/marketInsights`).then((res) => {
    setIsLoading(false)
    Store.dispatch({ type: Actions.VCProptechDetails.setMarketInsightsDetails, payload: marketInsightsTabVCDTO(res?.data?.data) });
    Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { marketInsights: false } });
    setTimeout(() => {
      Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { marketInsights: true } });
    }, apiTimeoutTime)
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    setIsLoading(false)
    handleError(err)
    return err?.status;
  })
}

export const getCompanyProfileAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return VC_API().get(`${VC_PROPTECH_LISTING}/${proptechCompanyDetailsId}/companyProfile`).then((res) => {
    setIsLoading(false)
    Store.dispatch({ type: Actions.VCProptechDetails.setCompanyProfileDetails, payload: companyProfileTabVCDTO(res?.data?.data) });
    Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { companyProfile: false } });
    setTimeout(() => {
      Store.dispatch({ type: Actions.VCProptechDetails.setApiTimeout, payload: { companyProfile: true } });
    }, apiTimeoutTime)
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    setIsLoading(false)
    handleError(err)
    return err?.status;
  })
}
