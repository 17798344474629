import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Chart from '../../Chart';
import { KeyFeatureLabel } from '../../Label';
import { marketChartData, marketChartLabels } from '../../../utils/chartData';
import InfoIcon from '../../../../components/InfoIcon';
import { Radar_Chart_Labels } from '../../../constants';
import { TooltipText } from '../../../../constants/General';

export default function MarketTab() {
  // Store
  const { marketDetails } = useSelector(state => state.vcProptechDetails)
  const [handleInfo, setHandleInfo] = useState([])
  return (
    <>
      {marketDetails ?
        <div className="tabpanel-wrapper market-tab">
          {/* Market opportunity block */}
          <div className="left_col">
            <h2>Market opportunity</h2>
            <p>{marketDetails?.proptechCompanyDetails?.marketOpportunityAdmin}</p>
            <div className="detail-sector-wrapper">
              {/* Charging model block */}
              <div className="detail-sectors">
                <div className='flex items-start'>
                  <h2 className='pr-4'>Charging model</h2>
                  <InfoIcon
                    label={TooltipText.chargingModel}
                    title={TooltipText.chargingModel}
                    info={handleInfo}
                    handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                    handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                  />
                </div>
                <ul className="menu-item">
                  <KeyFeatureLabel icon={marketDetails?.proptechAnalysisChargingModel1?.icon} label={marketDetails?.proptechAnalysisChargingModel1?.name} />
                  <KeyFeatureLabel icon={marketDetails?.proptechAnalysisChargingModel2?.icon} label={marketDetails?.proptechAnalysisChargingModel2?.name} />
                  <KeyFeatureLabel icon={marketDetails?.proptechAnalysisChargingModel3?.icon} label={marketDetails?.proptechAnalysisChargingModel3?.name} />
                </ul>
              </div>
              {/* Tech buyer block */}
              <div className="detail-sectors">
                <div className='flex items-start'>
                  <h2 className='pr-4'>Tech buyer</h2>
                  <InfoIcon
                    label={TooltipText.techBuyer}
                    title={TooltipText.techBuyer}
                    info={handleInfo}
                    handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                    handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                  />
                </div>
                <ul className="menu-item">
                  <KeyFeatureLabel icon={marketDetails?.proptechAnalysisTechBuyer1?.icon} label={marketDetails?.proptechAnalysisTechBuyer1?.name} />
                  <KeyFeatureLabel icon={marketDetails?.proptechAnalysisTechBuyer2?.icon} label={marketDetails?.proptechAnalysisTechBuyer2?.name} />
                  <KeyFeatureLabel icon={marketDetails?.proptechAnalysisTechBuyer3?.icon} label={marketDetails?.proptechAnalysisTechBuyer3?.name} />
                </ul>
              </div>
              {/* Value KPI block */}
              <div className="detail-sectors">
                <div className='flex items-start'>
                  <h2 className='pr-4'>Value KPI</h2>
                  <InfoIcon
                    label={TooltipText.valueKPI}
                    title={TooltipText.valueKPI}
                    info={handleInfo}
                    handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                    handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                  />
                </div>
                <ul className="menu-item">
                  <KeyFeatureLabel icon={marketDetails?.proptechAnalysisValueKPI1?.icon} label={marketDetails?.proptechAnalysisValueKPI1?.name} />
                  <KeyFeatureLabel icon={marketDetails?.proptechAnalysisValueKPI2?.icon} label={marketDetails?.proptechAnalysisValueKPI2?.name} />
                  <KeyFeatureLabel icon={marketDetails?.proptechAnalysisValueKPI3?.icon} label={marketDetails?.proptechAnalysisValueKPI3?.name} />
                </ul>
              </div>
            </div>
          </div>
          {/* Market Chart block */}
          <div className="right_col" onClick={(e) => e.stopPropagation()}>
            <div className='flex items-start'>
              <h2 className='pr-4'>{Radar_Chart_Labels.market}</h2>
              <InfoIcon
                label={TooltipText.marketRadar}
                info={handleInfo}
                handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
              />
            </div>
            <div className="chart-wrapper">
              <Chart
                items={marketChartData.map((val) => marketDetails[val])}
                labels={marketChartLabels}
                step={2}
                label={'Rating'}
                maximumValue={10}
              />
            </div>
          </div>
        </div>
        : null}
    </>
  )
}
