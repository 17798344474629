export default function adminAnalysisTab(
    initialState = {
        activeAnalysisTab: 0,
        companyTabData: null,
        overviewTabData: null,
        marketTabData: null,
        numbersTabData: null,
        raiseTabData: null,
        teamsTabData: null,
        designReviewTabData: null,
        raiseOverviewTabData: null,
        marketInsightsTabData: null,
        companyProfileTabData: null,
    },
    action
) {
    switch (action.type) {
        case 'SET_ACTIVE_ANALYSIS_TAB':
            return {
                ...initialState,
                activeAnalysisTab: action.payload,
            };

        case 'SET_COMPANY_TAB_DATA':
            return {
                ...initialState,
                companyTabData: action.payload,
            };

        case 'SET_OVERVIEW_TAB_DATA':
            return {
                ...initialState,
                overviewTabData: action.payload,
            };

        case 'SET_MARKET_TAB_DATA':
            return {
                ...initialState,
                marketTabData: action.payload,
            };

        case 'SET_NUMBERS_TAB_DATA':
            return {
                ...initialState,
                numbersTabData: action.payload,
            };

        case 'SET_RAISE_TAB_DATA':
            return {
                ...initialState,
                raiseTabData: action.payload,
            };

        case 'SET_TEAMS_TAB_DATA':
            return {
                ...initialState,
                teamsTabData: action.payload,
            };

        case 'SET_DESIGN_REVIEW_TAB_DATA':
            return {
                ...initialState,
                designReviewTabData: action.payload,
            };

        case 'SET_RAISE_OVERVIEW_TAB_DATA':
            return {
                ...initialState,
                raiseOverviewTabData: action.payload,
            };

        case 'SET_MARKET_INSIGHTS_TAB_DATA':
            return {
                ...initialState,
                marketInsightsTabData: action.payload,
            };

        case 'SET_COMPANY_PROFILE_TAB_DATA':
            return {
                ...initialState,
                companyProfileTabData: action.payload,
            };

        case 'ADMIN_ANALYSIS_TAB_RESET':
            return {
                ...initialState,
                activeAnalysisTab: 0,
                companyTabData: null,
                overviewTabData: null,
                marketTabData: null,
                numbersTabData: null,
                raiseTabData: null,
                teamsTabData: null,
                designReviewTabData: null,
                raiseOverviewTabData: null,
                marketInsightsTabData: null,
                companyProfileTabData: null,
            };

        default:
            return initialState;
    }
}
