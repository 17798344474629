import React, { useEffect, useRef, useState } from 'react';
import { MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import PTButton from '../../../../components/GenericComponents/PTButton';
import PTInput from '../../../../components/GenericComponents/PTInput';
import Loader from '../../../../components/Loading';
import ConfirmModal from '../../ConfirmModal';
import placeholderImage from '../../../../assets/Icons/placeholder.png';
import FullWidthTextField from '../../FullWidthTextField';
import DropdownWithTextField from '../../DropdownWithTextField';
import { INVESTOR_PROFILE_ERROR, INVESTOR_PROFILE_PAYLOAD } from '../../../utils/payloads/payloads';
import { uploadImage } from '../../../utils/middleware/companyTab';
import { numFormatter, trimQuery, urlValidate } from '../../../../utils/utility/utility';
import { updateInvestorProfileDetails } from '../../../utils/middleware/investorProfileTab';
import { dispatch } from '../../../../utils/utility/store';
import { Actions } from '../../../../store/actions';
import { investmentSize, INVESTOR_PROFILE_TAB_METRICS } from '../../../constants';
import { CustomSlider, StyledValueLabel } from '../../Slider';
import { isUnsaveChanges } from '../../../utils/middleware/analysisTab';
import Tags from '../../Tags';
import './investorProfile.scss';

export default function InvestoreProfileTab(props) {
  const { setUnsaveChange } = props

  // Store
  const { isLoading, selectedInvestor, activeSort, sortOrder, isArchived, search, profileTabData, selectedTags } = useSelector(state => state.adminInvestor);
  const { countries, investmentRounds, keyBizModels, tags } = useSelector(state => state.adminDropdowns)

  // State
  const [editedDetails, setEditedDetails] = useState(INVESTOR_PROFILE_PAYLOAD);
  const [errors, setErrors] = useState(INVESTOR_PROFILE_ERROR);
  const [editedTags, setEditedTags] = useState(null);
  const [files, setFiles] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const inputRef = useRef();

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    isUnsaveChanges(editedDetails, setUnsaveChange)
  }, [editedDetails]);  // eslint-disable-line react-hooks/exhaustive-deps

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails))
    Object.keys(data).forEach(error => {
      data = {
        ...data, [error]: editedDetails?.[error] !== null ?
          error === 'websiteURL' ? !urlValidate(editedDetails?.[error]) : !editedDetails?.[error] :
          error === 'websiteURL' ? !urlValidate(profileTabData?.[error]) : !profileTabData?.[error]
      }
    })
    setErrors(data)
    return Object.keys(data).some(k => data[k])
  }

  const handleImage = (e, key) => {
    if (e.target.files && e.target.files[0]) {
      setLoading(true)
      setFiles(e.target.files[0])
      uploadImage(e.target.files[0], 'vc-company-logo')
        .then(res => {
          setLoading(false);
          setEditedDetails({ ...editedDetails, 'companyLogo': res.data.fileName })
        })
        .catch(() => setLoading(false))
    }
  }

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => (newData[key] = key === 'companyLogo' ? profileTabData?.[key]?.split("/").slice(-2).join("/") : profileTabData?.[key]));
    handleCloseModal()
    try {
      await updateInvestorProfileDetails(
        selectedInvestor?.id,
        {
          ...Object.fromEntries(Object.entries(editedDetails).filter(([_, v]) => v !== null)),
          ...Object.fromEntries(Object.entries(newData).filter(([_, v]) => v !== null))
        }, editedTags, isArchived, activeSort, sortOrder, search)
    } catch {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      console.log('error')
    }
  }

  return (
    <div className='admin-profile-wrapper'>
      {isLoading && <Loader />}
      <div className='h-full flex flex-col overflow-x-hidden overflow-y-auto'>
        <div className='w-full flex company-two-col-block mb-12'>
          <input
            style={{ display: 'none' }}
            id="file"
            ref={inputRef}
            type="file"
            className="form-control"
            value=''
            accept={['jpg', 'jpeg', 'png']
              .map(function (x) {
                return '.' + x;
              })
              .join(',')}
            onChange={handleImage}
          />
          <div className='left-form-block'>
            <div className='flex justify-between form-item-wrap items-center'>
              <span>Company logo</span>
              <div className='flex flex-col justify-end items-end'>
                <PTButton
                  variant="contained"
                  label={!loading && <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">Upload</span>}
                  className='w-[120px] h-[44px] !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]'
                  loading={loading}
                  handleOnClickEvent={() => {
                    inputRef.current.click()
                  }}
                />
                {errors.companyLogo && <span className='font-normal text-xs mx-0 mb-0 mt-2 text-left tracking-wide text-[#d32f2f]'>Image Required.</span>}
              </div>
            </div>
            <div className='flex justify-between form-item-wrap items-center'>
              <span>Country</span>
              <PTInput
                variant="standard"
                label="Country"
                className="custom-dropdown-icon"
                select
                selectChildren={countries.map(country => <MenuItem value={country.id}>{country.name}</MenuItem>)}
                placeholder=''
                disabled={loading}
                value={editedDetails?.countryId || profileTabData?.countryId}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, countryId: e.target.value })}
                error={errors.countryId}
                helperText={errors.countryId && "Country Required."}
              />
            </div>
            <FullWidthTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors}
              maxLength={null}
              data={profileTabData}
              textLabel="Company Info"
              textValue="companyInfo"
            />
            <FullWidthTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors}
              data={profileTabData}
              textLabel="Website URL"
              textValue="websiteURL"
              validUrlText
            />
            <div className='profile-range-wrapper'>
              <div className='rangeslider-wrapper'>
                <label className="slider-label">INVESTMENT ROUND</label>
                <Box className="rangeSlider" style={{ marginLeft: '12px' }}>
                  <CustomSlider
                    defaultValue={1}
                    step={1}
                    track={false}
                    value={editedDetails?.investmentRoundId || profileTabData?.investmentRoundId}
                    onChange={(_, newValue) => setEditedDetails({ ...editedDetails, investmentRoundId: newValue })}
                    marks={investmentRounds.map((value, index) => { return { value: value.id, label: value.name } })}
                    min={1}
                    max={5}
                  />
                </Box>
              </div>
              <div className='rangeslider-wrapper'>
                <label className="slider-label">REVENUE</label>
                <Box className="rangeSlider" style={{ marginLeft: '12px' }}>
                  <CustomSlider
                    track={false}
                    defaultValue={0}
                    step={100000}
                    value={editedDetails?.revenueRange !== null ? editedDetails?.revenueRange : profileTabData?.revenueRange}
                    onChange={(_, newValue) => setEditedDetails({ ...editedDetails, revenueRange: newValue })}
                    valueLabelDisplay="on"
                    marks={investmentSize}
                    min={0}
                    max={10000000}
                    ValueLabelComponent={StyledValueLabel}
                    valueLabelFormat={(v) => { return `${numFormatter(v)}` }}
                  />
                </Box>
              </div>
              <div className='rangeslider-wrapper'>
                <label className="slider-label">MINIMUM INVESTMENT SIZE</label>
                <Box className="rangeSlider" style={{ marginLeft: '12px' }}>
                  <CustomSlider
                    track={false}
                    defaultValue={0}
                    step={100000}
                    value={editedDetails?.investmentRange !== null ? editedDetails?.investmentRange : profileTabData?.investmentRange}
                    onChange={(_, newValue) => setEditedDetails({ ...editedDetails, investmentRange: newValue })}
                    valueLabelDisplay="on"
                    marks={investmentSize}
                    min={0}
                    max={10000000}
                    ValueLabelComponent={StyledValueLabel}
                    getAriaValueText={v => v}
                    aria-labelledby="range-slider"
                    valueLabelFormat={(v) => { return `${numFormatter(v)}` }}
                  />
                </Box>
              </div>
            </div>
          </div>
          <div className='right-image-block'>
            {(files || profileTabData?.companyLogo) ?
              <div className='inner-image-block'><img src={files ? URL.createObjectURL(files) : profileTabData?.companyLogo} alt="Profile" width={'100%'} height={'100%'} /></div> :
              <div className='inner-image-block'><img src={placeholderImage} alt="placeholder" /></div>}
          </div>
        </div>
        <div className='analysis-dropdown-wrapper horizontal'>
          <div class="dropdown-title not-italic text-[#111D43]">Metrics</div>
          {INVESTOR_PROFILE_TAB_METRICS.map(customer => {
            return <DropdownWithTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors}
              data={profileTabData}
              dropdown
              dropdownOptions={keyBizModels}
              dropdownLabel={customer.dropdownLabel}
              dropdownValue={customer.dropdownValue}
              textLabel={customer.textLabel}
              textValue={customer.textValue}
            />
          })}
        </div>

        <FullWidthTextField
          editedDetails={editedDetails}
          setEditedDetails={setEditedDetails}
          errors={errors}
          maxLength={null}
          data={profileTabData}
          textLabel="Notes"
          textValue="notes"
        />

        <div className='analysis-dropdown-wrapper horizontal'>
          <div class="dropdown-title not-italic text-[#111D43]">Tags</div>
          <Tags
            value={editedTags !== null ? editedTags : selectedTags}
            onChange={(e, value) => setEditedTags(value)}
            options={tags}
          />
        </div>

        <div className='bottom-btn-wrapper flex justify-end'>
          <PTButton variant="contained"
            label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Revert</span>}
            type="submit" className='w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]'
            handleOnClickEvent={() => {
              setEditedDetails(INVESTOR_PROFILE_PAYLOAD)
              setEditedTags(null)
              setFiles(null)
            }}
          />
          <PTButton
            variant="contained"
            label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider text-[#FFFFFF]">Save & Continue</span>}
            type="submit"
            className='w-[142px] h-[44px] rounded-xl border-solid bg-[#111D43]'
            handleOnClickEvent={() => !checkValidation() && handleOpenModal()}
          />
        </div>
      </div>

      {openModal && <ConfirmModal open={openModal} handleCloseModal={handleCloseModal} handleSucces={updateData} />}
    </div>
  )
}
