import { ADMIN_PRE_SIGNED_URL_API, ADMIN_PROPTECHS, } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { dispatch } from "../../../utils/utility/store"
import { handleError, handleSuccess } from "../../../utils/utility/snackbar"
import { getProptechs } from "../../../utils/middleware/_admin/Proptechs"
import { companyTabDTO } from "../dtos/companyTab"
import { getCountriesData, getLanguagesData, getMegaTrendsData, getRevenueRangesData } from "../dropdowns"

export const loadCompanyTabData = (id, adminAnalysisTab, adminDropdowns) => {
  getCompanyDetails(id)
  loadCompanyTabDropdowns(adminDropdowns.countries, adminDropdowns.revenueRanges, adminDropdowns.megaTrends, adminDropdowns.languages)
}

export const getCompanyDetails = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/company`)
    .then(res => {
      dispatch(Actions.AdminUser.SetLoading, false)
      dispatch(Actions.AdminAnalysisTab.SetCompanyTabData, companyTabDTO(res.data.data))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err)
    })
}

export const fetchCalendlyUrl = (id, setLoading) => {
  setLoading(true)
  return ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/calendlyURL`)
    .then(res => {
      setLoading(false)
      handleSuccess(res)
      return res?.data?.data?.calendlyURL
    }).catch(err => {
      setLoading(false)
      handleError(err)
      return ''
    })
}

export const updateCompanyDetails = (id, data, isArchived, activeSort, sortOrder, search) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  return ADMIN_API().put(ADMIN_PROPTECHS + `/${id}/company`, data)
    .then(res => {
      handleSuccess(res)
      getCompanyDetails(id)
      getProptechs(isArchived, activeSort, sortOrder, search, false);
      return res?.status;
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err);
      return err?.response?.status;
    })
}

export const uploadImage = (file, bucketType) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function () {
      ADMIN_API().post(ADMIN_PRE_SIGNED_URL_API, {
        fileName: file.name?.replace(" ", ""),
        mimeType: file.type,
        bucketType: bucketType,
      })
        .then((response) => {
          fetch(response.data.data.preSignedUrl, {
            method: "PUT",
            body: new Blob([reader.result], {
              type: file.type,
            }),
          })
            .then(() => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    };
    reader.readAsArrayBuffer(file);
  });
};

export const loadCompanyTabDropdowns = (countries, revenueRanges, megaTrends, languages) => {
  if (!countries.length) {
    getCountriesData();
  }
  if (!revenueRanges.length) {
    getRevenueRangesData();
  }
  if (!megaTrends.length) {
    getMegaTrendsData();
  }
  if (!languages.length) {
    getLanguagesData();
  }
}