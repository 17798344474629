import React, { useState } from 'react';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import PTButton from '../../../components/GenericComponents/PTButton'
import LeftLogo from '../../../components/LeftLogo';
import { handleError, handleSuccess } from '../../../utils/utility/snackbar';
import { VS_SEND_EMAIL_VERIFICATION } from '../../../constants/APIs';
import { useFormik } from 'formik';
import PTInput from '../../../components/GenericComponents/PTInput';

export default function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = new URLSearchParams(location?.search);
  const [isVerified, setIsVerified] = useState(pathName.get('success') === 'true');
  const [message, setMessage] = useState(pathName.get('message'));

  const initialValues = {
    email: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string()
        .email('This email is not a valid format.')
        .required('Required'),
    }),
    onSubmit: (values, { resetForm }) => {
      resendEmailVerificationLink({
        email: values.email,
      }, resetForm)
    }
  });

  const resendEmailVerificationLink = (data) => {
    axios.post(VS_SEND_EMAIL_VERIFICATION, data)
      .then(res => {
        handleSuccess(res)
        if (res?.data?.data) {
          setIsVerified(true)
          setMessage(res?.data?.message)
        } else {
          navigate('/venture-scout/checkemail', { replace: true });
        }
      })
      .catch((error) => {
        handleError(error)
      })
  }

  return (
    <div className="w-screen flex proptech-layout-wrapper flex-wrap">
      <div className="w-full md:w-3/5">
        <LeftLogo />
      </div>
      <div className="md:min-h-screen w-full md:w-2/5 items-center inline-flex relative">
        <div className="wrapper-container">
          <div className="text-wrapper">
            <h2>{isVerified ? 'Email verified' : 'Email unverified'}!</h2>
            <p className='p-0'>
              {message}</p>
          </div>
          {!isVerified ? <form onSubmit={formik.handleSubmit}>
            <div className='form-wrapper'>
              <div className={"form-group " + (formik.touched.email && formik.errors.email ? "error-parent" : "") + (!formik.errors.email && formik.values.email ? "success-attempt" : "")}>
                <PTInput
                  id="email"
                  name="email"
                  placeholder="Email"
                  label="Email"
                  fullWidth
                  className='form-control'
                  handleOnChangeEvent={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && <span className="error-txt">{formik.errors.email}</span>}
              </div>
              <PTButton label='Resend verification' type="submit" className="normal-case mt-[13px] fill-orange btn" size='large' />
            </div>
          </form> :
            <PTButton label='Back to login' type="submit" className="normal-case mt-[13px] fill-orange btn" size='large' handleOnClickEvent={() => window.location.href = '/venture-scout/login'} />}
          <div className="not-have-account">
            <span>
              Need a hand? Email our support team at
              <a href='mailto:support@theproptechconnection.com' className='block'>
                support@theproptechconnection.com
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
