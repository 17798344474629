import { ADMIN_PROPTECHS, } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { dispatch } from "../../../utils/utility/store"
import { handleError, handleSuccess, } from "../../../utils/utility/snackbar"
import { getGrowthData, getNeedToKnowData, getPredictionsData, getTrendsData } from "../dropdowns"
import { marketInsightsTabDTO } from "../dtos/marketInsightsTab"

export const loadMarketInsightsTabData = (id, adminAnalysisTab, adminDropdowns) => {
    if (!adminAnalysisTab.marketInsightsTabData) {
        getMarketInsightsDetails(id)
    }
    loadMarketInsightsTabDropdowns(adminDropdowns.trends, adminDropdowns.predictions, adminDropdowns.growth, adminDropdowns.needToKnow);
}

export const getMarketInsightsDetails = (id) => {
    dispatch(Actions.AdminUser.SetLoading, true)
    ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/marketInsights`)
        .then(res => {
            dispatch(Actions.AdminUser.SetLoading, false)
            dispatch(Actions.AdminAnalysisTab.SetMarketInsightsTabData, marketInsightsTabDTO(res.data.data))
            handleSuccess(res)
        }).catch(err => {
            dispatch(Actions.AdminUser.SetLoading, false)
            handleError(err)
        })
}

export const updateMarketInsightsDetails = (id, data) => {
    dispatch(Actions.AdminUser.SetLoading, true)
    return ADMIN_API().put(ADMIN_PROPTECHS + `/${id}/marketInsights`, data)
        .then(res => {
            handleSuccess(res)
            getMarketInsightsDetails(id)
            return res?.status;
        }).catch(err => {
            dispatch(Actions.AdminUser.SetLoading, false)
            handleError(err);
            return err?.response?.status;
        })
}

export const loadMarketInsightsTabDropdowns = (trends, predictions, growth, needToKnow) => {
    if (!trends.length) {
        getTrendsData();
    }
    if (!predictions.length) {
        getPredictionsData();
    }
    if (!growth.length) {
        getGrowthData();
    }
    if (!needToKnow.length) {
        getNeedToKnowData();
    }
}