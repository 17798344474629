import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import { Box } from '@mui/system';
import leftArowIcon from '../../../assets/Icons/ic-right-arrow.svg';
import HeaderRight from "../../components/HeaderRight";
import PTButton from '../../../components/GenericComponents/PTButton';
import DropdownWithTextField from '../../../_admin/components/DropdownWithTextField';
import PTInput from '../../../components/GenericComponents/PTInput';
import { COMPANY_PROFILE_METRICS, COMPANY_PROFILE_PAYLOAD, COMPANY_PROFILE_ERROR, Update_profile_propmpt } from '../../constants';
import { getInvestmentRoundList, getKeyBizModels } from '../../utils/middleware/Dropdowns';
import { getCompanyProfileModels, updateCompanyProfile } from '../../utils/middleware/CompanyProfile';
import Loader from '../../../components/Loading';
import { CustomSlider, StyledValueLabel } from '../../../_admin/components/Slider';
import { COMPANY_ERROR_TEXT, investmentSize } from '../../../_admin/constants';
import { numFormatter, trimQuery, urlValidate } from '../../../utils/utility/utility';
import './company-profile.scss';
import ChangePassword from '../../../components/ChangePassword';
import { Store } from '../../../store/configureStore';
import { Actions } from '../../../store/actions';
import { VC_CHANGE_PASSWORD_API } from '../../../constants/APIs';
import { VC_API } from '../../../utils/utility/API-call';
import { handleError, handleSuccess } from '../../../utils/utility/snackbar';
import InfoIcon from '../../../components/InfoIcon';
import { TooltipText } from '../../../constants/General';

function CompanyProfile() {
  // State
  const [keyBizModels, setKeyBizModels] = useState([]);
  const [invRound, setInvRound] = useState([]);
  const [editedDetails, setEditedDetails] = useState(COMPANY_PROFILE_PAYLOAD);
  const [errors, setErrors] = useState(COMPANY_PROFILE_ERROR);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [handleInfo, setHandleInfo] = useState([])

  // Store
  const { vcMasterData } = useSelector(state => state.vcProptechs);
  const { isLoading, companyProfileData, keyBizModelsData } = useSelector(state => state.vcCompanyProfile);

  const navigate = useNavigate()
  const handleBackClick = () => {
    navigate(-1);
  }

  // Set revenue data from API or Store
  const handleRevenueData = async () => {
    if (!vcMasterData) {
      let invRound = await getInvestmentRoundList();
      invRound.map((round, index) => {
        round.label = round.name
        round.value = index + 1
      })
      setInvRound(invRound)
    } else {
      let invRound = vcMasterData?.investmentRoundData;
      invRound.map((round, index) => {
        round.label = round.name
        round.value = index + 1
      })
      setInvRound(invRound)
    }
  }

  // Handle revenue data and get key biz models value from API
  useEffect(() => {
    handleRevenueData()
    if (!keyBizModelsData) {
      getKeyBizModels().then((res) => {
        setKeyBizModels(res);
      })
    } else {
      setKeyBizModels(keyBizModelsData);
    }
    getCompanyProfileModels()
      .then((res) => {

      })
      .catch((err) =>
        console.log(err))

  }, [])

  // Update company profile data
  const handleUpdateData = () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => newData[key] = companyProfileData?.[key]);
    updateCompanyProfile(
      {
        ...Object.fromEntries(Object.entries(editedDetails).filter(([_, v]) => v !== null)),
        ...Object.fromEntries(Object.entries(newData).filter(([_, v]) => v !== null))
      }
    )
  }

  // validations for urls
  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails))
    Object.keys(data).forEach(error => {
      data = {
        ...data, [error]: editedDetails?.[error] !== null ?
          error === 'websiteURL' ? !urlValidate(editedDetails?.[error]) : !editedDetails?.[error] :
          error === 'websiteURL' ? !urlValidate(companyProfileData?.[error]) : !companyProfileData?.[error]
      }
    })
    setErrors(data)
    return Object.keys(data).some(k => data[k])
  }

  const changePassword = (payload, resetForm) => {
    Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: true });
    VC_API().put(VC_CHANGE_PASSWORD_API, payload)
      .then(res => {
        Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
        resetForm({ values: '' });
        handleSuccess(res);
        setChangePasswordModal(false)
      })
      .catch((error) => {
        Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
        handleError(error);
      })
  }

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="h-screen w-screen proptech-detail-wrapper">
        <div className="grow bg-[#F7F7F7]">
          <div className="navigation-top">
            <div className="btn-wrapper">
              <a onClick={() => handleBackClick()} className="icon-btn left-arrow cursor-pointer"> <img src={leftArowIcon} alt="Icon" />Back</a>
            </div>
            <div className='right-nav'>
              <HeaderRight setChangePasswordModal={setChangePasswordModal} />
            </div>
          </div>
        </div>
        {companyProfileData ?
          <div className="company-profile-page">
            <h1>Your company profile</h1>
            <div className="investor-top-heading">
              <em>
                {companyProfileData?.companyLogo ?
                  <img src={companyProfileData?.companyLogo} alt="logo" /> :
                  <HideImageOutlinedIcon />
                }
              </em>
              <div className="content-block">
                <h3><span>{companyProfileData?.vcCompany?.name || '-'}</span></h3>
              </div>
            </div>
            {/* Company Information Field */}
            <PTInput
              variant="standard"
              label="Company Information"
              fullWidth
              placeholder=''
              disabled={companyProfileData?.isProfileCompleted}
              value={editedDetails?.companyInfo !== null ? editedDetails?.companyInfo : companyProfileData?.companyInfo}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, companyInfo: e.target.value })}
              multiline={true}
              maxRows='4'
              error={errors?.companyInfo}
              helperText={errors?.companyInfo && `Company Info Required.`}
            />
            <div className='url-list'>
              <div className='url-list-item'>
                {/* website url Field */}
                <PTInput
                  variant="standard"
                  label="Website URL"
                  fullWidth
                  placeholder=''
                  disabled={companyProfileData?.isProfileCompleted}
                  value={editedDetails?.websiteURL !== null ? editedDetails?.websiteURL : companyProfileData?.websiteURL}
                  handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, websiteURL: e.target.value })}
                  error={errors?.websiteURL}
                  helperText={errors?.websiteURL && COMPANY_ERROR_TEXT.url}
                />
              </div>
            </div>
            <div className='profile-range-wrapper'>
              {/* Investment round slider */}
              <div className='rangeslider-wrapper'>
                <label className="slider-label">INVESTMENT ROUND</label>
                <Box className="rangeSlider">
                  <CustomSlider
                    disabled={companyProfileData?.isProfileCompleted}
                    step={1}
                    track={false}
                    value={parseInt(editedDetails?.investmentRoundId) || parseInt(companyProfileData?.investmentRoundId)}
                    onChange={(_, newValue) => setEditedDetails({ ...editedDetails, investmentRoundId: newValue })}
                    marks={invRound}
                    min={1}
                    max={5}
                  />
                </Box>
              </div>
              {/* Revenue slider */}
              <div className='rangeslider-wrapper'>
                <label className="slider-label">REVENUE</label>
                <Box className="rangeSlider">
                  <CustomSlider
                    disabled={companyProfileData?.isProfileCompleted}
                    track={false}
                    defaultValue={0}
                    step={100000}
                    value={editedDetails?.revenueRange !== null ? parseInt(editedDetails?.revenueRange) : parseInt(companyProfileData?.revenueRange)}
                    onChange={(_, newValue) => setEditedDetails({ ...editedDetails, revenueRange: newValue })}
                    valueLabelDisplay="on"
                    marks={investmentSize}
                    min={0}
                    max={10000000}
                    valueLabelFormat={(v) => { return `${numFormatter(v)}` }}
                    ValueLabelComponent={StyledValueLabel}
                    getAriaValueText={v => v}
                  />
                </Box>
              </div>
              {/* Min investment slider */}
              <div className='rangeslider-wrapper'>
                <label className="slider-label">MINIMUM INVESTMENT SIZE</label>
                <Box className="rangeSlider">
                  <CustomSlider
                    disabled={companyProfileData?.isProfileCompleted}
                    track={false}
                    defaultValue={0}
                    step={100000}
                    value={editedDetails?.investmentRange !== null ? parseInt(editedDetails?.investmentRange) : parseInt(companyProfileData?.investmentRange)}
                    onChange={(_, newValue) => setEditedDetails({ ...editedDetails, investmentRange: newValue })}
                    valueLabelDisplay="on"
                    marks={investmentSize}
                    min={0}
                    max={10000000}
                    valueLabelFormat={(v) => { return `${numFormatter(v)}` }}
                    ValueLabelComponent={StyledValueLabel}
                    getAriaValueText={v => v}
                  />
                </Box>
              </div>
            </div>
            {/* Metrics block */}
            <div className='investor-dropdown-wrapper '>
              <div className='flex items-start'>
                <h2 className='pr-4'>Metrics</h2>
                <InfoIcon
                  label={TooltipText.investorProfile}
                  title={TooltipText.investorProfile}
                  info={handleInfo}
                  handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                  handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                />
              </div>
              {COMPANY_PROFILE_METRICS.map(customer => {
                return <DropdownWithTextField
                  data={companyProfileData}
                  editedDetails={editedDetails}
                  setEditedDetails={setEditedDetails}
                  errors={errors}
                  dropdown
                  dropdownOptions={keyBizModels}
                  dropdownLabel={customer.dropdownLabel}
                  dropdownValue={customer.dropdownValue}
                  textLabel={customer.textLabel}
                  textValue={customer.textValue}
                  disabled={companyProfileData?.isProfileCompleted
                  }
                />
              })}
            </div>
            {!companyProfileData?.isProfileCompleted ?
              <div className='flex justify-end bottom-btn-wrapper'>
                <PTButton label='Save' handleOnClickEvent={() => !checkValidation() && handleUpdateData()} className="fill-orange btn" />
              </div>
              : (
                <>
                  <span className='text-[10px] text-right pt-1 font-semibold uppercase text-error'>
                    {Update_profile_propmpt.errorPropmptMessage}
                  </span>
                  <div className='text-[10px] font-semibold'>
                    <span className='text-[10px] text-right pt-1 font-semibold uppercase text-error'>{`contact to : `}</span>
                    <a className='hover:text-[#0000EE] hover:underline' href="mailto:connect@theptc.com">connect@theptc.com</a>
                  </div>
                </>
              )
            }
          </div>
          : null}
      </div>
      {/* Change password modal */}
      {changePasswordModal &&
        <ChangePassword
          open={changePasswordModal}
          handleClose={() => setChangePasswordModal(false)}
          handleSuccess={changePassword}
          cancelButtonLabel="Cancel"
          cancelButtonStyle="mr-4 fill-gray btn"
          changeButtonLabel="Change"
          changeButtonStyle='fill-orange btn small-size' />}
    </>
  )
}
export default CompanyProfile;
