import {configs} from '../Configs';

// ****** Base URL ****** //
export const BASE_URL = configs.api;

// ******  VC ****** //
export const VC_SIGNUP_API = `${BASE_URL}/vc/auth/signup`;
export const VC_FORGOT_PASSWORD_API = `${BASE_URL}/vc/auth/forgotPassword`;
export const VC_RESET_PASSWORD_API = `${BASE_URL}/vc/auth/resetPassword`;
export const VC_LOGIN_API = `${BASE_URL}/vc/auth/login`;
export const VC_USERS_API = `${BASE_URL}/vc/auth/users`;
export const VC_CHANGE_PASSWORD_API = `${BASE_URL}/vc/auth/changePassword`;
export const VC_SEND_EMAIL_VERIFICATION = `${BASE_URL}/vc/auth/re-send-email-invitation`;
export const VC_NOTIFICATIONS_API = `${BASE_URL}/vc/notifications`;
export const VC_INVITE_API = `${BASE_URL}/vc/auth/invite`;
export const VC_PROPTECH_LISTING = `${BASE_URL}/vc/proptechs`;
export const VC_WATCHLIST = `${BASE_URL}/vc/proptechs/favorites`;
export const VC_FORYOU = `${BASE_URL}/vc/proptechs/foryou`;
export const VC_MASTER_API = `${BASE_URL}/vc/master`;
export const VC_REGIONS_API = `${VC_MASTER_API}/regions`;
export const VC_REVENUERANGES_API = `${VC_MASTER_API}/revenueRanges`;
export const VC_INVESTMENT_ROUNDS_API = `${VC_MASTER_API}/investmentRounds`;
export const VC_KEYBIZMODELS_API = `${VC_MASTER_API}/keyBizModels`;
export const VC_ACTIVEUSERSLIST_API = `${BASE_URL}/vc/auth/activeUsers`;
export const VC_COMPANYPROFILE_API = `${BASE_URL}/vc/auth/companyProfile`;
export const VC_MEETINGSBASE_API = `${BASE_URL}/vc/meetings`;
export const VC_SCHEDULEMEETING_API = `${BASE_URL}/vc/meetings/schedule`;
export const VC_UPCOMINGMEETING_API = `${BASE_URL}/vc/meetings/upcoming`;
export const VC_PREVIOUSMEETING_API = `${BASE_URL}/vc/meetings/previous`;
export const VC_MEETONGCOUNTS_API = `${BASE_URL}/vc/meetings/counts`;
export const VC_FEATURELIST = `${BASE_URL}/vc/proptechs/featured`;
export const VC_PLANS_API = `${BASE_URL}/vc/master/plans`;
export const VC_PLAN_REQUEST = `${BASE_URL}/vc/plans/choosePlan`;

// ******  Proptech ****** //
export const PROPTECH_PROFILE_API = `${BASE_URL}/proptech/profile`;
export const PROPTECH_SIGNUP_API = `${BASE_URL}/proptech/auth/signup`;
export const PROPTECH_FORGOT_PASSWORD_API = `${BASE_URL}/proptech/auth/forgotPassword`;
export const PROPTECH_RESET_PASSWORD_API = `${BASE_URL}/proptech/auth/resetPassword`;
export const PROPTECH_CHANGE_PASSWORD_API = `${BASE_URL}/proptech/auth/changePassword`;
export const PROPTECH_LOGIN_API = `${BASE_URL}/proptech/auth/login`;
export const PROPTECH_COMPANY_DETAILS_API = `${BASE_URL}/proptech/auth/companyDetails`;
export const PROPTECH_SIGNUP_COMPLETED_API = `${BASE_URL}/proptech/auth/signupCompleted`;
export const PROPTECH_USERS_API = `${BASE_URL}/proptech/auth/users`;
export const PROPTECH_METRICS_API = `${BASE_URL}/proptech/auth/metrics`;
export const PROPTECH_SEND_EMAIL_VERIFICATION = `${BASE_URL}/proptech/auth/re-send-email-invitation`;
export const PROPTECH_NOTIFICATIONS_API = `${BASE_URL}/proptech/notifications`;
export const PROPTECH_PRE_SIGNED_URL_API = `${BASE_URL}/proptech/getPreSignedURL`;
export const PROPTECH_MEETINGS_API = `${BASE_URL}/proptech/meetings`;
export const PROPTECH_MASTER_API = `${BASE_URL}/proptech/master`;
export const PROPTECH_NEWSFEED = `${BASE_URL}/proptech/newsfeed`;
export const PROPTECH_COUNTRY_API = `${PROPTECH_MASTER_API}/countries`;
export const PROPTECH_CURRENCY_API = `${PROPTECH_MASTER_API}/currencies`;
export const PROPTECH_INVESTMENT_ROUND_API = `${PROPTECH_MASTER_API}/investmentRounds`;
export const PROPTECH_INVESTMENT_RANGE_API = `${PROPTECH_MASTER_API}/investmentRanges`;
export const PROPTECH_PLANS_API = `${BASE_URL}/proptech/plans`;
export const PROPTECH_CHOOSE_PLAN_API = `${BASE_URL}/proptech/plans/choosePlan`;
export const PROPTECH_UPGRADE_PLAN_API = `${BASE_URL}/proptech/plans/upgradePlan`;
export const PROPTECH_ACTIVE_PAYMENT_METHOD_API = `${BASE_URL}/proptech/plans/activePaymentMethod`;
export const PROPTECH_UPDATE_PAYMENT_METHOD_API = `${BASE_URL}/proptech/plans/updatePaymentMethod`;

// ******  VS ****** //
export const VS_SIGNUP_API = `${BASE_URL}/vs/auth/signup`;
export const VS_FORGOT_PASSWORD_API = `${BASE_URL}/vs/auth/forgotPassword`;
export const VS_RESET_PASSWORD_API = `${BASE_URL}/vs/auth/resetPassword`;
export const VS_LOGIN_API = `${BASE_URL}/vs/auth/login`;
export const VS_PROFILE_API = `${BASE_URL}/vs/auth/profile`;
export const VS_CALENDAR_API = `${BASE_URL}/vs/auth/calendar`;
export const VS_STATISTICS_API = `${BASE_URL}/vs/auth/statistics`;
export const VS_GRAPH_API = `${BASE_URL}/vs/auth/graph`;
export const VS_CHANGEPASSWORD_API = `${BASE_URL}/vs/auth/changePassword`;
export const VS_SEND_EMAIL_VERIFICATION = `${BASE_URL}/vs/auth/re-send-email-invitation`;

// ******  Admin  ****** //
export const ADMIN_LOGIN = `${BASE_URL}/admin/auth/login`;
export const ADMIN_PROPTECHS = `${BASE_URL}/admin/proptechs`;
export const ADMIN_NEWSFEED = `${BASE_URL}/admin/newsfeed`;
export const ADMIN_INVESTORS = `${BASE_URL}/admin/vc`;
export const ADMIN_VENTURE_SCOUTS = `${BASE_URL}/admin/vs`;
export const ADMIN_CONTACTS = `${BASE_URL}/admin/contact`;
export const ADMIN_MASTER_API = `${BASE_URL}/admin/master`;
export const ADMIN_COUNTRY_API = `${ADMIN_MASTER_API}/countries`;
export const ADMIN_REVENUE_RANGE_API = `${ADMIN_MASTER_API}/revenueRanges`;
export const ADMIN_MEGA_TRENDS_API = `${ADMIN_MASTER_API}/megaTrends`;
export const ADMIN_LANGUAGES_API = `${ADMIN_MASTER_API}/languages`;
export const ADMIN_TECH_BUYERS_API = `${ADMIN_MASTER_API}/techBuyers`;
export const ADMIN_CHARGING_MODELS_API = `${ADMIN_MASTER_API}/chargingModels`;
export const ADMIN_VALUE_KPIS_API = `${ADMIN_MASTER_API}/valueKPIs`;
export const ADMIN_SECTORS_API = `${ADMIN_MASTER_API}/sectors`;
export const ADMIN_USE_CASES_API = `${ADMIN_MASTER_API}/useCases`;
export const ADMIN_TECH_OFFERINGS_API = `${ADMIN_MASTER_API}/techOfferings`;
export const ADMIN_NO_OF_CUSTOMERS_API = `${ADMIN_MASTER_API}/noOfCustomers`;
export const ADMIN_NO_OF_COUNTRIES_API = `${ADMIN_MASTER_API}/noOfCountries`;
export const ADMIN_GROSS_MARGINS_API = `${ADMIN_MASTER_API}/grossMargins`;
export const ADMIN_GROWTH_BLOCKERS_API = `${ADMIN_MASTER_API}/growthBlockers`;
export const ADMIN_INVESTMENT_ROUNDS_API = `${ADMIN_MASTER_API}/investmentRounds`;
export const ADMIN_INVESTMENT_RANGES_API = `${ADMIN_MASTER_API}/investmentRanges`;
export const ADMIN_INVESTMENT_TYPES_API = `${ADMIN_MASTER_API}/investmentTypes`;
export const ADMIN_VALUATION_RANGES_API = `${ADMIN_MASTER_API}/valuationRanges`;
export const ADMIN_NO_OF_ROUNDS_API = `${ADMIN_MASTER_API}/noOfRounds`;
export const ADMIN_ALIGNMENTS_API = `${ADMIN_MASTER_API}/alignments`;
export const ADMIN_NO_OF_EMPLOYEES_API = `${ADMIN_MASTER_API}/noOfEmployees`;
export const ADMIN_TEAM_SKILLS_API = `${ADMIN_MASTER_API}/teamSkills`;
export const ADMIN_KEY_HIRE_MAPPINGS_API = `${ADMIN_MASTER_API}/keyHireMappings`;
export const ADMIN_KEY_BIZ_MODELS_API = `${ADMIN_MASTER_API}/keyBizModels`;
export const ADMIN_TRENDS_API = `${ADMIN_MASTER_API}/trends`;
export const ADMIN_PREDICTIONS_API = `${ADMIN_MASTER_API}/predictions`;
export const ADMIN_GROWTH_API = `${ADMIN_MASTER_API}/growth`;
export const ADMIN_NEED_TO_KNOW_API = `${ADMIN_MASTER_API}/needToKnow`;
export const ADMIN_INVESTOR_STYLE_API = `${ADMIN_MASTER_API}/investorStyle`;
export const ADMIN_INVESTOR_TRAITS_API = `${ADMIN_MASTER_API}/investorTraits`;
export const ADMIN_ASSET_LIFE_CYCLES_API = `${ADMIN_MASTER_API}/assetLifecycles`;
export const ADMIN_DEFENSIBILITY_TIMINGS_API = `${ADMIN_MASTER_API}/defensibilityTimings`;
export const ADMIN_TAGS_API = `${ADMIN_MASTER_API}/tags`;
export const ADMIN_CHANGE_PASSWORD_API = `${BASE_URL}/admin/auth/changePassword`;
export const ADMIN_PRE_SIGNED_URL_API = `${BASE_URL}/admin/getPreSignedURL`;
