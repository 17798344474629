import React from 'react';
import { Link, Menu, MenuItem } from '@mui/material';
import { HelpMenuPaperProps, HelpSectionLinks } from '../../constants/General';

export default function HelpMenu(props) {
  // Props
  const { helpMenu, open, handleClose, handleWatchMenuClick = () => {} } = props;

  return <>
    <Menu
      anchorEl={helpMenu}
      id="help-menu"
      className="dropdown_menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={HelpMenuPaperProps}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem onClick={handleWatchMenuClick}>
        Watch explainer video
      </MenuItem>
      <MenuItem>
        <Link href={`mailto:${HelpSectionLinks.contactUs}`} className='!w-full' underline="none">Contact us</Link>
      </MenuItem>
      <MenuItem>
        <Link href={HelpSectionLinks.faqs} className='!w-full' target="_blank" underline="none">FAQs</Link>
      </MenuItem>
      <MenuItem>
        <Link href={HelpSectionLinks.abouthThePtc} className='!w-full' target="_blank" underline="none">About the PTC</Link>
      </MenuItem>
    </Menu>
  </>
}
