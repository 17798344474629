import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/Icons/connect-logo.svg';
import './style.css';


export default function LeftLogo() {
  const navigate = useNavigate();

  return (
    <>
      <div className="background-blue md:min-h-screen">
        {/* <img src={Logo} alt="" /> */}
        <img src={Logo} alt="" className='cursor-pointer z-10' onClick={() => navigate('/', { replace: true })} />
      </div>
    </>
  )
}