import moment from "moment";
import { URL_REGEX } from "../../constants/General";

export const trimQuery = (query) => {
  Object.keys(query).forEach(k => query[k] = typeof query[k] == 'string' ? query[k].trim() : query[k])
  return query
}

export function numFormatter(num) {
  if (num > 999 && num < 1000000) {
    return `$${(num / 1000).toFixed(1)}k`; // convert to K for number from > 1000 < 1 million 
  } else if (num >= 1000000) {
    if (num === 10000000) {
      return `$${(num / 1000000).toFixed(1)}m+`; // convert to M for number from > 1 million 
    } else {
      return `$${(num / 1000000).toFixed(1)}m` // convert to M for number from > 1 million 
    }
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
}

export function formatTime(time, format) {
  return moment.tz(time, "UTC").format(format)
}

export function urlValidate(url) {
  return URL_REGEX.test(url)
}