export const apiTimeoutTime = 300000;

export const proptchDetailsTab = [
  'overview',
  'market',
  'numbers',
  'raise',
  'team',
  'designReview',
  'marketInsights',
  'companyProfile'
];

export const proptechDetailsArray = [
  'overviewDetails',
  'marketDetails',
  'numbersDetails',
  'raiseDetails',
  'teamDetails',
  'designReviewDetails',
  'marketInsightsDetails',
  'companyProfileDetails',
];

export const sortByDefaultOption = [
  { id: 'createdAt', name: 'Default', labelId: "orderBy" },
  { id: 'name', name: 'Name', labelId: "orderBy" },
  { id: 'recentlyAdded', name: 'Recently added', labelId: "orderBy" },
  { id: 'region', name: 'Region', labelId: "orderBy" },
  { id: 'round', name: 'Round', labelId: "orderBy" },
  { id: 'revenue', name: 'Revenue', labelId: "orderBy" },
];

export const COMPANY_PROFILE_METRICS = [
  { dropdownLabel: 'Metric 1', dropdownValue: "metric1", textLabel: "Metric 1 Value", textValue: "metric1Text" },
  { dropdownLabel: 'Metric 2', dropdownValue: "metric2", textLabel: "Metric 2 Value", textValue: "metric2Text" },
  { dropdownLabel: 'Metric 3', dropdownValue: "metric3", textLabel: "Metric 3 Value", textValue: "metric3Text" },
]

export const COMPANY_PROFILE_PAYLOAD = {
  metric1Text: null,
  metric2Text: null,
  metric3Text: null,
  metric1: null,
  metric2: null,
  metric3: null,
  companyInfo: null,
  websiteURL: null,
  investmentRoundId: null,
  revenueRange: null,
  investmentRange: null,
}

export const COMPANY_PROFILE_ERROR = {
  companyInfo: false,
  websiteURL: false,
  metric1: false,
  metric1Text: false,
  metric2: false,
  metric2Text: false,
  metric3: false,
  metric3Text: false,
}

export const CHANGE_PASSWORD_BOX = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: 4,
  border: 'none',
  p: 4,
}

export const CHANGE_PASSWORD_TYPO = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '24px'
}

export const CHANGE_PASSWORD_BOTTOM_DESCRIPTION = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '28px'
}

export const VIDEO_MODAL = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 500,
  bgcolor: 'background.paper',
  borderRadius: "16px",
  boxShadow: 24,
};

export const LoadingContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: 'center',
  height: '100%',
}

export const ChangePlanModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 1000,
  width: '70%',
  maxHeight: '84%',
  backgroundColor: '#E5E5E5',
  border: '2px solid #E5E5E5',
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  overflow: 'auto'
}

export const FORM_FIELDS = {
  passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
  passwordNotMatch: "Password don't match",
  sizeLimit: {
    tooShortPassword: 'Password is too short - should be 6 chars minimum.',
    passwordContain: 'Password has contain minimum 6 characters (includes 1 Upper, 1 Special, 1 Number)'
  },
  currentPassword: 'currentPassword',
  currentPassword2: 'Current Password',
  password: 'password',
  required: 'Required',
  confirmPassword: 'confirmPassword',
  confirmPassword2: 'Confirm Password',
  newPassword: 'New Password',
  confirmNewPassword: 'Confirm new password',
  notRegistered: 'Not registered yet?',
  createAccount: 'Create an account',
  rememerMe: 'Remember me',
  forgotPassword: 'Forgot password?',
  logIn: 'Log in',
  emailValidFormat: 'This email is not a valid format.',
  signUp: 'Sign up',
  securitySeriously: `We take security seriously. We'll verify your details once completed, and be in contact via email, ASAP.`,
  mustContain: 'Password must contain at least:',
  upperCase: '1 uppercase letter',
  number: '1 number',
  special: '1 special character',
  alreadyAccount: 'Already got an account?',
}

export const PROPTECHCARD = {
  goodFit: 'Why is this a good fit',
  curated: 'Curated for you',
  keyCustomers: 'KEY CUSTOMERS',
  sectors: 'SECTORS',
}

export const SHARE_MODAL = {
  share: 'Share',
  shareLink: 'Or share this link',
  shareMsg: 'Hey, check out this proptech. Keen to hear what you think!'
}
export const MANAGEUSERS_MODAL_HEADER = "Manage users";

export const INVITE_MODAL = {
  emailAddressPlaceholder: 'Only users with your company email can join your team',
  invitationMessage: 'Hey, come join this awesome platform!'
}

export const FEATURED_CARD = {
  featured: 'FEATURED',
  keyThemes: 'KEY THEMES'
}

export const DASHBOARD_MENU = {
  exploreVideo: 'Watch explainer video',
}

export const ICON_PLACEHOLDER_STYLE = {
  FeatureKeyFeatureLabel: { width: '30px', height: '30px' },
  KeyFeatureLabel: { width: '44px', height: '40px' },
  VerticalLogoWithHeading: { width: '40px', height: '40px', marginBottom: '12px' },
  KeySpanLogo: { width: '46px', height: '52px' },
}

export const Update_profile_propmpt = {
  errorPropmptMessage: '* Please contact the connect admin to update your profile.'
}

export const Radar_Chart_Labels = {
  overview: 'Megatrends',
  market: 'Opportunity drivers',
  numbers: 'Competitive edge',
  raise: 'Why invest?',
  designReview: 'Design review',
}

export const FEEDBACK_TYPE = {
  positive: 1,
  negative: 2,
}

// need to update video url once received from client
export const INTRODUCTRY_VIDEO_URL = "https://ptc-company.s3.ap-southeast-2.amazonaws.com/the_ptc_video.mp4";

export const FREE_MEMBERSHIP_MEETING_COUNT = 20;

export const WELCOME_POP_UP_COUNT = 5;

export const features = [
  { icon: false, key: 'durationOfDays', label: 'Duration (in days)' },
  { icon: true, key: 'vcPitchAnalysedAndCurated', label: 'VC pitch analysed and curated' },
  { icon: true, key: 'accessToGlobalPTCNetwork', label: 'Access to globalthe PTC network' },
  { icon: true, key: 'profilePublished', label: 'Profile published' },
  { icon: true, key: 'integratedCalendar', label: 'Integrated calendar' },
  { icon: true, key: 'videoHostedOnPTCPlatform', label: 'Video hosted on the PTC platform' },
  { icon: false, key: 'numberOfWatchListCompanies', label: 'Watchlist companies' },
  { icon: true, key: 'isHavingForYouFunctionality', label: 'For You' },
  { icon: true, key: 'isHavingDownloadOnePager', label: 'Download one pager' },
  { icon: true, key: 'isHavingDesignReview', label: 'Design review' },
  { icon: true, key: 'isHavingMarketInsight', label: 'Market insights' },
];