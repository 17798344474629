import { ADMIN_PROPTECHS } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { handleError, handleSuccess } from "../../../utils/utility/snackbar"
import { dispatch } from "../../../utils/utility/store"
import { adminForYouProptechsListDTO, adminWatchlistProptechsListDTO } from "../dtos/adminProptechTab"
import { loadCompanyTabData } from "./companyTab"

export const setActivetabData = (tab, id, adminAnalysisTab, adminDropdowns) => {
  dispatch(Actions.AdminUser.SetProptechActiveTabIndex, tab)

  switch (tab) {
    case 2: id && loadCompanyTabData(id, adminAnalysisTab, adminDropdowns);
      break;

    case 3: id && getForYouProptechs(id);
      break;

    case 4: id && getWatchlistProptechs(id);
      break;

    default:
      break;
  }
}

export const getForYouProptechs = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/forYou`)
    .then(res => {
      dispatch(Actions.AdminUser.SetLoading, false)
      dispatch(Actions.AdminUser.SetForYouProptechs, adminForYouProptechsListDTO(res.data.data.rows))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err)
    })
}

export const getWatchlistProptechs = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/watchList`)
    .then(res => {
      dispatch(Actions.AdminUser.SetLoading, false)
      dispatch(Actions.AdminUser.SetWatchlistProptechs, adminWatchlistProptechsListDTO(res.data.data.rows))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err)
    })
}