import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './style.scss';
import Chart from '../../Chart';
import { designReviewChartData, designReviewChartLabels } from '../../../utils/chartData';
import { Radar_Chart_Labels } from '../../../constants';
import { TooltipText } from '../../../../constants/General';
import InfoIcon from '../../../../components/InfoIcon';

// Function for rating
const reviewBlock = (rating) => {
  let reviewBlockArr = []
  for (let i = 1; i <= rating; i++) {
    reviewBlockArr.push(<li><em className={`line${rating}`}></em></li>)
  }
  for (let j = 0; j < 5 - rating; j++) {
    reviewBlockArr.push(<li><em></em></li>)
  }
  return reviewBlockArr;
}

export default function DesignReview() {
  // Store
  const { designReviewDetails } = useSelector(state => state.vcProptechDetails)

  //State
  const [handleInfo, setHandleInfo] = useState([])

  return (
    <>
      {designReviewDetails ?
        <div className="tabpanel-wrapper design-review-tab">
          {/****Learn more block removed****/}
          {/* <div className="watchlist-card">
            <div className="watchlist-data">
              <div className="left">
                <em><img src={weDiscoverIcon} alt="discovericon" /></em>
                This design review was conducted by We Discover
              </div>
              <div className="right">
                <PTButton label='Learn more' endIcon={<img src={loginIcon} alt="Icon" />} className="btn" />
              </div>
            </div>
          </div> */}
          <div className="left_col">

            <h2>Overview</h2>
            <div className="modal-design">
              <div className="market-growth">
                <div className="growth-info">
                  {designReviewDetails?.overview1 && <div className="growth-item"><p>{designReviewDetails?.overview1}</p></div>}
                  {designReviewDetails?.overview2 && <div className="growth-item"><p>{designReviewDetails?.overview2}</p></div>}
                  {designReviewDetails?.overview3 && <div className="growth-item"><p>{designReviewDetails?.overview3}</p></div>}
                  {designReviewDetails?.overview4 && <div className="growth-item"><p>{designReviewDetails?.overview4}</p></div>}
                  {designReviewDetails?.overview5 && <div className="growth-item"><p>{designReviewDetails?.overview5}</p></div>}
                  {designReviewDetails?.noOverview === true &&
                    <div><span>No overview details provided.</span></div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="right_col">
            <div className='flex items-start'>
              <h2 className='pr-4'>{Radar_Chart_Labels.designReview}</h2>
              <InfoIcon
                label={TooltipText.designRaviewRadar}
                info={handleInfo}
                handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
              />
            </div>
            <div className="chart-wrapper">
              <Chart
                items={designReviewChartData.map((val) => designReviewDetails[val])}
                labels={designReviewChartLabels}
                step={1}
                label={'Rating'}
                maximumValue={5}
              />
            </div>
          </div>
          <div className="design-key-points">
            <div className="market-growth key-metrics">
              <div className="modal-design">
                <div className="growth-info">
                  <div className="growth-item-wrap">
                    <div className="growth-item">
                      <h3>Visual design and branding</h3>
                      <p className="small-text">How does brand and aesthethic of the application as a hold up as a whole? Checking consistency of UI, layout and actions. </p>
                      <div className="rating-process-wrapper">
                        <span>{designReviewDetails?.visualDesignBranding}</span>
                        <ul>{reviewBlock(designReviewDetails?.visualDesignBranding)}</ul>
                      </div>
                      <p>{designReviewDetails?.visualDesignBrandingText}</p>
                    </div>
                  </div>
                  <div className="growth-item-wrap">
                    <div className="growth-item">
                      <h3>Intuitiveness</h3>
                      <p className="small-text">How easily can a new user can use the platform and how familiar actions are compared to common experiences across other applications?</p>
                      <div className="rating-process-wrapper">
                        <span>{designReviewDetails?.intuitiveness}</span>
                        <ul>{reviewBlock(designReviewDetails?.intuitiveness)}</ul>
                      </div>
                      <p>{designReviewDetails?.intuitivenessText}</p>
                    </div>
                  </div>
                  <div className="growth-item-wrap">
                    <div className="growth-item">
                      <h3>Efficiency</h3>
                      <p className="small-text">How is the platform to navigate for both novice and expert users. Does the platform provide shortcuts or methods to get from point A to point B in an effective manner, and is this shown in a logical or natural order?</p>
                      <div className="rating-process-wrapper">
                        <span>{designReviewDetails?.efficiency}</span>
                        <ul>{reviewBlock(designReviewDetails?.efficiency)}</ul>
                      </div>
                      <p>{designReviewDetails?.efficiencyText}</p>
                    </div>
                  </div>
                  <div className="growth-item-wrap">
                    <div className="growth-item">
                      <h3>Feedback</h3>
                      <p className="small-text">How well does the application provide indications that the user correctly completed an application? Are the actions predictable for the user and how well can they determine their next step?</p>
                      <div className="rating-process-wrapper">
                        <span>{designReviewDetails?.feedback}</span>
                        <ul>{reviewBlock(designReviewDetails?.feedback)}</ul>
                      </div>
                      <p>{designReviewDetails?.feedbackText}</p>
                    </div>
                  </div>
                  <div className="growth-item-wrap">
                    <div className="growth-item">
                      <h3>Error prevention and recovery</h3>
                      <p className="small-text">How well can the user interact with application and be safe from making errors? How well can they recovery from errors they have made?</p>
                      <div className="rating-process-wrapper">
                        <span>{designReviewDetails?.errorPreventionRecovery}</span>
                        <ul>{reviewBlock(designReviewDetails?.errorPreventionRecovery)}</ul>
                      </div>
                      <p>{designReviewDetails?.errorPreventionRecoveryText}</p>
                    </div>
                  </div>
                  <div className="growth-item-wrap">
                    <div className="growth-item">
                      <h3>Support</h3>
                      <p className="small-text">How well does the platform support a user should they require assistance. What level of documentation and information is available for the user and how easily is it accessed?</p>
                      <div className="rating-process-wrapper">
                        <span>{designReviewDetails?.support}</span>
                        <ul>{reviewBlock(designReviewDetails?.support)}</ul>
                      </div>
                      <p>{designReviewDetails?.supportText}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        : null}
    </>
  )
}
