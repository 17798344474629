export const ANSWERS_TAB_PAYLOAD = {
    elevatorPitchAdmin: null,
    technologyProductAdmin: null,
    marketOpportunityAdmin: null,
    futureGoalsAdmin: null,
    investabilityAdmin: null,
    teamStrengthAdmin: null,
    KPIsAdmin: null
}

export const ANSWERS_TAB_ERRORS = {
    elevatorPitchAdmin: false,
    technologyProductAdmin: false,
    marketOpportunityAdmin: false,
    futureGoalsAdmin: false,
    investabilityAdmin: false,
    teamStrengthAdmin: false,
    KPIsAdmin: false
}

export const COMPANY_TAB_PAYLOAD = {
    countryId: null,
    revenueRangeId: null,
    megaTrendId: null,
    languageId: null,
    founderPitchVideo: null,
    calendlyURL: null,
    keyCustomer1: null,
    keyCustomer2: null,
    keyCustomer3: null,
    keyCustomer1Logo: null,
    keyCustomer2Logo: null,
    keyCustomer3Logo: null,
    profileImage: null,
    companyLogo: null,
}

export const COMPANY_TAB_ERRORS = {
    countryId: false,
    companyLogo: false,
    founderPitchVideo: false,
    calendlyURL: false,
}

export const OVERVIEW_TAB_PAYLOAD = {
    connectivity: null,
    dealClosing: null,
    decisionMaking: null,
    evolvingAssets: null,
    smarterBuilds: null,
    userExperience: null,
    sector1: null,
    sector2: null,
    sector3: null,
    techOffering1: null,
    techOffering2: null,
    techOffering3: null,
    useCase1: null,
    useCase2: null,
    useCase3: null,
}

export const OVERVIEW_TAB_ERRORS = {
    sector1: false,
    sector2: false,
    sector3: false,
    techOffering1: false,
    techOffering2: false,
    techOffering3: false,
    useCase1: false,
    useCase2: false,
    useCase3: false,
}

export const MARKET_TAB_PAYLOAD = {
    competition: null,
    costToScale: null,
    currentPipeline: null,
    geographyAgnostic: null,
    highDemand: null,
    marketSize: null,
    chargingModel1: null,
    chargingModel2: null,
    chargingModel3: null,
    techBuyer1: null,
    techBuyer2: null,
    techBuyer3: null,
    valueKPI1: null,
    valueKPI2: null,
    valueKPI3: null,
    valueKPI4: null,
}

export const MARKET_TAB_ERRORS = {
    chargingModel1: false,
    chargingModel2: false,
    chargingModel3: false,
    techBuyer1: false,
    techBuyer2: false,
    techBuyer3: false,
    valueKPI1: false,
    valueKPI2: false,
    valueKPI3: false,
    valueKPI4: false,
}

export const NUMBERS_TAB_PAYLOAD = {
    cashProfile: null,
    customerType: null,
    profitability: null,
    salesConversion: null,
    speedToScale: null,
    stickiness: null,
    currentNoOfCustomers: null,
    currentNoOfCountries: null,
    currentGrossMargin: null,
    futureNoOfCustomers: null,
    futureNoOfCountries: null,
    futureGrossMargin: null,
    growthBlocker1: null,
    growthBlocker2: null,
    growthBlocker3: null,
}

export const NUMBERS_TAB_ERRORS = {
    currentNoOfCustomers: false,
    currentNoOfCountries: false,
    currentGrossMargin: false,
    futureNoOfCustomers: false,
    futureNoOfCountries: false,
    futureGrossMargin: false,
    growthBlocker1: false,
    growthBlocker2: false,
    growthBlocker3: false,
}

export const RAISE_TAB_PAYLOAD = {
    defensibility: null,
    model: null,
    network: null,
    technology: null,
    team: null,
    timing: null,
    investmentRangeId: null,
    investmentRoundId: null,
    investmentTypeId: null,
    noOfRoundsId: null,
    raiseDate: null,
    valuationRangeId: null,
    alignment1: null,
    alignment2: null,
    alignment3: null,
}

export const RAISE_TAB_ERRORS = {
    investmentRangeId: false,
    investmentRoundId: false,
    investmentTypeId: false,
    noOfRoundsId: false,
    raiseDate: false,
    valuationRangeId: false,
    alignment1: false,
    alignment2: false,
    alignment3: false,
}

export const TEAM_TAB_PAYLOAD = {
    award1: null,
    award1Image: null,
    award2: null,
    award2Image: null,
    award3: null,
    award3Image: null,
    yearFounded: null,
    noOfEmployees: null,
    productVideo: null,
    noOfOffices: null,
    skill1: null,
    skill2: null,
    skill3: null,
    keyHireMapping1: null,
    keyHireMapping2: null,
    keyHireMapping3: null,
    location1: null,
    location1Latitude: null,
    location1Longitude: null,
    location2: null,
    location2Latitude: null,
    location2Longitude: null,
    location3: null,
    location3Latitude: null,
    location3Longitude: null,
}

export const TEAM_TAB_ERRORS = {
    yearFounded: false,
    noOfEmployees: false,
    productVideo: false,
    noOfOffices: false,
    skill1: false,
    skill2: false,
    skill3: false,
    keyHireMapping1: false,
    keyHireMapping2: false,
    keyHireMapping3: false,
    location1: false,
    location1Latitude: false,
    location1Longitude: false,
}

export const RAISE_OVERVIEW_TAB_PAYLOAD = {
    keyBizModelId: null,
    committedId: null,
    priorInvestmentRoundId: null,
    priorInvestmentRangeId: null,
    priorValuationRangeId: null,
    priorInvestmentTypeId: null,
    priorRaiseDate: null,
    priorKeyBizModelId: null
}

export const RAISE_OVERVIEW_TAB_ERRORS = {
    keyBizModelId: false,
    committedId: false,
    priorInvestmentRoundId: false,
    priorInvestmentRangeId: false,
    priorValuationRangeId: false,
    priorInvestmentTypeId: false,
    priorRaiseDate: false,
    priorKeyBizModelId: false
}

export const DESIGN_REVIEW_TAB_PAYLOAD = {
    efficiency: null,
    efficiencyText: null,
    errorPreventionRecovery: null,
    errorPreventionRecoveryText: null,
    feedback: null,
    feedbackText: null,
    intuitiveness: null,
    intuitivenessText: null,
    support: null,
    supportText: null,
    visualDesignBranding: null,
    visualDesignBrandingText: null,
    overview1: null,
    overview2: null,
    overview3: null,
    overview4: null,
    overview5: null,
}

export const DESIGN_REVIEW_TAB_ERRORS = {
    efficiencyText: false,
    errorPreventionRecoveryText: false,
    feedbackText: false,
    intuitivenessText: false,
    supportText: false,
    visualDesignBrandingText: false,
    overview1: false,
}

export const MARKET_INSIGHTS_TAB_PAYLOAD = {
    trend1: null,
    trend2: null,
    trend3: null,
    prediction1: null,
    prediction2: null,
    prediction3: null,
    growth1: null,
    growth2: null,
    growth3: null,
    futureSynopsis: null,
    future1Heading: null,
    future1: null,
    future2Heading: null,
    future2: null,
    future3Heading: null,
    future3: null,
    stat1Type: null,
    stat1: null,
    stat2Type: null,
    stat2: null,
    stat3Type: null,
    stat3: null,
    stat4Type: null,
    stat4: null,
    needToKnow1Heading: null,
    needToKnow1Text: null,
    needToKnow2Heading: null,
    needToKnow2Text: null,
    needToKnow3Heading: null,
    needToKnow3Text: null
}

export const MARKET_INSIGHTS_TAB_ERRORS = {
    trend1: false,
    trend2: false,
    trend3: false,
    prediction1: false,
    prediction2: false,
    prediction3: false,
    growth1: false,
    growth2: false,
    growth3: false,
    futureSynopsis: false,
    future1Heading: false,
    future1: false,
    future2Heading: false,
    future2: false,
    future3Heading: false,
    future3: false,
    stat1Type: false,
    stat1: false,
    stat2Type: false,
    stat2: false,
    stat3Type: false,
    stat3: false,
    stat4Type: false,
    stat4: false,
    needToKnow1Heading: false,
    needToKnow1Text: false,
    needToKnow2Heading: false,
    needToKnow2Text: false,
    needToKnow3Heading: false,
    needToKnow3Text: false
}

export const COMPANY_PROFILE_TAB_PAYLOAD = {
    assetLifecycle1: null,
    assetLifecycle2: null,
    assetLifecycle3: null,
    problemSynopsis: null,
    problem1Heading: null,
    problem1Text: null,
    problem2Heading: null,
    problem2Text: null,
    problem3Heading: null,
    problem3Text: null,
    solutionSynopsis: null,
    solution1Heading: null,
    solution1Text: null,
    solution2Heading: null,
    solution2Text: null,
    solution3Heading: null,
    solution3Text: null,
    bizModel1Heading: null,
    bizModel1Text: null,
    bizModel2Heading: null,
    bizModel2Text: null,
    bizModel3Heading: null,
    bizModel3Text: null,
    goToMarket1Heading: null,
    goToMarket1Text: null,
    goToMarket2Heading: null,
    goToMarket2Text: null,
    goToMarket3Heading: null,
    goToMarket3Text: null,
    customerBreakdown1Text: null,
    customerBreakdown2Text: null,
    customerBreakdown3Text: null,
    customerKeyPoint1Text: null,
    customerKeyPoint2Text: null,
    customerKeyPoint3Text: null,
    currentTraction2Stat: null,
    currentTraction2: null,
    futureMetric1: null,
    futureMetric1Text: null,
    futureMetric2: null,
    futureMetric2Text: null,
    futureMetric3: null,
    futureMetric3Text: null,
    valueKPI1Text: null,
    valueKPI2Text: null,
    valueKPI3Text: null,
    valueKPI4Text: null,
    whyGoodFit1Text: null,
    whyGoodFit2Text: null,
    whyGoodFit3Text: null,
    defensibilityTiming1: null,
    defensibilityTiming1Text: null,
    defensibilityTiming2: null,
    defensibilityTiming2Text: null,
    defensibilityTiming3: null,
    defensibilityTiming3Text: null,
    skill1Text: null,
    skill2Text: null,
    skill3Text: null
}

export const COMPANY_PROFILE_TAB_ERROR = {
    assetLifecycle1: false,
    assetLifecycle2: false,
    assetLifecycle3: false,
    problemSynopsis: false,
    problem1Heading: false,
    problem1Text: false,
    problem2Heading: false,
    problem2Text: false,
    problem3Heading: false,
    problem3Text: false,
    solutionSynopsis: false,
    solution1Heading: false,
    solution1Text: false,
    solution2Heading: false,
    solution2Text: false,
    solution3Heading: false,
    solution3Text: false,
    bizModel1Heading: false,
    bizModel1Text: false,
    bizModel2Heading: false,
    bizModel2Text: false,
    bizModel3Heading: false,
    bizModel3Text: false,
    goToMarket1Heading: false,
    goToMarket1Text: false,
    goToMarket2Heading: false,
    goToMarket2Text: false,
    goToMarket3Heading: false,
    goToMarket3Text: false,
    customerBreakdown1Text: false,
    customerBreakdown2Text: false,
    customerBreakdown3Text: false,
    customerKeyPoint1Text: false,
    customerKeyPoint2Text: false,
    customerKeyPoint3Text: false,
    currentTraction2Stat: false,
    currentTraction2: false,
    futureMetric1: false,
    futureMetric1Text: false,
    futureMetric2: false,
    futureMetric2Text: false,
    futureMetric3: false,
    futureMetric3Text: false,
    valueKPI1Text: false,
    valueKPI2Text: false,
    valueKPI3Text: false,
    valueKPI4Text: false,
    whyGoodFit1Text: false,
    whyGoodFit2Text: false,
    whyGoodFit3Text: false,
    defensibilityTiming1: false,
    defensibilityTiming1Text: false,
    defensibilityTiming2: false,
    defensibilityTiming2Text: false,
    defensibilityTiming3: false,
    defensibilityTiming3Text: false,
    skill1Text: false,
    skill2Text: false,
    skill3Text: false
}

// Newsfeeds
export const NEWSFEED_PAYLOAD = {
    title: null,
    description: null,
    image: null,
    redirectionURL: null,
}

export const NEWSFEED_ERROR = {
    title: false,
    description: false,
    image: false,
    redirectionURL: false,
}

// Investors
export const INVESTOR_PROFILE_PAYLOAD = {
    companyInfo: null,
    companyLogo: null,
    websiteURL: null,
    countryId: null,
    investmentRoundId: null,
    revenueRange: null,
    investmentRange: null,
    metric1: null,
    metric1Text: null,
    metric2: null,
    metric2Text: null,
    metric3: null,
    metric3Text: null,
    notes: null,
}

export const INVESTOR_PROFILE_ERROR = {
    companyInfo: false,
    companyLogo: false,
    websiteURL: false,
    countryId: false,
    metric1: false,
    metric1Text: false,
    metric2: false,
    metric2Text: false,
    metric3: false,
    metric3Text: false,
    notes: false,
}

// Investors
export const VENTURE_SCOUT_PROFILE_PAYLOAD = {
    companyName: null,
    profileImage: null,
    phone: null,
    linkedInURL: null,
    code: null,
    countryId: null,
    onboardCommission: null,
    meetingCommission: null,
}

export const VENTURE_SCOUT_PROFILE_ERROR = {
    profileImage: false,
    linkedInURL: false,
    code: false,
    countryId: false,
    onboardCommission: false,
    meetingCommission: false,
}