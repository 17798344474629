export function designReviewTabDTO(data) {
  return {
    id: data?.id || 0,
    efficiency: data?.efficiency || 0,
    errorPreventionRecovery: data?.errorPreventionRecovery || 0,
    feedback: data?.feedback || 0,
    intuitiveness: data?.intuitiveness || 0,
    support: data?.support || 0,
    visualDesignBranding: data?.visualDesignBranding || 0,
    proptechCompanyDetailsId: data?.proptechCompanyDetailsId || 0,
    efficiencyText: data?.efficiencyText || null,
    errorPreventionRecoveryText: data?.errorPreventionRecoveryText || null,
    feedbackText: data?.feedbackText || null,
    intuitivenessText: data?.intuitivenessText || null,
    supportText: data?.supportText || null,
    visualDesignBrandingText: data?.visualDesignBrandingText || null,
    overview1: data?.overview1 || null,
    overview2: data?.overview2 || null,
    overview3: data?.overview3 || null,
    overview4: data?.overview4 || null,
    overview5: data?.overview5 || null,
  }
}