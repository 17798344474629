import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { Avatar } from '@mui/material';
import Badge from '@mui/material/Badge';
import { Box } from '@mui/system';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import closeIcon from '../../../assets/Icons/ic-cross.svg';
import HelpIcon from "../../../assets/Icons/ic-help.svg";
import NotificationIcon from "../../../assets/Icons/ic-notification.svg";
import paymentIcon from '../../../assets/Icons/ic-payment-gray.svg';
import daimondIcon from '../../../assets/Icons/ic-daimond-gray.svg';
import PTButton from '../../../components/GenericComponents/PTButton';
import { getVcLocalStorageUserData, removeVcSessionToken } from '../../../utils/session';
import ManageUsers from '../../../proptech/components/ManageUsers';
import { Actions } from '../../../store/actions';
import "./style.scss";
import "../HeaderNavigation/style.scss";
import { VC_API } from '../../../utils/utility/API-call';
import { VC_PLANS_API, VC_PLAN_REQUEST, VC_USERS_API } from '../../../constants/APIs';
import { userListDTO } from '../../../proptech/utils/dtos/users';
import { handleError, handleSuccess } from '../../../utils/utility/snackbar';
import { useSelector } from 'react-redux';
import { dispatch } from '../../../utils/utility/store';
import { INTRODUCTRY_VIDEO_URL, INVITE_MODAL, MANAGEUSERS_MODAL_HEADER } from '../../constants';
import { iconColor } from '../../../utils/theme';
import PTInput from '../../../components/GenericComponents/PTInput';
import { inviteMember } from '../../utils/middleware/Dashboard';
import { checkForOnlyWhiteSpace } from '../../utils/utility/utility';
import NotificationModal from '../../../components/Notifications';
import { clearNotification, getNotifications, readNotification } from '../../utils/middleware/Dashboard/notifications';
import { NotificationIconStyle } from '../../../constants/General';
import HelpMenu from '../../../components/HelpMenu';
import ChangePlan from '../ChangePlan';
import VideoModal from '../../../components/VideoModal';

//  Help, Notification, and user setting block
export default function HeaderRight({ setChangePasswordModal }) {

  // State
  const [helpMenu, setHelpMenu] = useState()
  const [notificationMenu, setNotificationMenu] = useState()
  const [userMenu, setUserMenu] = useState(null)
  const [userData, setUserData] = useState({})
  const [emailToInvite, setEmailToInvite] = useState('')
  const [wrongEmail, setWrongEmail] = useState(false)
  const [plans, setPlans] = useState([]);
  const [invitationMessage, setInvitationMessage] = useState(INVITE_MODAL.invitationMessage)
  // Modal
  const [openModal, setOpenModal] = React.useState(false);
  const [openManageUsersModal, setOpenManageUsersModal] = React.useState(false);
  const [openVideoModal, setIsOpenVideoModal] = React.useState(false);
  const [users, setUsers] = React.useState(null);

  const navigate = useNavigate();

  // Store
  const { notificationCheckUnread, notifications, notificationLoading, vcPlanDetails, changePlanModal } = useSelector(state => state.vcUser);
  const { isLoading } = useSelector(state => state.vcProptechs);
  const vcCountDetails = useSelector(state => state.vcUser.vcCountDetails);

  useEffect(() => {
    setUserData(getVcLocalStorageUserData())
  }, [])
  const open = Boolean(helpMenu);
  const openNotification = Boolean(notificationMenu);
  const openUser = Boolean(userMenu);

  // Handling user settings menu
  const handleClick_u = (event) => {
    setUserMenu(event.currentTarget);
  };
  const handleClose_u = () => {
    setUserMenu(null);
  };

  // Handling notifications menu
  const handleNotificationClick = (event) => {
    setNotificationMenu(event.currentTarget);
  };
  const handleClose_n = () => {
    setNotificationMenu(null);
  };

  // Handling Help menu
  const handleClick = (event) => {
    setHelpMenu(event.currentTarget);
  };
  const handleClose = () => {
    setHelpMenu(null);
  };

  const setLoading = (isLoading) => {
    dispatch(Actions.VCProptechs.VCSetLoading, isLoading);
  }

  const setChangePlanModal = (show) => {
    dispatch(Actions.VCUser.SetChangePlanModal, show);
  }

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false)
    setEmailToInvite('')
    setInvitationMessage(INVITE_MODAL.invitationMessage)
    setWrongEmail(false)
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleLogOut = () => {
    removeVcSessionToken()
    // window.location.replace = '/';
  }

  // Avatar for user name
  const stringAvatar = (name) => {
    if (name)
      return {
        children: `${name.split(' ')[0][0]}${name.split(' ').length > 1 ? name.split(' ')[1][0] : ``}`,
      };
  }

  // get list of vc users
  const getUsers = () => {
    dispatch(Actions.VCProptechs.VCSetLoading, true);
    VC_API().get(VC_USERS_API).then(res => {
      handleSuccess(res)
      setUsers(userListDTO(res.data.data));
      dispatch(Actions.VCProptechs.VCSetLoading, false);
    }).catch((error) => {
      dispatch(Actions.VCProptechs.VCSetLoading, false);
      handleError(error);
    });
  }

  // update vc user status
  const handleUpdateStatus = (id, status) => {
    dispatch(Actions.VCProptechs.VCSetLoading, true);
    VC_API().put(`${VC_USERS_API}/${id}/status`, {
      status
    }).then(res => {
      setUsers((users) => {
        const updatedUsers = users.map((u) => {
          if (u.id === id) {
            u.status = status;
          }
          return u;
        });
        return updatedUsers;
      })
      handleSuccess(res)
      dispatch(Actions.VCProptechs.VCSetLoading, false);
    }).catch((error) => {
      dispatch(Actions.VCProptechs.VCSetLoading, false);
      handleError(error);
    })
  };

  const handleInviteMember = () => {
    inviteMember(`${emailToInvite}@${userData?.company?.domain}`, invitationMessage)
    handleCloseModal()
  }

  // Change plan
  const notifyAdminForPlanChage = (selectedPlan) => {
    dispatch(Actions.VCProptechs.VCSetLoading, true)
    VC_API()
      .post(VC_PLAN_REQUEST, { planId: selectedPlan })
      .then((res) => {
        dispatch(Actions.VCProptechs.VCSetLoading, false)
        setChangePlanModal(false)
        handleSuccess(res);
      })
      .catch((err) => {
        dispatch(Actions.VCProptechs.VCSetLoading, false)
        handleError(err);
      });
  };

  useEffect(() => {
    if (changePlanModal) {
      getPlanDetails();
    }
  }, [changePlanModal]);

  const getPlanDetails = () => {
    VC_API()
      .get(VC_PLANS_API)
      .then((res) => {
        setPlans(res.data.data);
      })
      .catch((err) => {
        handleError(err);
      });
  };


  return (
    <>
      <ul className='flex justify-end right-header-menu'>
        {/* Help menu */}
        <li>
          <Tooltip title="Help">
            <IconButton
              onClick={(e) => handleClick(e)}
              size="small"
              sx={{ ml: 2, width: 40, height: 40 }}
              aria-controls={open ? 'help-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              className={open ? "open-menu" : " "}
            >
              <img src={HelpIcon} alt="Help" />
            </IconButton>
          </Tooltip>

          {open && <HelpMenu
            helpMenu={helpMenu}
            open={open}
            handleClose={handleClose}
            handleWatchMenuClick={() => setIsOpenVideoModal(true)}
          />}

        </li>

        {/* Notifications menu */}
        <li>
          <Tooltip title="Notifications">
            <IconButton
              onClick={(e) => {
                handleNotificationClick(e)
                notifications?.length === 0 && getNotifications()
              }}
              size="small"
              sx={NotificationIconStyle}
              aria-controls={openNotification ? 'notification-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openNotification ? 'true' : undefined}
              className={openNotification ? "open-menu" : " "}
            >
              <Badge className="badge-orange" variant={notificationCheckUnread && "dot"}>
                <img src={NotificationIcon} alt="notification" />
              </Badge>
            </IconButton>
          </Tooltip>
          <NotificationModal
            open={openNotification}
            loading={notificationLoading}
            anchor={notificationMenu}
            notifications={notifications}
            handleClose={handleClose_n}
            readNotification={readNotification}
            clearNotification={clearNotification}
          />
        </li>

        {/* User settings menu */}
        <li>
          <Tooltip title="User settings">
            <IconButton
              onClick={(e) => handleClick_u(e)}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={openUser ? 'user-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openUser ? 'true' : undefined}
              className={"profile-user " + (openUser ? "open-menu" : " ")}
            >
              <Avatar sx={{ width: 32, height: 32 }} {...stringAvatar(userData?.name)}></Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={userMenu}
            id="user-menu"
            className="dropdown_menu"
            open={openUser}
            onClose={handleClose_u}
            onClick={handleClose_u}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <div className="profile-wrapper">
              <div className="user-wrapper">
                <Avatar sx={{ width: 80, height: 80 }} {...stringAvatar(userData?.name)}></Avatar>
                <h2>{userData?.name}</h2>
                <a href={`mailto:${userData?.email}`}>{userData?.email}</a>
                <div className="user-contact">
                  If your details need updating, contact us at
                  <a href="mailto:hello@theproptechconnection.com">hello@theproptechconnection.com</a>
                </div>
                <PTButton label='Log Out' className="normal-case fill-gray btn" size='large' handleOnClickEvent={() => handleLogOut()} />
              </div>
              <div className="user-profile-menu">
                <div className="user-txt">
                  <span>Company</span>
                  <h2>{userData?.company?.name}</h2>
                  {vcPlanDetails?.name && <span className="badge blue-badge">{vcPlanDetails?.name}</span>}
                </div>
              </div>
            </div>
            <MenuItem onClick={() => setChangePlanModal(true)}>
              <em className='img-icon'><img src={paymentIcon} alt={vcCountDetails?.vcPlanId !== null ? 'Change plan' : 'Choose plan'} /></em>
              {vcCountDetails?.vcPlanId !== null ? 'Change plan' : 'Choose plan'}
            </MenuItem>
            {/* <MenuItem onClick={handleOpenModal}>
              <em className='img-icon'><img src={inviteIcon} alt="Invite team members" /></em>
              Invite team members
            </MenuItem> */}
            <MenuItem onClick={() => navigate('/investor/companyprofile')}>
              <em className='img-icon'><img src={daimondIcon} alt="View company profile" /></em>
              Your company profile
            </MenuItem>
            <MenuItem onClick={() => setOpenManageUsersModal(true)}>
              <em className='img-icon'><PersonOutlineOutlinedIcon sx={{ color: iconColor }} /></em>
              My account
            </MenuItem>
            <MenuItem onClick={() => setChangePasswordModal(true)}>
              <em className='img-icon'><ManageAccountsOutlinedIcon style={{ color: "#70778E" }} /></em>
              <span>Change password</span>
            </MenuItem>
          </Menu>
        </li>
      </ul>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="meeting-cancel-modal modal invite-modal"
      >
        <>
          <Box sx={style} >
            <IconButton className="close-btn" onClick={() => handleCloseModal()}>
              <img src={closeIcon} alt="Icon" />
            </IconButton>
            <div className="modal-inner">
              <h2>Invite team member</h2>
            </div>
            <form>
              <div className="form-wrapper">
                <div className={`form-group email-decoration ${!wrongEmail ? "" : 'error-parent'}`}>
                  <PTInput
                    id="email"
                    name="email"
                    placeholder={INVITE_MODAL.emailAddressPlaceholder}
                    label="Email address"
                    fullWidth
                    className='form-control'
                    value={emailToInvite}
                    handleOnChangeEvent={(e) => {
                      setWrongEmail(e.target.value.includes('@') || e.target.value.indexOf(' ') >= 0 ? true : false)
                      setEmailToInvite(e.target.value)
                    }}
                  />
                  <span className="email-text">{`@${userData?.company?.domain}`}</span>
                </div>
                <div className={`form-group ${invitationMessage ? "" : 'error-parent'}`}>
                  <PTInput
                    multiline
                    maxRows={1}
                    id="message"
                    name="message"
                    placeholder="Message"
                    label="Message"
                    fullWidth
                    className='form-control textarea-input'
                    value={invitationMessage}
                    handleOnChangeEvent={(e) => setInvitationMessage(e.target.value)}
                    maxLength={255}
                  />
                </div>
              </div>
            </form>
            <div className="btn-wrapper">
              <PTButton
                label='Invite'
                className={`normal-case btn ${!invitationMessage || !emailToInvite || wrongEmail || checkForOnlyWhiteSpace(invitationMessage) ? 'disable-btn' : 'fill-green'}`} size='large'
                handleOnClickEvent={() => emailToInvite && invitationMessage && handleInviteMember()}
                disabled={!invitationMessage || !emailToInvite || wrongEmail || checkForOnlyWhiteSpace(invitationMessage)}
              />
            </div>
          </Box>
        </>
      </Modal>
      {openManageUsersModal && <ManageUsers
        isPrimaryUser={userData?.isPrimaryUser || false}
        isLoading={isLoading}
        title={MANAGEUSERS_MODAL_HEADER}
        open={openManageUsersModal}
        handleClose={() => {
          setOpenManageUsersModal(false)
          setUsers(null)
        }}
        setIsLoading={setLoading}
        getUsers={getUsers}
        handleUpdateStatus={handleUpdateStatus}
        users={users}
        openInviteModal={() => handleOpenModal()}
        isVcPortal={true}
      />}
      {changePlanModal && <ChangePlan
        openChangePlanModal={changePlanModal}
        closeChangePlanModal={() => {
          setPlans([])
          setChangePlanModal(false)
        }}
        currentPlan={vcPlanDetails?.id}
        plans={plans}
        loading={isLoading}
        handleSuccess={notifyAdminForPlanChage}
      />}
      {openVideoModal && <VideoModal
        videoUrl={INTRODUCTRY_VIDEO_URL}
        openVideoModal={openVideoModal}
        closeVideoModal={() => setIsOpenVideoModal(false)}
      />}
    </>
  )
}