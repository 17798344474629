import React, { useState } from 'react'
import { Typography } from '@mui/material'
import Modal from '@mui/material/Modal'
import { Box } from '@mui/system'
import search from '../../../assets/Icons/welcome-modal-search.svg'
import bar from '../../../assets/Icons/welcome-modal-bar.svg'
import calender from '../../../assets/Icons/welcome-modal-calender.svg'
import PTButton from '../../../components/GenericComponents/PTButton'
import './welcomeModal.scss'
import { Store } from '../../../store/configureStore'
import { Actions } from '../../../store/actions'

export default function WelcomeModal() {
  const [open, setOpen] = useState(true)
  const handleClose = () => {
    setOpen(false)
    Store.dispatch({ type: Actions.VCProptechs.VCShowModal, payload: false });
  }

  const handleChangePlanModal = () => {
    Store.dispatch({ type: Actions.VCUser.SetChangePlanModal, payload: true });
    handleClose();
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '768px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className='welcome-modal'>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="more-filter-modal modal"
      >
        <Box sx={style} className='text-center welcome-modal'>
          <div>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Welcome to Connect
            </Typography>
            <p className='para-text sm:w-1/2 m-auto'>The all-in-one platform dedicated to connecting VCs with Proptechs from around the globe.</p>
          </div>
          <div className='flex sm:flex-row mb-6 flex-col'>
            <div className='flex flex-col items-center w-full mb-5 sm:mb-0 sm:w-auto sm:pr-8 justify-between'>
              <div className='flex flex-col items-center'>
                <img src={search} alt='search' />
                <p className='para-text'>At the PTC we interview Proptechs to ensure legitimacy and quality, preparing an unbiased, objective profile for VCs to examine.</p>
              </div>
              <span className='find-out-more' onClick={() => window.open("https://www.theproptechconnection.com/about", "_blank")}>Find out more</span>
            </div>
            <div className='flex flex-col items-center w-full mb-5 sm:mb-0 sm:w-auto sm:pr-8 justify-between'>
              <div className='flex flex-col items-center'>
                <img src={bar} alt='search' />
                <p className='para-text'>We offer three pricing tiers providing you a tailored experience suitable for your company.
                </p>
              </div>
              <span className='find-out-more' onClick={() => handleChangePlanModal()}>Find out more</span>
            </div>
            <div className='w-full flex flex-col items-center sm:mb-0 sm:w-auto  justify-between'>
              <div className='flex flex-col items-center'>
                <img src={calender} alt='search' />
                <p className='para-text'>Our objective is to secure you a meeting with your selected Proptech(s). The PTC does not take a cut of any future deal.
                </p>
              </div>
              <span className='find-out-more' onClick={() => handleChangePlanModal()}>Find out more</span>
            </div>
          </div>
          <div className='flex justify-end footer-btn'>
            <PTButton label='Watch video' className="normal-case fill-gray btn mr-4" size='large' handleOnClickEvent={() => handleClose()} />
            <PTButton label='Get started' className="normal-case btn" size='large' handleOnClickEvent={() => handleClose()} />
          </div>
        </Box>
      </Modal>
    </div>
  )
}
