export default function adminUser(
  initialState = {
    isLoading: false,
    proptechs: [],
    isProptechSelected: false,
    selectedProptech: null,
    activeSort: 'createdAt',
    sortOrder: 'DESC',
    isArchived: false,
    search: '',
    preview: false,
    forYouProptechs: [],
    watchlistProptechs: [],
    activeTabIndex: 0
  },
  action
) {
  switch (action.type) {
    case 'PROPTECHS_SET_LOADING':
      return {
        ...initialState,
        isLoading: action.payload,
      };

    case 'PROPTECHS_LIST_SET':
      return {
        ...initialState,
        proptechs: action.payload,
      };

    case 'SELECTED_PROPTECH_SET':
      return {
        ...initialState,
        selectedProptech: action.payload,
      };

    case 'SET_IS_SELECTED_PROPTECH':
      return {
        ...initialState,
        isProptechSelected: action.payload,
      };

    case 'SET_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_IS_ARCHIVED':
      return {
        ...initialState,
        isArchived: action.payload,
      };

    case 'SET_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'SET_PREVIEW':
      return {
        ...initialState,
        preview: action.payload,
      };

    case 'SET_FOR_YOU_PROPTECHS':
      return {
        ...initialState,
        forYouProptechs: action.payload,
      };

    case 'SET_WATCHLIST_PROPTECHS':
      return {
        ...initialState,
        watchlistProptechs: action.payload,
      };

    case 'SET_PROPTECH_TAB_ACTIVE_TAB_INDEX':
      return {
        ...initialState,
        activeTabIndex: action.payload,
      };

    case 'ADMIN_USER_RESET':
      return {
        ...initialState,
        isLoading: false,
        proptechs: [],
        isProptechSelected: false,
        selectedProptech: null,
        activeSort: 'createdAt',
        sortOrder: 'DESC',
        isArchived: false,
        search: '',
        preview: false,
        forYouProptechs: [],
        watchlistProptechs: [],
        activeTabIndex: 0
      };

    default:
      return initialState;
  }
}
