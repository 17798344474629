export function numbersTabDTO(data) {
  return {
    id: data?.id || 0,
    cashProfile: data?.cashProfile || 0,
    customerType: data?.customerType || 0,
    profitability: data?.profitability || 0,
    salesConversion: data?.salesConversion || 0,
    speedToScale: data?.speedToScale || 0,
    stickiness: data?.stickiness || 0,
    proptechCompanyDetailsId: data?.proptechCompanyDetailsId || 0,
    proptechCompanyDetails: {
      futureGoalsAdmin: data?.proptechCompanyDetails?.futureGoalsAdmin || '',
    },
    proptechAnalysisCurrentGrossMargin: {
      id: data?.proptechAnalysisCurrentGrossMargin?.id || 0,
      name: data?.proptechAnalysisCurrentGrossMargin?.name || '',
    },
    proptechAnalysisCurrentNoOfCountry: {
      id: data?.proptechAnalysisCurrentNoOfCountry?.id || 0,
      name: data?.proptechAnalysisCurrentNoOfCountry?.name || '',
    },
    proptechAnalysisCurrentNoOfCustomer: {
      id: data?.proptechAnalysisCurrentNoOfCustomer?.id || 0,
      name: data?.proptechAnalysisCurrentNoOfCustomer?.name || '',
    },
    proptechAnalysisFutureGrossMargin: {
      id: data?.proptechAnalysisFutureGrossMargin?.id || 0,
      name: data?.proptechAnalysisFutureGrossMargin?.name || '',
    },
    proptechAnalysisFutureNoOfCountry: {
      id: data?.proptechAnalysisFutureNoOfCountry?.id || 0,
      name: data?.proptechAnalysisFutureNoOfCountry?.name || '',
    },
    proptechAnalysisFutureNoOfCustomer: {
      id: data?.proptechAnalysisFutureNoOfCustomer?.id || 0,
      name: data?.proptechAnalysisFutureNoOfCustomer?.name || '',
    },
    proptechAnalysisGrowthBlocker1: {
      id: data?.proptechAnalysisGrowthBlocker1?.id || 0,
      name: data?.proptechAnalysisGrowthBlocker1?.name || '',
    },
    proptechAnalysisGrowthBlocker2: {
      id: data?.proptechAnalysisGrowthBlocker2?.id || 0,
      name: data?.proptechAnalysisGrowthBlocker2?.name || '',
    },
    proptechAnalysisGrowthBlocker3: {
      id: data?.proptechAnalysisGrowthBlocker3?.id || 0,
      name: data?.proptechAnalysisGrowthBlocker3?.name || '',
    },
    futureGrossMargin: data?.futureGrossMargin || null,
    futureNoOfCountries: data?.futureNoOfCountries || null,
    futureNoOfCustomers: data?.futureNoOfCustomers || null,
    currentGrossMargin: data?.currentGrossMargin || null,
    currentNoOfCountries: data?.currentNoOfCountries || null,
    currentNoOfCustomers: data?.currentNoOfCustomers || null,
    growthBlocker1: data?.growthBlocker1 || null,
    growthBlocker2: data?.growthBlocker2 || null,
    growthBlocker3: data?.growthBlocker3 || null,
  }
}