import React, { useEffect, useState, } from 'react'
import { useSelector } from 'react-redux';
import PTButton from '../../../../components/GenericComponents/PTButton';
import Loader from '../../../../components/Loading';
import { MessageType } from '../../../../constants/General';
import { Actions } from '../../../../store/actions';
import { showMessage } from '../../../../utils/utility/snackbar';
import { dispatch, dispatchNoPayload } from '../../../../utils/utility/store';
import { DeleteUserMessage, INVESTOR_DETAILS_TAB, UNSAVED_CHANGES_MESSAGE } from '../../../constants';
import { deleteInvestor, getInvestors, setActiveTab } from '../../../utils/middleware/adminInvestors';
import ConfirmModal from '../../ConfirmModal';
import ForYouTab from '../ForYouTab';
import InteractionsTab from '../InteractionsTab';
import InvestorListCard from '../InvestorListCard';
import InvestoreProfileTab from '../InvestorProfileTab';
import PlanTab from '../PlanTab';
import WatchlistTab from '../WatchlistTab';
import './InvestorTab.scss';

export default function InvestorTab(props) {
  const { unsaveChange, setUnsaveChange } = props

  // Store
  const { isLoading, isInvestorSelected, selectedInvestor, activeSort, sortOrder, isArchived, search, activeTab } = useSelector(state => state.adminInvestor);
  const adminInvestor = useSelector(state => state.adminInvestor);
  const adminDropdowns = useSelector(state => state.adminDropdowns);
  const isRedirect = useSelector(state => state.admin.isRedirect);

  // State
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    getInvestors(isArchived, activeSort, sortOrder, search, !isRedirect)
  }, [activeSort, sortOrder, isArchived, search]);  // eslint-disable-line react-hooks/exhaustive-deps

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        className='h-full'
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className='h-full'>{children}</div>
        )}
      </div>
    );
  }

  useEffect(() => () => {
    isRedirect ? dispatch(Actions.Admin.SetIsRedirect, false) : dispatchNoPayload(Actions.AdminInvestor.AdminInvestorReset)
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading && <Loader />}
      <div className='w-full h-full box-border p-[30px] bg-[#FAFAFB] admin-proptech-tab investor-proptech-tab'>
        <div className='w-full h-full flex flex-wrap rounded-[32px] overflow-hidden bg-[#FFFFFF]'>
          <div className='h-full left-block'>
            <InvestorListCard unsaveChange={unsaveChange} />
          </div>
          <div className='h-full right-block'>
            <>
              <div className='w-full flex items-center right-top-heading bg-[#111D43] text-[#FFFFFF]'>
                <div className='w-full flex top-heading-listing'>
                  {
                    INVESTOR_DETAILS_TAB.map((tab, index) => {
                      return (
                        <div key={index}>
                          <span
                            className={`text-sm not-italic uppercase cursor-pointer ${activeTab === index ? `font-semibold` : `font-light`}`}
                            onClick={() => {
                              if (!unsaveChange.current) {
                                setActiveTab(index, selectedInvestor?.id, adminInvestor, adminDropdowns)
                                dispatch(Actions.AdminInvestor.SetActiveTab, index)
                              } else {
                                showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
                              }
                            }}
                          >
                            {tab?.panel}
                          </span>
                        </div>
                      )
                    })
                  }
                </div>
                {isInvestorSelected && <PTButton
                  label="DELETE"
                  size='large'
                  className='normal-case fill-delete-admin btn white-border'
                  handleOnClickEvent={() => setOpenDeleteModal(true)} />}
              </div>
              {isInvestorSelected &&
                <div className='md:h-[calc(100%-80px)] md:overflow-auto'>
                  <TabPanel value={activeTab} index={0}>
                    <InvestoreProfileTab setUnsaveChange={setUnsaveChange} />
                  </TabPanel>
                  <TabPanel value={activeTab} index={1}>
                    <InteractionsTab />
                  </TabPanel>
                  <TabPanel value={activeTab} index={2}>
                    <ForYouTab />
                  </TabPanel>
                  <TabPanel value={activeTab} index={3}>
                    <WatchlistTab />
                  </TabPanel>
                  <TabPanel value={activeTab} index={4}>
                    <PlanTab />
                  </TabPanel>
                </div>
              }
            </>
          </div>
        </div>
      </div>

      {openDeleteModal && <ConfirmModal open={openDeleteModal} handleCloseModal={() => setOpenDeleteModal(false)} message={DeleteUserMessage} handleSucces={() => deleteInvestor(selectedInvestor?.vcCompany?.id, isArchived, activeSort, sortOrder, search, setOpenDeleteModal)} />}
    </>
  )
}
