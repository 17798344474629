export default function vcUser(
  initialState = {
    user: null,
    notificationCheckUnread: false,
    notifications: [],
    notificationLoading: false,
    upcomingMeetings: { weekMeetings: [], upcomingMeetings: [] },
    previousMeetings: [],
    vcPlanDetails: null,
    vcCountDetails: null,
    changePlanModal: false,
  },
  action
) {
  switch (action.type) {
    case 'VC_USER_SET':
      return {
        ...initialState,
        user: action.payload,
      };

    case 'VC_USER_REMOVE':
      return {
        ...initialState,
        user: null,
      };

    case 'VC_NOTIFICATION_CHECK_UNREAD':
      return {
        ...initialState,
        notificationCheckUnread: action.payload,
      };

    case 'VC_SET_NOTIFICATIONS':
      return {
        ...initialState,
        notifications: action.payload,
      };

    case 'SET_NOTIFICATION_LOADING':
      return {
        ...initialState,
        notificationLoading: action.payload
      };

    case 'SET_VC_UPCOMING_MEETINGS':
      return {
        ...initialState,
        upcomingMeetings: action.payload
      };


    case 'SET_VC_PREVIOUS_MEETINGS':
      return {
        ...initialState,
        previousMeetings: action.payload
      };

    case 'SET_VC_ACTIVE_PLAN':
      return {
        ...initialState,
        vcPlanDetails: action.payload
      };

    case 'SET_VC_COUNT_DETAILS':
      return {
        ...initialState,
        vcCountDetails: action.payload
      };
    case 'SET_VC_CHANGE_PLAN_MODAL':
      return {
        ...initialState,
        changePlanModal: action.payload,
      };

    case 'VC_USER_RESET':
      return {
        ...initialState,
        user: null,
        notificationCheckUnread: false,
        notifications: [],
        notificationLoading: false,
        upcomingMeetings: { weekMeetings: [], upcomingMeetings: [] },
        previousMeetings: [],
        vcPlanDetails: null,
        vcCountDetails: null,
        changePlanModal: false,
      };

    default:
      return initialState;
  }
}
