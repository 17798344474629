import { useNavigate } from "react-router-dom";
import PTButton from "../../../components/GenericComponents/PTButton";
import LeftLogo from '../../../components/LeftLogo';
import { LandingLabels } from "../../constants/general";

function VSLanding() {
  const navigate = useNavigate()

  return (
    <div className="w-screen flex proptech-layout-wrapper flex-wrap">
      <div className="w-full md:w-3/5">
        <LeftLogo />
      </div>
      <div className="md:min-h-screen w-full md:w-2/5 items-center inline-flex relative">
        <div className="wrapper-container">
          <div className="flex flex-col">
            <div className="text-wrapper"><h2>{LandingLabels.title}</h2></div>
            <PTButton label={LandingLabels.signUp} className="my-5 normal-case fill-orange btn " size='large' handleOnClickEvent={() => navigate('/venture-scout/signup')} />
            <PTButton label={LandingLabels.login} variant='outlined' className="normal-case btn" size='large' color='primary' handleOnClickEvent={() => window.location.href = '/venture-scout/login'} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VSLanding;

