import React, { useEffect, useState } from 'react';
import { Avatar, Menu, MenuItem, Tooltip } from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import NotificationIcon from "../../../assets/Icons/proptech-notification.svg";
import PTButton from '../../../components/GenericComponents/PTButton';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { getProptechLocalStorageUserData, removeProptechAccessToken, removeProptechLocalStorageUserData } from '../../../utils/session';
import Logo from '../../../assets/Icons/ic-tringle-proptech.svg';
import { useSelector } from 'react-redux';
import { clearNotification, getNotifications, readNotification } from '../../utils/middleware/notifications';
import NotificationModal from '../../../components/Notifications';
import { NotificationIconStyle } from '../../../constants/General';
import daimondIcon from '../../../assets/Icons/ic-daimond-gray.svg';
import paymentIcon from '../../../assets/Icons/ic-payment-gray.svg';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HelpMenu from '../../../components/HelpMenu';
import './proptechNavbar.scss';
import VideoModal from '../../../components/VideoModal';
import { INTRODUCTRY_VIDEO_URL } from '../../../vc/constants';
import { formatTime } from '../../../utils/utility/utility';

export default function ProptechNavbar(props) {
  // Props
  const { setOpenModal, setOpenManageUsersModal, setOpenProfile, setOpenChangePlanModal, setOpenChangePaymentMethodModal, setActivePaymentMethodLoading } = props

  // State
  const [userData, setUserData] = useState({})
  const [notificationMenu, setNotificationMenu] = useState()
  const [userMenu, setUserMenu] = useState()
  const [helpMenu, setHelpMenu] = useState()
  const [openVideoModal, setIsOpenVideoModal] = React.useState(false);
  const open = Boolean(helpMenu);
  const openUser = Boolean(userMenu);
  const openNotification = Boolean(notificationMenu);

  const { isLoading, notificationCheckUnread, notifications } = useSelector(state => state.proptechUser);

  useEffect(() => setUserData(getProptechLocalStorageUserData()), [])

  // Handle help menu
  const handleClick = (event) => {
    setHelpMenu(event.currentTarget);
  };

  const handleClose = () => {
    setHelpMenu(null);
  };

  // Handle user data menu
  const handleClick_u = (event) => {
    setUserData(getProptechLocalStorageUserData())
    setUserMenu(event.currentTarget);
  };

  const handleClose_u = () => {
    setUserMenu(null);
  };

  const handleLogoutClick = () => {
    removeProptechAccessToken()
    removeProptechLocalStorageUserData()
    window.location.href = '/proptech/login';
  }

  // Handle notification menu
  const openNotificationModal = (event) => {
    setNotificationMenu(event.currentTarget);
  };

  const closeNotificationModal = () => {
    setNotificationMenu(null);
  };

  return (
    <>
      <div className='bg-[#111D43] flex flex-row items-center justify-between lg:h-[120px] sm:h-[82px] h-[70px] pr-12 fixed inset-x-0 w-full z-[99] proptech-dashboard-header'>
        <div className='flex items-center'>
          <img src={Logo} alt="" className="lg:h-[120px] sm:h-[82px] h-[70px]" />
          <div className='sm:ml-7 ml-2 proptech-nav-main-title'>
            <h2 className='lg:text-[32px] md:text-[26px] sm:text-[22px] text-[20px] text-white'>Welcome <span>{userData?.name}</span></h2>
            <p className='text-[#70778E] sm:text-[14px] text-[12px]'>{userData?.lastLoginAt ? formatTime(userData?.lastLoginAt, 'dddd, DD MMMM YYYY') : ''}</p>
          </div>
        </div>
        {/* Help menu */}
        <div className='flex items-center'>
          <Tooltip title="Help">
            <IconButton
              onClick={(e) => handleClick(e)}
              size="small"
              sx={{ ml: 2, width: 40, height: 40 }}
              aria-controls={open ? 'help-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              className={open ? "open-menu" : " "}
            >
              <HelpOutlineIcon className='text-white' />
            </IconButton>
          </Tooltip>
          <div className='notification-icon mr-2 xsm:mr-4 lg:mr-6'>
            <IconButton
              onClick={(e) => {
                openNotificationModal(e)
                notifications?.length === 0 && getNotifications()
              }}
              size="small"
              sx={NotificationIconStyle}
              aria-controls={openNotification ? 'notification-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openNotification ? 'true' : undefined}
              className={openNotification ? "open-menu" : " "}
            >
              <Badge className="badge-orange" variant={notificationCheckUnread && "dot"}>
                <img src={NotificationIcon} alt="notification" />
              </Badge>
            </IconButton>
          </div>
          <div onClick={(e) => handleClick_u(e)} className='user-icon'>
            {userData?.name?.split(' ').map(word => word[0]).join('')}
          </div>
        </div>
      </div >
      {/* User menu */}
      {openUser && <Menu
        anchorEl={userMenu}
        id="user-menu-proptech"
        className="dropdown_menu"
        open={openUser}
        onClose={handleClose_u}
        onClick={handleClose_u}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 15,
              width: 10,
              height: 100,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className="profile-wrapper">
          <div className="user-wrapper">
            <Avatar sx={{ width: 80, height: 80 }}>{userData?.name?.split(' ').map(word => word[0]).join('')}</Avatar>
            <h2>{userData?.name}</h2>
            <a href={`mailto:${userData?.email}`}>{userData?.email}</a>
            <div className="user-contact">
              If your details need updating, contact us at
              <a href="mailto:hello@theproptechconnection.com">hello@theproptechconnection.com</a>
            </div>
            <PTButton label='Log Out' className="normal-case fill-gray btn" size='large' handleOnClickEvent={handleLogoutClick} />
          </div>
          <div className="user-profile-menu">
            <div className="user-txt">
              <span>Company</span>
              <h2>{userData?.company?.name}</h2>
              {userData?.planName && <span className="badge blue-badge">{userData?.planName}</span>}
            </div>
          </div>
        </div>
        <MenuItem onClick={() => setOpenChangePlanModal(true)}>
          <em className='img-icon'><img src={paymentIcon} alt="Change plan" /></em>
          <span>{userData?.isPlanExpired ? 'Choose plan' : 'Change plan'}</span>
        </MenuItem>
        <MenuItem onClick={() => {
          setActivePaymentMethodLoading(true)
          setOpenChangePaymentMethodModal(true)
        }}>
          <em className='img-icon'><ManageAccountsOutlinedIcon style={{ color: "#70778E" }} /></em>
          <span>Change payment method</span>
        </MenuItem>
        <MenuItem onClick={() => setOpenProfile(true)}>
          <em className='img-icon'><img src={daimondIcon} alt="View company profile" /></em>
          <span>View profile</span>
        </MenuItem>
        <MenuItem className='flex items-center' onClick={() => setOpenManageUsersModal(true)}>
          <em className='img-icon'><PersonOutlineOutlinedIcon style={{ color: "#70778E" }} /></em>
          <span>My account</span>
        </MenuItem>
        <MenuItem onClick={() => setOpenModal(true)}>
          <em className='img-icon'><ManageAccountsOutlinedIcon style={{ color: "#70778E" }} /></em>
          <span>Change password</span>
        </MenuItem>
      </Menu>
      }
      {/* Help menu */}
      {open && <HelpMenu
        helpMenu={helpMenu}
        open={open}
        handleClose={handleClose}
        handleWatchMenuClick={() => setIsOpenVideoModal(true)}
      />}
      {/* Notification menu */}
      <NotificationModal
        open={openNotification}
        loading={isLoading}
        anchor={notificationMenu}
        notifications={notifications}
        handleClose={closeNotificationModal}
        readNotification={readNotification}
        clearNotification={clearNotification}
      />
      {openVideoModal && <VideoModal
        videoUrl={INTRODUCTRY_VIDEO_URL}
        openVideoModal={openVideoModal}
        closeVideoModal={() => setIsOpenVideoModal(false)}
      />}
    </>
  )
}
