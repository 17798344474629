import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import PTButton from '../../../components/GenericComponents/PTButton';
import ProptechListSidebar from '../../components/ProptechListSidebar'
import AnalysisBlock from './AnalysisBlock';
import AnswerBlock from './AnswerBlock';
import SnapshotBlock from './SnapshotBlock';
import { DeleteUserMessage, PROPTEC_DETAILS_TAB, UNSAVED_CHANGES_MESSAGE } from '../../constants';
import { deleteProptech, getProptechs, updatePublishStatus } from '../../../utils/middleware/_admin/Proptechs';
import { Store } from '../../../store/configureStore';
import { Actions } from '../../../store/actions';
import Loader from '../../../components/Loading';
import PreviewScreen from '../../components/PreviewScreen';
import { dispatch, dispatchNoPayload } from '../../../utils/utility/store';
import ConfirmModal from '../../components/ConfirmModal';
import ForYouTab from './ForYouTab';
import WatchlistTab from './WatchlistTab';
import { setActivetabData } from '../../utils/middleware/adminProptechTab';
import './ProptechTab.scss';
import { showMessage } from '../../../utils/utility/snackbar';
import { MessageType } from '../../../constants/General';
import Plan from './Plan';

export default function ProptechTab(props) {
  const { unsaveChange, setUnsaveChange } = props

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  // Store
  const { activeTabIndex, isProptechSelected, selectedProptech, activeSort, sortOrder, isArchived, search, preview } = useSelector(state => state.adminUser);
  const isRedirect = useSelector(state => state.admin.isRedirect);
  const adminDropdowns = useSelector(state => state.adminDropdowns)
  const adminAnalysisTab = useSelector(state => state.adminAnalysisTab)

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleToGetProptech = async (isArchived, activeSort, sortOrder, search, clear = true) => {
    setIsLoading(true)
    try {
      await getProptechs(isArchived, activeSort, sortOrder, search, clear);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      console.log('error');
    }
  }

  useEffect(() => {
    handleToGetProptech(isArchived, activeSort, sortOrder, search, !isRedirect)
  }, [activeSort, sortOrder, isArchived, search]);  // eslint-disable-line react-hooks/exhaustive-deps

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        className='h-full'
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className='h-full'>{children}</div>
        )}
      </div>
    );
  }

  const handlePublish = async () => {
    setIsLoading(true);
    try {
      let result = await updatePublishStatus(selectedProptech?.id, { "isPublished": !selectedProptech?.isPublished }, isArchived, activeSort, sortOrder, search);
      if (result === 200) {
        Store.dispatch({
          type: Actions.AdminUser.SetSelectedProptech, payload: {
            ...selectedProptech,
            isPublished: !selectedProptech?.isPublished
          }
        })
      }
      setIsLoading(false);
      handleCloseModal()
    } catch {
      console.log('error')
      setIsLoading(false);
      handleCloseModal()
    }
  }

  useEffect(() => () => {
    isRedirect ? dispatch(Actions.Admin.SetIsRedirect, false) : dispatchNoPayload(Actions.AdminUser.AdminUserReset)
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className='w-full h-full box-border p-[30px] bg-[#FAFAFB] admin-proptech-tab'>
        <div className='w-full h-full flex flex-wrap rounded-[32px] overflow-hidden bg-[#FFFFFF]'>
          <div className='h-full left-block'>
            <ProptechListSidebar unsaveChange={unsaveChange} />
          </div>
          <div className='h-full right-block'>
            <>
              <div className='w-full flex items-center justify-between right-top-heading bg-[#111D43] text-[#FFFFFF]'>
                <div className='w-full flex top-heading-listing'>
                  {
                    PROPTEC_DETAILS_TAB.map((tab, index) => {
                      return (
                        <div key={index}>
                          <span
                            className={`text-sm not-italic uppercase cursor-pointer ${activeTabIndex === index ? `font-semibold` : `font-light`}`}
                            onClick={() => {
                              if (!unsaveChange.current) {
                                setActivetabData(index, selectedProptech?.id, adminAnalysisTab, adminDropdowns)
                              } else {
                                showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
                              }
                            }}
                          >
                            {tab?.panel}
                          </span>
                        </div>
                      )
                    })
                  }
                </div>
                {
                  isProptechSelected && selectedProptech?.status !== 2 ?
                    <div className='flex mr-5'>
                      <PTButton label='PREVIEW' size='large' className='normal-case fill-blue-admin btn white-border mr-5'
                        handleOnClickEvent={() => {
                          if (!unsaveChange.current) {
                            dispatch(Actions.AdminUser.SetPreview, true)
                          } else {
                            showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
                          }
                        }} />
                      {
                        selectedProptech?.status ?
                          <PTButton
                            label={`${selectedProptech?.isPublished ? `UNPUBLISH` : `PUBLISH`}`}
                            size='large'
                            className='normal-case fill-white-admin btn'
                            handleOnClickEvent={() => {
                              if (!unsaveChange.current) {
                                handleOpenModal()
                              } else {
                                showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
                              }
                            }} />
                          : null
                      }
                    </div>
                    : null
                }
                {isProptechSelected && <PTButton
                  label="DELETE"
                  size='large'
                  className='normal-case fill-delete-admin btn white-border'
                  handleOnClickEvent={() => setOpenDeleteModal(true)} />}
              </div>
              {isProptechSelected ?
                <div className='md:h-[calc(100%-80px)] md:overflow-auto'>
                  <TabPanel value={activeTabIndex} index={0}>
                    <SnapshotBlock />
                  </TabPanel>
                  <TabPanel value={activeTabIndex} index={1}>
                    <AnswerBlock setUnsaveChange={setUnsaveChange} />
                  </TabPanel>
                  <TabPanel value={activeTabIndex} index={2}>
                    <AnalysisBlock unsaveChange={unsaveChange} setUnsaveChange={setUnsaveChange} />
                  </TabPanel>
                  <TabPanel value={activeTabIndex} index={3}>
                    <ForYouTab />
                  </TabPanel>
                  <TabPanel value={activeTabIndex} index={4}>
                    <WatchlistTab />
                  </TabPanel>
                  <TabPanel value={activeTabIndex} index={5}>
                    <Plan />
                  </TabPanel>
                </div>
                : null}
            </>
          </div>
        </div>
      </div>

      {preview && <PreviewScreen closeIconClick={() => dispatch(Actions.AdminUser.SetPreview, false)} />}
      {openModal && <ConfirmModal open={openModal} handleCloseModal={handleCloseModal} handleSucces={handlePublish} />}
      {openDeleteModal && <ConfirmModal open={openDeleteModal} handleCloseModal={() => setOpenDeleteModal(false)} message={DeleteUserMessage} handleSucces={() => deleteProptech(selectedProptech?.proptechCompany?.id, isArchived, activeSort, sortOrder, search, setOpenDeleteModal)} />}
    </>
  )
}
