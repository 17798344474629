import { ADMIN_PROPTECHS } from '../../../../constants/APIs';
import { Actions } from '../../../../store/actions';
import { Store } from '../../../../store/configureStore';
import { proptechsListDTO } from '../../../dtos/adminDetails';
import { ADMIN_API } from '../../../utility/API-call';
import { handleError, handleSuccess } from '../../../utility/snackbar';
import { dispatch } from '../../../utility/store';

export const getProptechs = (isArchived, activeSort, sortOrder, search, clear = true) => {
  const params = {
    filter: { "isArchived": isArchived },
    ...(!!search ? { search } : {}),
    ...(!!sortOrder ? { 'order': sortOrder } : {}),
    ...(!!activeSort ? { 'orderBy': activeSort } : {}),
  }

  return ADMIN_API().get(ADMIN_PROPTECHS, { params })
    .then(res => {
      handleSuccess(res)
      if (res?.data?.data) {
        let proptechs = proptechsListDTO(res?.data?.data?.rows)
        dispatch(Actions.AdminUser.ProptechsListSet, proptechs)
        if (clear) {
          dispatch(Actions.AdminUser.SetIsSelectedProptech, false)
          dispatch(Actions.AdminUser.SetSelectedProptech, null)
        }
        return res?.status;
      }
    }).catch(err => {
      handleError(err)
      return err?.response?.status;
    })
}

export const updateProptechsAnswers = (newData, proptechId, selectedProptech, isArchived, activeSort, sortOrder, search) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  return ADMIN_API().put(`${ADMIN_PROPTECHS}/${proptechId}/answers`, newData)
    .then(res => {
      handleSuccess(res);
      getProptechs(isArchived, activeSort, sortOrder, search, false)
      dispatch(Actions.AdminUser.SetLoading, false)
      return res?.status;
    }).catch(err => {
      handleError(err);
      dispatch(Actions.AdminUser.SetLoading, false)
      return err?.response?.status;
    });
}

export const updatePublishStatus = async (proptechId, status, isArchived, activeSort, sortOrder, search) => {
  return ADMIN_API().put(`${ADMIN_PROPTECHS}/${proptechId}/publish`, status)
    .then(res => {
      handleSuccess(res);
      getProptechs(isArchived, activeSort, sortOrder, search);
      console.log('line 28', res);
      return res?.status
    }).catch(err => {
      handleError(err)
      console.log('line 31', err);
      return err?.response?.status
    });
}

export const updateArchiveStatus = (proptechId, status, data, isArchived, activeSort, sortOrder, search) => {
  return ADMIN_API().put(`${ADMIN_PROPTECHS}/${proptechId}/archive`, status)
    .then(res => {
      handleSuccess(res);
      getProptechs(isArchived, activeSort, sortOrder, search);
      Store.dispatch({
        type: Actions.AdminUser.SetSelectedProptech, payload: {
          ...data,
          ...status
        }
      })
      return res?.status
    }).catch(err => {
      handleError(err)
      return err?.response?.status
    }
    );
}

export const updateActivetatus = (proptechId, status, data, isArchived, activeSort, sortOrder, search) => {
  return ADMIN_API().put(`${ADMIN_PROPTECHS}/${proptechId}/status`, status)
    .then(res => {
      handleSuccess(res);
      getProptechs(isArchived, activeSort, sortOrder, search);
      Store.dispatch({
        type: Actions.AdminUser.SetSelectedProptech, payload: {
          ...data,
          ...status
        }
      })
      return res?.status
    }).catch(err => {
      handleError(err)
      return err?.response?.status
    });
}

export const deleteProptech = (proptechId, isArchived, activeSort, sortOrder, search, handleClose) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  ADMIN_API().delete(`${ADMIN_PROPTECHS}/permanently-delete/${proptechId}`)
    .then(res => {
      handleSuccess(res);
      getProptechs(isArchived, activeSort, sortOrder, search);
      handleClose(false)
    }).catch(err => {
      handleError(err)
      dispatch(Actions.AdminUser.SetLoading, false)
    });
}
