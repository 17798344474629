export function activeUsersDTO(response) {
  let activeUsers = [];
  if (response && response.length) {
    activeUsers = response.map((data) => {
      return {
        id: data?.id || 0,
        name: data?.id || '',
        email: data?.email || '',
        title: data?.email || null
      }
    })
  }

  return activeUsers;
}
