import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { VC_MEETONGCOUNTS_API } from '../../../constants/APIs';
import { Actions } from '../../../store/actions';
import { getVcAccessToken } from '../../../utils/session';
import { VC_API } from '../../../utils/utility/API-call';
import { handleError } from '../../../utils/utility/snackbar';
import { dispatch } from '../../../utils/utility/store';

// Access of Routes For logged in user
const PrivateRoute = ({ children }) => {
  const isLogin = getVcAccessToken()

  const getMeetingCounts = () => {
    VC_API().get(VC_MEETONGCOUNTS_API)
      .then((res) => {
        if (res.data.data) {
          dispatch(Actions.VCUser.SetActivePlan, res?.data?.data?.vcPlanDetails)
          dispatch(Actions.VCUser.SetCountDetails, res?.data?.data)
        }
        if (res?.data?.code !== 200) {
          handleError(res?.data);
        }
      }).catch((err) => {
        handleError(err);
      });
  }

  useEffect(() => {
    getMeetingCounts();
  }, [])

  return isLogin ? children : <Navigate to='/investor/login' />
}
export default PrivateRoute;