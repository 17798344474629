import moment from 'moment';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Actions } from '../../../../store/actions';
import { dispatch } from '../../../../utils/utility/store';
import ProptechCardLabel from '../../ProptechCardLabel';
import ArrowRightIcon from '../../../../assets/Icons/admin-arrow-right.svg';
import ArrowOpened from '../../../../assets/Icons/admin-arrow-opened.svg';
import defaultProfile from '../../../../assets/Icons/default-profile.svg';
import PTButton from '../../../../components/GenericComponents/PTButton';
import ConfirmModal from '../../ConfirmModal';
import { setActiveTab, updateActivetatus, updateArchiveStatus } from '../../../utils/middleware/adminInvestors';
import { showMessage } from '../../../../utils/utility/snackbar';
import { UNSAVED_CHANGES_MESSAGE } from '../../../constants';
import { MessageType } from '../../../../constants/General';

export default function InvestorCard(props) {
  //  **** props ****
  const { investor, unsaveChange } = props

  // State
  const [activeStatus, setActiveStatus] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  // Store
  const { isInvestorSelected, selectedInvestor, activeSort, sortOrder, isArchived, search, activeTab } = useSelector(state => state.adminInvestor);
  const adminInvestor = useSelector(state => state.adminInvestor);
  const adminDropdowns = useSelector(state => state.adminDropdowns);

  const handleSelected = () => {
    if (!unsaveChange.current) {
      let isSelected = selectedInvestor?.id !== investor.id ? true : !isInvestorSelected
      dispatch(Actions.AdminInvestor.SetIsSelectedInvestor, isSelected)
      dispatch(Actions.AdminInvestor.SetSearchProptech, '')
      if (investor.id === selectedInvestor?.id) {
        dispatch(Actions.AdminInvestor.SetIsSelectedInvestor, false)
        dispatch(Actions.AdminInvestor.SetSelectedInvestor, null)
      } else {
        dispatch(Actions.AdminInvestor.SetSelectedInvestor, investor)
        setActiveTab(activeTab, investor?.id, adminInvestor, adminDropdowns)
      }
    } else {
      showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
    }
  }

  return (
    <>
      <div className='border-solid border-t border-[#111D43] opacity-40' />
      <div
        id={`investor-${investor?.id}`}
        className={`w-full cursor-pointer ${investor?.status === 1 ? 'bg-[#DFEEE2]'
          : investor?.status === 2 ? 'bg-[#E7E8EC]'
            : `bg-[#FFFFFF]`} `}
        onClick={() => handleSelected()}
      >
        <div className='proptech-tab-heading'>
          <div className='flex box-border items-center '>
            <div className='profile-img mr-[15px] w-[35px] h-[35px] xlg:h-[46px] xlg:w-[46px] rounded-full min-w-[35px] xlg:min-w-[46px]'>
              <img
                src={investor?.companyLogo || defaultProfile}
                className="rounded-full bg-[#70778E] h-full w-full object-cover"
                alt="Profile"
              />
            </div>
            <div className='grow'>
              <div className='flex justify-between'>
                <span className='font-medium text-sm leading-5 not-italic text-[#111D43]'>{investor.vcCompany.name}</span>
              </div>
              <div className='flex'>
                <ProptechCardLabel
                  header="Country"
                  className="xlg:min-w-[104px] has-align-start"
                  label={investor?.country?.name ? investor?.country.name : '-'}
                  style={{ marginRight: '4px' }} />
                <ProptechCardLabel
                  header="Meetings"
                  className="xlg:min-w-[104px] has-align-start"
                  label={investor?.totalMeetingCount || 0}
                  style={{ marginRight: '4px' }} />
              </div>
              <div className='flex'>
                <ProptechCardLabel
                  header="Created"
                  className="xlg:min-w-[104px] has-align-start"
                  label={investor?.createdAt ? moment.tz(investor?.createdAt, "Australia/Sydney").format('DD MMM YYYY') : '-'}
                  style={{ marginRight: '4px' }} />
                <ProptechCardLabel
                  header="Last active"
                  className="xlg:min-w-[104px] has-align-start"
                  label={investor?.lastLoginAt ? moment.tz(investor?.lastLoginAt, "Australia/Sydney").format('DD MMM YYYY') : '-'}
                  style={{ marginRight: '4px' }} />
              </div>
            </div>
            <img src={isInvestorSelected && selectedInvestor.id === investor.id ? ArrowOpened : ArrowRightIcon} alt="Icon" className='ml-[20px]' />
          </div>
          <div className='w-full box-border items-center flex justify-end proptech-heading-btn'>
            {investor?.status === 0 ?
              <PTButton
                variant="contained"
                label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Reject</span>}
                type="submit"
                className='mx-3 !rounded-xl !border-solid !bg-[#111D43]'
                handleOnClickEvent={(e) => {
                  e.stopPropagation()
                  if (!unsaveChange.current) {
                    setOpenModal2(true)
                    setActiveStatus(2)
                  } else {
                    showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
                  }
                }}
              />
              : null}
            {investor?.status !== 1 ?
              <PTButton
                variant="contained"
                label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Accept</span>}
                type="submit"
                className='mx-3 !rounded-xl !border-solid !bg-[#111D43]'
                handleOnClickEvent={(e) => {
                  e.stopPropagation()
                  if (!unsaveChange.current) {
                    setOpenModal2(true)
                    setActiveStatus(1)
                  } else {
                    showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
                  }
                }}
              />
              : null}
          </div>
        </div>
      </div>
      {
        isInvestorSelected && selectedInvestor.id === investor.id &&
        <>
          <div className='border-solid border-t border-[#111D43] opacity-40' />
          <div className={` w-full box-border 
            ${investor?.status === 1 ? 'bg-[#DFEEE2]'
              : investor?.status === 2 ? 'bg-[#E7E8EC]'
                : `bg-[#FFFFFF]`} 
            detail-block`}>
            <div className='sidebar-info-wrap'>
              <span className='detail-label'>Primary contact</span>
              <p className='text-sm'>{investor.vcUser.primaryUserName || '-'}</p>
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>contact email</span>
              <p className='text-sm'>{investor.vcUser.email || `-`}</p>
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>associated contacts</span>
              {investor?.vcCompany?.vcUsers.length ? investor.vcCompany.vcUsers.map((user) => {
                return (
                  <PTButton
                    key={user?.id}
                    variant="contained"
                    label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">{user?.name}</span>}
                    type="submit"
                    className='!rounded-xl !bg-[rgba(17,29,67,0.1)] mr-3 mb-2 gray-btn'
                    handleOnClickEvent={() => {
                      dispatch(Actions.Admin.SetActiveAdminTab, 4)
                      dispatch(Actions.Contacts.SetAlreadySelectedContact, { id: user?.id, userType: 'VC' })
                      dispatch(Actions.Contacts.SetIsSelectedContact, true)
                    }}
                  />
                )
              }) : `-`}
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>total users</span>
              <p className='text-sm'>{investor?.vcCompany?.vcUsers?.length + 1 || `-`}</p>
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>meetings cancelled</span>
              <p className='text-sm'>{investor?.cancelledMeetingCount || 0}</p>
            </div>
            <div className='flex justify-between items-center'>
              <PTButton
                label={`${investor?.isArchived ? `UNARCHIVE` : `ARCHIVE`}`}
                color='primary'
                size='large'
                className='text-sm btn'
                handleOnClickEvent={() => {
                  if (!unsaveChange.current) {
                    setOpenModal(true)
                  } else {
                    showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
                  }
                }}
              />
            </div>
          </div>
        </>
      }

      {openModal && <ConfirmModal open={openModal} handleCloseModal={() => setOpenModal(false)} handleSucces={() => updateArchiveStatus(investor?.id, { isArchived: !investor?.isArchived }, isArchived, activeSort, sortOrder, search, setOpenModal)} />}
      {openModal2 && <ConfirmModal open={openModal2} handleCloseModal={() => setOpenModal2(false)} handleSucces={() => updateActivetatus(investor?.id, { 'status': activeStatus }, investor, isArchived, activeSort, sortOrder, search, setOpenModal2)} />}
    </>
  )
}
