import { MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PTButton from '../../../../components/GenericComponents/PTButton';
import PTInput from '../../../../components/GenericComponents/PTInput';
import Loader from '../../../../components/Loading';
import { AnalysisLabelStyle } from '../../../../proptech/constants/General';
import { Actions } from '../../../../store/actions';
import { dispatch } from '../../../../utils/utility/store';
import { trimQuery } from '../../../../utils/utility/utility';
import { COMPANY_PROFILE_TAB_BUSINESS_MODEL, COMPANY_PROFILE_TAB_COMMERCIAL_PROBLEM, COMPANY_PROFILE_TAB_CUSTOMER_BREAKDOWN, COMPANY_PROFILE_TAB_CUSTOMER_KEY_POINT, COMPANY_PROFILE_TAB_DEFENSIBILITY_AND_TIMING, COMPANY_PROFILE_TAB_FORECAST_AND_METRICS, COMPANY_PROFILE_TAB_GO_TO_MARKET, COMPANY_PROFILE_TAB_SKILL, COMPANY_PROFILE_TAB_SOLUTION, COMPANY_PROFILE_TAB_VALUE_PROPOSITION, COMPANY_PROFILE_TAB_WHY_GOOD_FIT } from '../../../constants';
import { updateCompanyProfileDetails } from '../../../utils/middleware/companyProfileTab';
import { COMPANY_PROFILE_TAB_ERROR, COMPANY_PROFILE_TAB_PAYLOAD } from '../../../utils/payloads/payloads';
import ConfirmModal from '../../ConfirmModal';
import FullWidthTextField from '../../FullWidthTextField';
import DropdownWithTextField from '../../DropdownWithTextField';
import { isUnsaveChanges } from '../../../utils/middleware/analysisTab';

export default function CompanyProfile(props) {
  const { setUnsaveChange } = props

  // Store
  const companyProfileTabData = useSelector(state => state.adminAnalysisTab.companyProfileTabData)
  const selectedProptech = useSelector(state => state.adminUser.selectedProptech)
  const isLoading = useSelector(state => state.adminUser.isLoading)
  const { keyBizModels, assetLifeCycles, defensibilityTimings } = useSelector(state => state.adminDropdowns)

  // State
  const [editedDetails, setEditedDetails] = useState(COMPANY_PROFILE_TAB_PAYLOAD);
  const [errors, setErrors] = useState(COMPANY_PROFILE_TAB_ERROR);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails))
    Object.keys(data).forEach(error => {
      data = {
        ...data, [error]: editedDetails?.[error] !== null ? !editedDetails?.[error] : !companyProfileTabData?.proptechAnalysisCompanyProfile?.[error]
      }
    })
    setErrors(data)
    return Object.keys(data).some(k => data[k])
  }

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => (newData[key] = companyProfileTabData?.proptechAnalysisCompanyProfile?.[key]));

    handleCloseModal()
    try {
      await updateCompanyProfileDetails(
        selectedProptech?.id,
        {
          ...Object.fromEntries(Object.entries(editedDetails).filter(([_, v]) => !!v)),
          ...Object.fromEntries(Object.entries(newData).filter(([_, v]) => !!v)),
        })
    } catch {
      dispatch(Actions.AdminUser.SetLoading, false)
      console.log('error')
    }
  }

  useEffect(() => {
    isUnsaveChanges(editedDetails, setUnsaveChange)
  }, [editedDetails]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading && <Loader />}
      <div className='mb-[22px]'>
        <span className="admin-portal-title">Asset Life cycles</span>
        <div className='analysis-dropdown-wrapper horizontal mt-4 mx-0'>
          <div className='dropdown-items flex'>
            <div className="dropdown-inner-item">
              <PTInput
                labelStyle={AnalysisLabelStyle}
                className='custom-dropdown-icon'
                variant="standard"
                label="Asset Lifecycle 1"
                InputPropsStyle={{ fontSize: '12px' }}
                fullWidth
                select
                placeholder=''
                selectChildren={assetLifeCycles.map(option => <MenuItem value={option.id}>{option.name}</MenuItem>)}
                value={editedDetails?.assetLifecycle1 || companyProfileTabData?.proptechAnalysisCompanyProfile?.assetLifecycle1}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, assetLifecycle1: e.target.value })}
                error={errors.assetLifecycle1}
                helperText={errors.assetLifecycle1 && "Asset Lifecycle 1 Required."}
              />
            </div>
            <div className="dropdown-inner-item">
              <PTInput
                labelStyle={AnalysisLabelStyle}
                className='custom-dropdown-icon'
                variant="standard"
                label="Asset Lifecycle 2"
                InputPropsStyle={{ fontSize: '12px' }}
                fullWidth
                select
                placeholder=''
                selectChildren={assetLifeCycles.map(option => <MenuItem value={option.id}>{option.name}</MenuItem>)}
                value={editedDetails?.assetLifecycle2 || companyProfileTabData?.proptechAnalysisCompanyProfile?.assetLifecycle2}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, assetLifecycle2: e.target.value })}
                error={errors.assetLifecycle2}
                helperText={errors.assetLifecycle2 && "Asset Lifecycle 2 Required."}
              />
            </div>
            <div className="dropdown-inner-item">
              <PTInput
                labelStyle={AnalysisLabelStyle}
                className='custom-dropdown-icon'
                variant="standard"
                label="Asset Lifecycle 3"
                InputPropsStyle={{ fontSize: '12px' }}
                fullWidth
                select
                placeholder=''
                selectChildren={assetLifeCycles.map(option => <MenuItem value={option.id}>{option.name}</MenuItem>)}
                value={editedDetails?.assetLifecycle3 || companyProfileTabData?.proptechAnalysisCompanyProfile?.assetLifecycle3}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, assetLifecycle3: e.target.value })}
                error={errors.assetLifecycle3}
                helperText={errors.assetLifecycle3 && "Asset Lifecycle 3 Required."}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='analysis-dropdown-wrapper horizontal'>
        <div class="dropdown-title not-italic text-[#111D43]">Commercial problem</div>
        <FullWidthTextField
          editedDetails={editedDetails}
          setEditedDetails={setEditedDetails}
          errors={errors}
          data={companyProfileTabData?.proptechAnalysisCompanyProfile}
          textLabel="Problem Synopsis"
          textValue="problemSynopsis"
          showChar
        />
        {COMPANY_PROFILE_TAB_COMMERCIAL_PROBLEM.map(customer => {
          return <DropdownWithTextField
            editedDetails={editedDetails}
            setEditedDetails={setEditedDetails}
            errors={errors}
            data={companyProfileTabData?.proptechAnalysisCompanyProfile}
            dropdownLabel={customer.dropdownLabel}
            dropdownValue={customer.dropdownValue}
            textLabel={customer.textLabel}
            textValue={customer.textValue}
            showChar
          />
        })}
      </div>

      <div className='analysis-dropdown-wrapper horizontal'>
        <div class="dropdown-title not-italic text-[#111D43]">Solution</div>
        <FullWidthTextField
          editedDetails={editedDetails}
          setEditedDetails={setEditedDetails}
          errors={errors}
          data={companyProfileTabData?.proptechAnalysisCompanyProfile}
          textLabel="Solution Synopsis"
          textValue="solutionSynopsis"
          showChar
        />
        {COMPANY_PROFILE_TAB_SOLUTION.map(customer => {
          return <DropdownWithTextField
            editedDetails={editedDetails}
            setEditedDetails={setEditedDetails}
            errors={errors}
            data={companyProfileTabData?.proptechAnalysisCompanyProfile}
            dropdownLabel={customer.dropdownLabel}
            dropdownValue={customer.dropdownValue}
            textLabel={customer.textLabel}
            textValue={customer.textValue}
            showChar
          />
        })}
      </div>

      <div className='analysis-dropdown-wrapper horizontal'>
        <div class="dropdown-title not-italic text-[#111D43]">Business model and Go to market</div>
        {COMPANY_PROFILE_TAB_BUSINESS_MODEL.map(customer => {
          return <DropdownWithTextField
            editedDetails={editedDetails}
            setEditedDetails={setEditedDetails}
            errors={errors}
            data={companyProfileTabData?.proptechAnalysisCompanyProfile}
            dropdownLabel={customer.dropdownLabel}
            dropdownValue={customer.dropdownValue}
            textLabel={customer.textLabel}
            textValue={customer.textValue}
            showChar
          />
        })}
        {COMPANY_PROFILE_TAB_GO_TO_MARKET.map(customer => {
          return <DropdownWithTextField
            editedDetails={editedDetails}
            setEditedDetails={setEditedDetails}
            errors={errors}
            data={companyProfileTabData?.proptechAnalysisCompanyProfile}
            dropdownLabel={customer.dropdownLabel}
            dropdownValue={customer.dropdownValue}
            textLabel={customer.textLabel}
            textValue={customer.textValue}
            showChar
          />
        })}
      </div>

      <div className='analysis-dropdown-wrapper horizontal'>
        <div class="dropdown-title not-italic text-[#111D43]">Customers and validation</div>
        {COMPANY_PROFILE_TAB_CUSTOMER_BREAKDOWN.map(customer => {
          return <FullWidthTextField
            editedDetails={editedDetails}
            setEditedDetails={setEditedDetails}
            errors={errors}
            data={companyProfileTabData?.proptechAnalysisCompanyProfile}
            textLabel={customer.text}
            textValue={customer.value}
            showChar
          />
        })}
        {COMPANY_PROFILE_TAB_CUSTOMER_KEY_POINT.map(customer => {
          return <FullWidthTextField
            editedDetails={editedDetails}
            setEditedDetails={setEditedDetails}
            errors={errors}
            data={companyProfileTabData?.proptechAnalysisCompanyProfile}
            textLabel={customer.text}
            textValue={customer.value}
            showChar
          />
        })}
        <DropdownWithTextField
          editedDetails={editedDetails}
          setEditedDetails={setEditedDetails}
          errors={errors}
          dropdown
          data={companyProfileTabData?.proptechAnalysisCompanyProfile}
          dropdownOptions={keyBizModels}
          dropdownLabel="Current Traction 2"
          dropdownValue="currentTraction2Stat"
          textLabel="Current Traction 2 Value"
          textValue="currentTraction2"
          showChar
        />
      </div>

      <div className='analysis-dropdown-wrapper horizontal'>
        <div class="dropdown-title not-italic text-[#111D43]">Forecast and metrics</div>
        {COMPANY_PROFILE_TAB_FORECAST_AND_METRICS.map(customer => {
          return <DropdownWithTextField
            editedDetails={editedDetails}
            setEditedDetails={setEditedDetails}
            errors={errors}
            dropdown
            data={companyProfileTabData?.proptechAnalysisCompanyProfile}
            dropdownOptions={keyBizModels}
            dropdownLabel={customer.dropdownLabel}
            dropdownValue={customer.dropdownValue}
            textLabel={customer.textLabel}
            textValue={customer.textValue}
            showChar
          />
        })}
      </div>

      <div className='analysis-dropdown-wrapper horizontal'>
        <div class="dropdown-title not-italic text-[#111D43]">Value proposition</div>
        {COMPANY_PROFILE_TAB_VALUE_PROPOSITION.map(customer => {
          return <FullWidthTextField
            editedDetails={editedDetails}
            setEditedDetails={setEditedDetails}
            errors={errors}
            data={companyProfileTabData?.proptechAnalysisCompanyProfile}
            textLabel={customer.text}
            textValue={customer.value}
            showChar
          />
        })}
      </div>

      <div className='analysis-dropdown-wrapper horizontal'>
        <div class="dropdown-title not-italic text-[#111D43]">Why it’s a good fit</div>
        {COMPANY_PROFILE_TAB_WHY_GOOD_FIT.map(customer => {
          return <FullWidthTextField
            editedDetails={editedDetails}
            setEditedDetails={setEditedDetails}
            errors={errors}
            data={companyProfileTabData?.proptechAnalysisCompanyProfile}
            textLabel={customer.text}
            textValue={customer.value}
            showChar
          />
        })}
      </div>

      <div className='analysis-dropdown-wrapper horizontal'>
        <div class="dropdown-title not-italic text-[#111D43]">Defensibility and timing</div>
        {COMPANY_PROFILE_TAB_DEFENSIBILITY_AND_TIMING.map(customer => {
          return <DropdownWithTextField
            editedDetails={editedDetails}
            setEditedDetails={setEditedDetails}
            errors={errors}
            dropdown
            data={companyProfileTabData?.proptechAnalysisCompanyProfile}
            dropdownOptions={defensibilityTimings}
            dropdownLabel={customer.dropdownLabel}
            dropdownValue={customer.dropdownValue}
            textLabel={customer.textLabel}
            textValue={customer.textValue}
            showChar
          />
        })}
      </div>

      <div className='analysis-dropdown-wrapper horizontal'>
        <div class="dropdown-title not-italic text-[#111D43]">Team</div>
        {COMPANY_PROFILE_TAB_SKILL.map(customer => {
          return <FullWidthTextField
            editedDetails={editedDetails}
            setEditedDetails={setEditedDetails}
            errors={errors}
            data={companyProfileTabData?.proptechAnalysisCompanyProfile}
            textLabel={customer.text}
            textValue={customer.value}
            showChar
          />
        })}
      </div>

      <div className='flex justify-end bottom-btn-wrapper'>
        <PTButton variant="contained"
          label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Revert</span>}
          type="submit" className='w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]'
          handleOnClickEvent={() => setEditedDetails(COMPANY_PROFILE_TAB_PAYLOAD)}
        />
        <PTButton variant="contained"
          label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Save & Continue</span>} type="submit"
          className='w-[142px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]'
          handleOnClickEvent={() => !checkValidation() && handleOpenModal()}
        />

      </div>
      {openModal && <ConfirmModal open={openModal} handleCloseModal={handleCloseModal} handleSucces={updateData} />}
    </>
  )
}
