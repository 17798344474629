import React from 'react'
import { useSelector } from 'react-redux';
import { MessageType } from '../../../../constants/General';
import defaultProfile from '../../../../assets/Icons/default-profile.svg';
import { Actions } from '../../../../store/actions';
import { showMessage } from '../../../../utils/utility/snackbar';
import { dispatch } from '../../../../utils/utility/store';
import { UNSAVED_CHANGES_MESSAGE } from '../../../constants';
import ProptechCardLabel from '../../ProptechCardLabel';

export default function ContactCard(props) {
  //  **** props ****
  const { contact, unsaveChange } = props

  // Store
  const { isContactSelected, selectedContact } = useSelector(state => state.adminContacts);

  const handleSelected = () => {
    dispatch(Actions.Contacts.SetAlreadySelectedContact, null)
    if (!unsaveChange.current) {
      let isSelected = selectedContact?.id !== contact.id && selectedContact?.userType !== contact.userType ? true : !isContactSelected
      dispatch(Actions.Contacts.SetIsSelectedContact, isSelected)
      if (contact.id === selectedContact?.id && selectedContact?.userType === contact.userType) {
        dispatch(Actions.Contacts.SetIsSelectedContact, false)
        dispatch(Actions.Contacts.SetSelectedContact, null)
      } else {
        dispatch(Actions.Contacts.SetSelectedContact, contact)
      }
    } else {
      showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
    }
  }

  return (
    <>
      <div className='border-solid border-t border-[#111D43] opacity-40' />
      <div
        id={`contact-${contact?.id}`}
        className={`w-full cursor-pointer ${selectedContact?.id === contact.id && selectedContact?.userType === contact.userType ? `bg-[#E7E8EC]` : `bg-[#FFFFFF]`}
      `}
        onClick={() => handleSelected()}
      >
        <div className='proptech-tab-heading'>
          <div className='flex box-border items-center '>
            <div className='profile-img mr-[15px] w-[35px] h-[35px] xlg:h-[46px] xlg:w-[46px] rounded-full min-w-[35px] xlg:min-w-[46px]'>
              <img
                src={contact?.profileImage || defaultProfile}
                className="rounded-full bg-[#70778E] h-full w-full object-cover"
                alt="Profile"
              />
            </div>
            <div className='grow'>
              <div className='flex justify-between'>
                <span className='font-medium text-sm leading-5 not-italic text-[#111D43]'>{contact.name}</span>
              </div>
              <ProptechCardLabel
                header="Company name"
                className="xlg:min-w-[104px] has-direction-row"
                label={contact?.companyName || '-'}
                style={{ marginRight: '4px' }} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
