import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Loader from '../../../../components/Loading'
import { Actions } from '../../../../store/actions'
import { dispatchNoPayload } from '../../../../utils/utility/store'
import { getContacts } from '../../../utils/middleware/adminContacts'
import ContactCardDetails from '../ContactCatdDetails'
import ContactsListCard from '../ContactsListCard'

export default function ContactsTab(props) {
  const { unsaveChange, setUnsaveChange } = props

  // Store
  const { isLoading, selectedContact, alreadySelectedContact, activeSort, sortOrder, isPrimaryUser, activefilter, search } = useSelector(state => state.adminContacts);

  useEffect(() => {
    getContacts(isPrimaryUser, activeSort, sortOrder, search, activefilter, alreadySelectedContact)
  }, [activeSort, sortOrder, isPrimaryUser, activefilter, search]);

  useEffect(() => () => {
    dispatchNoPayload(Actions.Contacts.ResetContacts)
  }, [])

  return (
    <>
      {isLoading && <Loader />}
      <div className='w-full h-full box-border p-[30px] bg-[#FAFAFB] admin-proptech-tab investor-proptech-tab'>
        <div className='w-full h-full flex flex-wrap rounded-[32px] overflow-hidden bg-[#FFFFFF]'>
          <div className='h-full left-block'>
            <ContactsListCard unsaveChange={unsaveChange} />
          </div>
          <div className='h-full right-block'>
            <>
              <div className='w-full flex items-center justify-end right-top-heading bg-[#111D43] text-[#FFFFFF]' />
              <div className='md:h-[calc(100%-80px)] md:overflow-auto bg-[#FFFFFF] investor-card-wrapper'>
                {selectedContact && <ContactCardDetails setUnsaveChange={setUnsaveChange} />}
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  )
}
