// Reducers
import loader from './reducers/loader';
import vcAuth from './reducers/vcAuth';
import vcUser from './reducers/vcUser';
import vcProptechs from './reducers/vcProptechs';
import vcForYou from './reducers/vcForYou';
import vcProptechDetails from './reducers/vcProptechDetails';
import proptechAuth from './reducers/proptechAuth';
import proptechUser from './reducers/proptechUser';
import vsUser from './reducers/vsUser';
import vsDashboard from './reducers/vsDashboard';
import snackbar from './reducers/snackbar';
import adminUser from './reducers/adminUser';
import adminDropdowns from './reducers/adminDropdowns';
import adminAnalysisTab from './reducers/adminAnalysisTab';
import newsfeeds from './reducers/newsfeeds';
import vcCompanyProfile from './reducers/vcCompanyProfile';
import adminInvestor from './reducers/adminInvestor';
import adminVentureScout from './reducers/adminVentureScout';
import adminContacts from './reducers/adminContacts';
import admin from './reducers/admin';


// Utility Packages
import { combineReducers } from 'redux';

/* *********************** */
/* ***** Imports End ***** */
/* *********************** */

export default combineReducers({
  loader,
  vcAuth,
  vcUser,
  vcProptechs,
  vcForYou,
  vcProptechDetails,
  proptechAuth,
  proptechUser,
  snackbar,
  vsUser,
  vsDashboard,
  adminUser,
  adminDropdowns,
  adminAnalysisTab,
  newsfeeds,
  vcCompanyProfile,
  adminInvestor,
  adminVentureScout,
  adminContacts,
  admin
});
