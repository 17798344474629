import React from 'react'
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

export default function PTInput(props) {
    //  **** props ****
    const {
        variant,
        className,
        textColor,
        label,
        select,
        selectChildren,
        disabled,
        handleOnChangeEvent,
        value,
        fullWidth,
        placeholder,
        name,
        type,
        onBlur,
        id,
        helperText,
        error,
        labelStyle,
        shrink,
        icon,
        maxLength,
        multiline,
        maxRows,
        InputPropsStyle,
        onKeyPress
    } = props


    return (
        <TextField
            id={id}
            variant={variant}
            className={className ? className : null}
            style={{ textTransform: 'none', color: textColor }}
            disabled={disabled}
            label={label}
            value={value}
            name={name}
            select={select}
            placeholder={placeholder}
            fullWidth={fullWidth}
            onChange={handleOnChangeEvent ? handleOnChangeEvent : null}
            type={type ? type : "text"}
            onBlur={onBlur ? onBlur : null}
            error={error}
            helperText={helperText}  // error message
            InputLabelProps={{
                shrink: shrink,
                style: labelStyle
            }}
            multiline={multiline}
            maxRows={maxRows}
            inputProps={{
                maxLength: maxLength
            }}
            InputProps={{
                endAdornment: icon,
                style: InputPropsStyle
            }}
            onKeyPress={onKeyPress}
        >
            {select && selectChildren}
        </TextField >
    )
}

// Default PROPS
PTInput.defaultProps = {
    variant: 'outlined',
    label: 'Text',
    disabled: false,
    placeholder: '',
    value: '',
    fullWidth: false,
}

// Expected PROPS
PTInput.prototypes = {
    variant: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string
}