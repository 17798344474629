import React, { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Modal } from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import PTButton from '../../../../components/GenericComponents/PTButton';
import eyeIcon from '../../../../assets/Icons/ic-eye.svg';
import calenderIcon from '../../../../assets/Icons/ic-calender.svg';
import mapIcon from '../../../../assets/Icons/ic-map.svg';
import trendIcon from '../../../../assets/Icons/ic-trend.svg';
import paymentIcon from '../../../../assets/Icons/ic-payment.svg';
import communicationIcon from '../../../../assets/Icons/ic-communication.svg';
import shareIcon from '../../../../assets/Icons/ic-share.svg';
import starIcon from '../../../../assets/Icons/ic-star.svg';
import starIconActive from '../../../../assets/Icons/StarIconActive.svg';
import scheduleIcon from '../../../../assets/Icons/ic-schedule.svg';
import closeIcon from '../../../../assets/Icons/ic-cross.svg';
import { setWatchlistStatus } from '../../../utils/middleware/Dashboard';
import Loader from '../../../../components/Loading';
import PTInput from '../../../../components/GenericComponents/PTInput';
import { updateNote } from '../../../utils/middleware/Dashboard';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import ShareModal from '../../ShareModal';
import { getActiveUsers } from '../../../utils/middleware/Dropdowns';
import { VC_SCHEDULEMEETING_API } from '../../../../constants/APIs';
import { CALENDLY_API, VC_API } from '../../../../utils/utility/API-call';
import { handleError, handleSuccess, showMessage } from '../../../../utils/utility/snackbar';
import { Actions } from '../../../../store/actions';
import { dispatch } from '../../../../utils/utility/store';
import { MessageType } from '../../../../constants/General';
import { TooltipText } from '../../../../constants/General';

export default function TopBlock() {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  // Store
  const { proptechDetails } = useSelector(state => state.vcProptechDetails);
  const { isLoading } = useSelector(state => state.vcProptechs)
  const vcProptechs = useSelector(state => state.vcProptechs);

  // State
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [openCalendly, setOpenCalendly] = useState(false);
  const [noteData, setNoteData] = useState("");
  const [openShareModal, setOpenShareModal] = useState(false);
  const [shareMessage, setShareMessage] = useState("");
  const [activeUsers, setActiveUsers] = useState([])
  const handleNoteCloseModal = () => setOpenNoteModal(false);

  const setLoading = (isLoading) => {
    dispatch(Actions.VCProptechs.VCSetLoading, isLoading);
  }

  const handleCalendlyModal = () => {
    if (!proptechDetails?.calendlyURL) {
      showMessage('Calendly URL not found, please contact admin.', MessageType.Error);
      return;
    }
    setOpenCalendly(true);
  }

  // handle calenly schedule event 
  useCalendlyEventListener({
    onEventScheduled: (e) => {
      Promise.all([
        CALENDLY_API().get(e.data.payload.invitee.uri),
        CALENDLY_API().get(e.data.payload.event.uri)
      ]).then(([inviteeRes, eventRes]) => {
        setLoading(true);
        const event = {
          proptechCompanyDetailsId: proptechDetails.id,
          startTime: moment(eventRes.data.resource.start_time).utc().format(),
          duration: moment(eventRes.data.resource.end_time).diff(moment(eventRes.data.resource.start_time), 'minutes'),
          endTime: moment(eventRes.data.resource.end_time).utc().format(),
          topic: eventRes.data.resource.name,
          scheduledLink: inviteeRes.data.resource.uri,
          rescheduleLink: inviteeRes.data.resource.reschedule_url,
          cancelLink: inviteeRes.data.resource.cancel_url,
          calendlyResponse: {
            ...eventRes.data.resource,
            ...inviteeRes.data.resource
          }
        }
        VC_API().post(VC_SCHEDULEMEETING_API, event).then((res) => {
          handleSuccess(res);
        }).catch((err) => {
          handleError(err);
        }).finally(() => {
          setOpenCalendly(false);
          setLoading(false);
        })
      }).catch((err) => {
        handleError(err);
        setLoading(false);
      })
    },
  });


  const handleShareCloseModal = () => {
    setOpenShareModal(false)
    setShareMessage('')
  };

  // Set note data
  useEffect(() => {
    if (proptechDetails && proptechDetails?.vcProptechNote.length) {
      setNoteData(proptechDetails?.vcProptechNote[0]?.note)
    }
  }, [proptechDetails])

  const handleNoteSumit = () => {
    updateNote(proptechDetails?.id, noteData, 'proptechDetails', vcProptechs).then(() => {
      setOpenNoteModal(false)
    })
  }

  const handleNoteModal = () => {
    setOpenNoteModal(true)
  }

  const handleShareModal = async () => {
    setOpenShareModal(true)
    if (vcProptechs.vcActiveUsers.length) {
      setActiveUsers(vcProptechs.vcActiveUsers)
    } else {
      getActiveUsers().then((res) => {
        setActiveUsers(res)
      })
    }
  }


  return (
    <div className="top-menu">
      {isLoading ? <Loader /> : null}
      <div className="relative">
        <div className="icon-wrapper">
          {/* Note Modal Button */}
          <Tooltip title={TooltipText.comment} arrow >
            <IconButton onClick={handleNoteModal}>
              <img src={communicationIcon} alt="Icon" />
            </IconButton>
          </Tooltip>
          {/* Share Modal Button */}
          <Tooltip title={TooltipText.share} arrow >
            <IconButton onClick={handleShareModal}>
              <img src={shareIcon} alt="Icon" />
            </IconButton>
          </Tooltip>
          {/* Set Watchlist Button */}
          <Tooltip title={TooltipText.watchlist} arrow >
            <IconButton
              onClick={() => setWatchlistStatus(proptechDetails?.id, 'proptechDetails', !!proptechDetails?.vcProptechFavorite.length, vcProptechs)}
            >
              <img src={proptechDetails?.vcProptechFavorite.length ? starIconActive : starIcon} alt="Icon" />
            </IconButton>
          </Tooltip>
          {/* Schedule meeting Button */}
          <PTButton
            label='Schedule a meeting'
            iconBtn={scheduleIcon}
            className="normal-case fill-orange btn"
            size='large'
            id="schedule-button"
            handleOnClickEvent={() => handleCalendlyModal()}
          />
        </div>
        <div className="left_col">
          {/* Proptech details wrapper */}
          <div className="nav-top flex">
            <i className="image-wrapper">
              {proptechDetails?.companyLogo ?
                <img src={proptechDetails?.companyLogo} alt="Logo" />
                : <HideImageOutlinedIcon />
              }
            </i>
            <div className="card-info">
              <h2>{proptechDetails?.proptechCompany?.name}</h2>
              <ul className="listing-item">
                <li>
                  <img src={eyeIcon} alt="Icon" />
                  {proptechDetails?.watchingCount} watching
                </li>
                <li>
                  <img src={calenderIcon} alt="Icon" />
                  {proptechDetails?.publishedAt ? <>Listed {moment.tz(proptechDetails?.publishedAt, "YYYYMMDD", "Australia/Sydney").fromNow()}</> : `Not listed`}
                </li>
              </ul>
              <ul className="listing-item badge-item">
                <Tooltip title={TooltipText.companyRegion} arrow >
                  <li>
                    <img src={mapIcon} alt="Icon" />
                    {proptechDetails?.region?.name}
                  </li>
                </Tooltip>
                <Tooltip title={TooltipText.revenueRange} arrow >
                  <li>
                    <img src={trendIcon} alt="Icon" />
                    {proptechDetails?.revenueRange?.name}
                  </li>
                </Tooltip>
                <Tooltip title={TooltipText.investmentRound} arrow >
                  <li>
                    <img src={paymentIcon} alt="Icon" />
                    {proptechDetails?.investmentRound?.name}
                  </li>
                </Tooltip>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Calendly Modal */}
      <PopupModal
        url={proptechDetails?.calendlyURL || ""}
        rootElement={document.getElementById("schedule-button")}
        open={openCalendly}
        onModalClose={() => setOpenCalendly(false)}
      />
      <Modal
        open={openNoteModal}
        onClose={handleNoteCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="meeting-cancel-modal modal"
      >
        <>
          <Box sx={style} >
            <IconButton className="close-btn" onClick={() => handleNoteCloseModal()}>
              <img src={closeIcon} alt="Icon" />
            </IconButton>
            <div className="modal-outer relative">
              <div className="modal-inner">
                <h2>Add a note</h2>
              </div>
              <form>
                <div className="form-wrapper">
                  <div className="form-group">
                    <PTInput
                      id="note"
                      name="note"
                      className='form-control textarea-input'
                      multiline="multiline"
                      placeholder="Enter a note for later"
                      label="Enter a note for later"
                      fullWidth
                      value={noteData}
                      handleOnChangeEvent={(e) => setNoteData(e.target.value)}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="btn-wrapper">
              <PTButton label='Cancel' className="fill-gray btn"
                onClick={() => setOpenNoteModal(false)}
              />
              <PTButton label='Save' handleOnClickEvent={() => handleNoteSumit()} className="fill-orange btn" />
            </div>
          </Box>
        </>
      </Modal>
      {/* Share Modal */}
      <ShareModal
        shareMessage={shareMessage}
        openShareModal={openShareModal}
        handleShareCloseModal={() => handleShareCloseModal()}
        activeUsers={activeUsers}
        proptechId={proptechDetails?.id}
      />
    </div>)
}
