import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import PTButton from '../../../../components/GenericComponents/PTButton';
import PTInput from '../../../../components/GenericComponents/PTInput';
import Loader from '../../../../components/Loading';
import { AdminQuestions1, AdminQuestions2 } from '../../../../proptech/constants/General';
import { Actions } from '../../../../store/actions';
import { updateProptechsAnswers } from '../../../../utils/middleware/_admin/Proptechs';
import ConfirmModal from '../../../components/ConfirmModal';
import { ANSWERS_TAB_ERRORS, ANSWERS_TAB_PAYLOAD } from '../../../utils/payloads/payloads';
import { DateFormat } from '../../../../constants/General';
import { formatTime, trimQuery } from '../../../../utils/utility/utility';
import { isUnsaveChanges } from '../../../utils/middleware/analysisTab';
import { dispatch } from '../../../../utils/utility/store';
import './AnswerBlock.scss';

export default function AnswerBlock(props) {
  const { setUnsaveChange } = props

  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState(ANSWERS_TAB_ERRORS);
  const [editIndex, setEditIndex] = useState();
  const [adminAnswers, setAdminAnswers] = useState(ANSWERS_TAB_PAYLOAD);
  const [openModal, setOpenModal] = useState(false)

  // Store
  const isLoading = useSelector(state => state.adminUser.isLoading)
  const selectedProptech = useSelector(state => state.adminUser.selectedProptech)
  const { activeSort, sortOrder, isArchived, search } = useSelector(state => state.adminUser);

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const checkValidation = () => {
    let data = errors
    setAdminAnswers(trimQuery(adminAnswers))
    Object.keys(data).forEach(error => {
      data = {
        ...data, [error]: adminAnswers?.[error] !== null ?
          error === 'futureGoalsAdmin' ? adminAnswers?.[error]?.length < 50 : adminAnswers?.[error]?.length < 100
          : error === 'futureGoalsAdmin' ? selectedProptech?.[error]?.trim()?.length < 50 : selectedProptech?.[error]?.trim()?.length < 100
      }
    })
    setErrors(data)
    return Object.keys(data).some(k => data[k])
  }

  const updateAnswers = async () => {
    const newData = {};
    Object.entries(adminAnswers)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => (newData[key] = selectedProptech?.[key]));

    try {
      updateProptechsAnswers(
        {
          ...Object.fromEntries(Object.entries(adminAnswers).filter(([_, v]) => v !== null)),
          ...Object.fromEntries(Object.entries(newData).filter(([_, v]) => v !== null)),
        }, selectedProptech?.id, selectedProptech, isArchived, activeSort, sortOrder, search)
    } catch {
      dispatch(Actions.AdminUser.SetLoading, false)
      console.log('error')
    }
  }

  const handleAnswerEdit = (index) => {
    setEdit(!edit);
    setEditIndex(index);
  }

  useEffect(() => {
    isUnsaveChanges(adminAnswers, setUnsaveChange)
  }, [adminAnswers]);  // eslint-disable-line react-hooks/exhaustive-deps

  console.log(adminAnswers, selectedProptech?.technologyProductAdmin)
  return (
    <div className='answer-block'>
      {isLoading ? <Loader /> : null}
      {adminAnswers ?
        <>
          <div className='flex justify-between heading-block items-end'>
            <h3>Company Overview 1</h3>
            {selectedProptech?.publishedAt ?
              <span className='uppercase text-xs font-normal'>
                Last Published
                <span className='font-semibold'>
                  {' '}{formatTime(selectedProptech?.publishedAt, DateFormat.DayMonthFormat3)} GMT
                </span>
              </span>
              : null}
          </div>
          {Object.keys(AdminQuestions1).map((snapshot, index) => {
            return (
              <div key={index} className='flex justify-between content-wrapper'>
                <div className='left-block'>
                  <p className='text-sm'>{AdminQuestions1[snapshot]}</p>
                </div>
                <div className='right-block'>
                  {
                    editIndex === index && edit ?
                      <PTInput
                        className='text-xs'
                        maxRows={4}
                        multiline
                        value={adminAnswers?.[snapshot] !== null ? adminAnswers?.[snapshot] : selectedProptech?.[snapshot]}
                        handleOnChangeEvent={(e) => setAdminAnswers({ ...adminAnswers, [snapshot]: e.target.value })}
                        fullWidth
                        maxLength={index ? 400 : 250}
                        label=''
                        InputPropsStyle={{ fontSize: 12, padding: 7 }}
                      />
                      :
                      <p className='text-xs break-all'>{adminAnswers[snapshot] || selectedProptech[snapshot]}</p>
                  }
                  <div className='flex justify-between mt-4'>
                    <p className='text-xs font-semibold uppercase cursor-pointer'>
                      <span
                        onClick={() => handleAnswerEdit(index)}
                      >
                        {editIndex === index && edit ? `Done` : `Edit`}
                      </span>
                      <span
                        className='text-xs ml-4'
                        style={{ color: 'rgba(17, 29, 67, 0.4)' }}
                        onClick={() => {
                          handleAnswerEdit(index)
                          setAdminAnswers({ ...adminAnswers, [snapshot]: selectedProptech[snapshot] })
                        }}
                      >
                        {editIndex === index && edit ? `Cancel` : null}
                      </span>
                    </p>
                    <p className='text-xs font-semibold uppercase' style={{ color: 'rgba(17, 29, 67, 0.4)' }}>{adminAnswers?.[snapshot]?.length || selectedProptech?.[snapshot]?.length}/<span>{index ? '400' : '250'}</span> CHARACTERS</p>
                  </div>
                  <span className='font-normal text-xs mx-0 mb-0 mt-2 text-left tracking-wide text-[#d32f2f]'>{errors?.[snapshot] && `Must be ${snapshot === 'futureGoalsAdmin' ? '50' : '100'} characters or more.`}</span>
                </div>
              </div>
            )
          })
          }
          <div className='flex justify-between heading-block items-end'>
            <h3>Company Overview 2</h3>
          </div>
          {Object.keys(AdminQuestions2).map((snapshot, index) => {
            return (
              <div key={index} className='flex justify-between content-wrapper'>
                <div className='left-block'>
                  <p className='text-sm'>{AdminQuestions2[snapshot]}</p>
                </div>
                <div className='right-block'>
                  {
                    editIndex === index && edit ?
                      <PTInput
                        className='text-xs'
                        maxRows={4}
                        multiline
                        value={adminAnswers?.[snapshot] !== null ? adminAnswers?.[snapshot] : selectedProptech?.[snapshot]}
                        handleOnChangeEvent={(e) => setAdminAnswers({ ...adminAnswers, [snapshot]: e.target.value })}
                        fullWidth
                        maxLength={400}
                        label=''
                        InputPropsStyle={{ fontSize: 12, padding: 7 }}
                      />
                      :
                      <p className='text-xs break-all'>{adminAnswers[snapshot] || selectedProptech[snapshot]}</p>
                  }
                  <div className='flex justify-between mt-4'>
                    <p className='text-xs font-semibold uppercase cursor-pointer'>
                      <span
                        onClick={() => handleAnswerEdit(index)}
                      >
                        {editIndex === index && edit ? `Done` : `Edit`}
                      </span>
                      <span
                        className='text-xs ml-4'
                        style={{ color: 'rgba(17, 29, 67, 0.4)' }}
                        onClick={() => {
                          handleAnswerEdit(index)
                          setAdminAnswers({ ...adminAnswers, [snapshot]: selectedProptech[snapshot] })
                        }}
                      >
                        {editIndex === index && edit ? `Cancel` : null}
                      </span>
                    </p>
                    <p className='text-xs font-semibold' style={{ color: 'rgba(17, 29, 67, 0.4)' }}>{adminAnswers?.[snapshot]?.length || selectedProptech?.[snapshot]?.length}/<span>400</span> CHARACTERS</p>
                  </div>
                  <span className='font-normal text-xs mx-0 mb-0 mt-2 text-left tracking-wide text-[#d32f2f]'>{errors?.[snapshot] && "Must be 100 characters or more."}</span>
                </div>
              </div>
            )
          })
          }
          <div className='w-full flex justify-end bottom-btn-wrapper'>
            <PTButton variant="contained"
              label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Revert</span>} type="submit"
              className='w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]'
              handleOnClickEvent={() => setAdminAnswers(ANSWERS_TAB_PAYLOAD)}
            />
            <PTButton
              label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Save & Continue</span>}
              className='w-[142px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]'
              handleOnClickEvent={() => !checkValidation() && handleOpenModal()}
            />
          </div>
        </>
        : null
      }

      {openModal && <ConfirmModal open={openModal} handleCloseModal={handleCloseModal} handleSucces={updateAnswers} />}

    </div >
  )
}
