import { useNavigate } from "react-router-dom";
import PTButton from "../../components/GenericComponents/PTButton";
import LeftLogo from '../../components/LeftLogo';

function Landing() {
  const navigate = useNavigate();

  return (
    <div className="w-screen flex proptech-layout-wrapper flex-wrap">
      <div className="w-full md:w-3/5 ">
        <LeftLogo />
      </div>
      <div className="md:min-h-screen w-full md:w-2/5 items-center inline-flex relative">
        <div className="wrapper-container">
          <div className="flex flex-col">
            <PTButton label='Are you a VC (Investor)?' className="my-5 normal-case fill-orange btn " size='large' handleOnClickEvent={() => navigate('/investor')} />
            <PTButton label='Are you a Proptech?' className="normal-case btn" size='large' color='primary' handleOnClickEvent={() => navigate('/proptech')} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
