import { PROPTECH_COMPANY_DETAILS_API } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { Store } from "../../../store/configureStore";
import { PROPTECH_API } from "../../utility/API-call";
import { startLoading, stopLoading } from "../../utility/loader";
import { handleError, handleSuccess } from "../../utility/snackbar";

export const sendHeading1Data = (data, companyDetails, setActiveStep, resetForm) => {
  console.log(companyDetails)
  startLoading();
  PROPTECH_API().post(PROPTECH_COMPANY_DETAILS_API, { heading1: data, signupProgress: 2 })
    .then((res) => {
      if (res.data.data && res.data.code !== 500) {
        Store.dispatch({
          type: Actions.ProptechUser.ProptechSetCompanyDetails, payload: {
            ...companyDetails,
            ...data,
            signupProgress: companyDetails?.signupProgress > 2 ? companyDetails?.signupProgress : 2
          }
        });
        resetForm({ values: '' })
        setActiveStep(2)
      }
      stopLoading();
      handleSuccess(res)
    })
    .catch((error) => {
      stopLoading();
      handleError(error);
    })
}
