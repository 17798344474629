import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PTButton from '../../../../components/GenericComponents/PTButton';
import Ratings from '../../../../components/GenericComponents/Ratings';
import DropdownColumn from '../../DropdownColumn';
import { updateOverviewDetails } from '../../../utils/middleware/overviewTab';
import ConfirmModal from '../../ConfirmModal';
import Loader from '../../../../components/Loading';
import Chart from '../../Chart';
import { OVERVIEW_TAB_ERRORS, OVERVIEW_TAB_PAYLOAD } from '../../../utils/payloads/payloads';
import { dispatch } from '../../../../utils/utility/store';
import { Actions } from '../../../../store/actions';
import { trimQuery } from '../../../../utils/utility/utility';
import { RATING_CHART } from '../../../constants';
import { isUnsaveChanges } from '../../../utils/middleware/analysisTab';

export default function OverviewTab(props) {
  const { setUnsaveChange } = props

  // Store
  const overviewTabData = useSelector(state => state.adminAnalysisTab.overviewTabData)
  const selectedProptech = useSelector(state => state.adminUser.selectedProptech)
  const isLoading = useSelector(state => state.adminUser.isLoading)
  const { sectors, useCases, techOfferings } = useSelector(state => state.adminDropdowns)

  // State
  const [editedDetails, setEditedDetails] = useState(OVERVIEW_TAB_PAYLOAD);
  const [errors, setErrors] = useState(OVERVIEW_TAB_ERRORS);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails))
    Object.keys(data).forEach(error => {
      data = {
        ...data, [error]: editedDetails?.[error] !== null ? !editedDetails?.[error] : !overviewTabData?.[error]
      }
    })
    setErrors(data)
    return Object.keys(data).some(k => data[k])
  }

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => (newData[key] = overviewTabData?.[key]));

    handleCloseModal()
    try {
      await updateOverviewDetails(
        selectedProptech?.id,
        {
          ...Object.fromEntries(Object.entries(editedDetails).filter(([_, v]) => v !== null)),
          ...Object.fromEntries(Object.entries(newData).filter(([_, v]) => v !== null)),
        })
    } catch {
      dispatch(Actions.AdminUser.SetLoading, false)
      console.log('error')
    }
  }

  const totalStartCount = () => {
    return (editedDetails?.userExperience !== null ? editedDetails?.userExperience : overviewTabData?.userExperience) +
      (editedDetails?.dealClosing !== null ? editedDetails?.dealClosing : overviewTabData?.dealClosing) +
      (editedDetails?.evolvingAssets !== null ? editedDetails?.evolvingAssets : overviewTabData?.evolvingAssets) +
      (editedDetails?.decisionMaking !== null ? editedDetails?.decisionMaking : overviewTabData?.decisionMaking) +
      (editedDetails?.smarterBuilds !== null ? editedDetails?.smarterBuilds : overviewTabData?.smarterBuilds) +
      (editedDetails?.connectivity !== null ? editedDetails?.connectivity : overviewTabData?.connectivity) || 0
  }

  useEffect(() => {
    isUnsaveChanges(editedDetails, setUnsaveChange)
  }, [editedDetails]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading && <Loader />}
      <div className='flex flex-col justify-between'>
        <div className='analysis-rating-chart-block flex'>
          <div className='rating-block'>
            <Ratings className='flex justify-between rating-items' label="User experience" value={editedDetails?.userExperience !== null ? editedDetails?.userExperience : overviewTabData?.userExperience} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, userExperience: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, userExperience: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Deal closing" value={editedDetails?.dealClosing !== null ? editedDetails?.dealClosing : overviewTabData?.dealClosing} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, dealClosing: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, dealClosing: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Evolving assets" value={editedDetails?.evolvingAssets !== null ? editedDetails?.evolvingAssets : overviewTabData?.evolvingAssets} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, evolvingAssets: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, evolvingAssets: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Decision making" value={editedDetails?.decisionMaking !== null ? editedDetails?.decisionMaking : overviewTabData?.decisionMaking} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, decisionMaking: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, decisionMaking: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Smarter builds" value={editedDetails?.smarterBuilds !== null ? editedDetails?.smarterBuilds : overviewTabData?.smarterBuilds} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, smarterBuilds: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, smarterBuilds: 0 })} />
            <Ratings className='flex justify-between last-rating-items' label="Connectivity" value={editedDetails?.connectivity !== null ? editedDetails?.connectivity : overviewTabData?.connectivity} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, connectivity: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, connectivity: 0 })}
              total={<p className={`text-xs text-right font-semibold uppercase ${totalStartCount() >= RATING_CHART.limitForError ? 'text-error' : 'text-light-grey'}`}>
                {totalStartCount()}/<span>{RATING_CHART.maxLimit}</span></p>} />
          </div>
          <div className='chart-block'>
            <div className='box-border bg-[#F3F3F5]'>
              <Chart labels={["User experience", "Deal closing", ["Evolving", "assets"], "Decision making", ["Smarter", "builds"], "Connectivity"]} items={[
                editedDetails?.userExperience !== null ? editedDetails?.userExperience : overviewTabData?.userExperience,
                editedDetails?.dealClosing !== null ? editedDetails?.dealClosing : overviewTabData?.dealClosing,
                editedDetails?.evolvingAssets !== null ? editedDetails?.evolvingAssets : overviewTabData?.evolvingAssets,
                editedDetails?.decisionMaking !== null ? editedDetails?.decisionMaking : overviewTabData?.decisionMaking,
                editedDetails?.smarterBuilds !== null ? editedDetails?.smarterBuilds : overviewTabData?.smarterBuilds,
                editedDetails?.connectivity !== null ? editedDetails?.connectivity : overviewTabData?.connectivity
              ]}
                step={2}
                maximumValue={10} />
            </div>
          </div>
        </div>
        <div className='flex justify-between analysis-dropdown-wrapper'>
          <DropdownColumn
            header="Sectors"
            dropdown1Label="Sector 1"
            dropdown1Options={sectors}
            dropdown1Value={editedDetails?.sector1 || overviewTabData?.sector1}
            dropdown1HandleChange={(e) => setEditedDetails({ ...editedDetails, sector1: e.target.value })}
            dropdown1Error={errors.sector1}
            dropdown1HelperText={errors.sector1 && "Sector 1 Required."}
            dropdown2Label="Sector 2"
            dropdown2Options={sectors}
            dropdown2Value={editedDetails?.sector2 || overviewTabData?.sector2}
            dropdown2HandleChange={(e) => setEditedDetails({ ...editedDetails, sector2: e.target.value })}
            dropdown2Error={errors.sector2}
            dropdown2HelperText={errors.sector2 && "Sector 2 Required."}
            dropdown3Label="Sector 3"
            dropdown3Options={sectors}
            dropdown3Value={editedDetails?.sector3 || overviewTabData?.sector3}
            dropdown3HandleChange={(e) => setEditedDetails({ ...editedDetails, sector3: e.target.value })}
            dropdown3Error={errors.sector3}
            dropdown3HelperText={errors.sector3 && "Sector 3 Required."}
          />
          <DropdownColumn
            header="Use cases"
            dropdown1Label="Use Case 1"
            dropdown1Options={useCases}
            dropdown1Value={editedDetails?.useCase1 || overviewTabData?.useCase1}
            dropdown1HandleChange={(e) => setEditedDetails({ ...editedDetails, useCase1: e.target.value })}
            dropdown1Error={errors.useCase1}
            dropdown1HelperText={errors.useCase1 && "Use Case 1 Required."}
            dropdown2Label="Use Case 2"
            dropdown2Options={useCases}
            dropdown2Value={editedDetails?.useCase2 || overviewTabData?.useCase2}
            dropdown2HandleChange={(e) => setEditedDetails({ ...editedDetails, useCase2: e.target.value })}
            dropdown2Error={errors.useCase2}
            dropdown2HelperText={errors.useCase2 && "Use Case 2 Required."}
            dropdown3Label="Use Case 3"
            dropdown3Options={useCases}
            dropdown3Value={editedDetails?.useCase3 || overviewTabData?.useCase3}
            dropdown3HandleChange={(e) => setEditedDetails({ ...editedDetails, useCase3: e.target.value })}
            dropdown3Error={errors.useCase3}
            dropdown3HelperText={errors.useCase3 && "Use Case 3 Required."}
          />
          <DropdownColumn
            header="Offerings"
            dropdown1Label="Offering 1"
            dropdown1Options={techOfferings}
            dropdown1Value={editedDetails?.techOffering1 || overviewTabData?.techOffering1}
            dropdown1HandleChange={(e) => setEditedDetails({ ...editedDetails, techOffering1: e.target.value })}
            dropdown1Error={errors.techOffering1}
            dropdown1HelperText={errors.techOffering1 && "Offering 1 Required."}
            dropdown2Label="Offering 2"
            dropdown2Options={techOfferings}
            dropdown2Value={editedDetails?.techOffering2 || overviewTabData?.techOffering2}
            dropdown2HandleChange={(e) => setEditedDetails({ ...editedDetails, techOffering2: e.target.value })}
            dropdown2Error={errors.techOffering2}
            dropdown2HelperText={errors.techOffering2 && "Offering 2 Required."}
            dropdown3Label="Offering 3"
            dropdown3Options={techOfferings}
            dropdown3Value={editedDetails?.techOffering3 || overviewTabData?.techOffering3}
            dropdown3HandleChange={(e) => setEditedDetails({ ...editedDetails, techOffering3: e.target.value })}
            dropdown3Error={errors.techOffering3}
            dropdown3HelperText={errors.techOffering3 && "Offering 3 Required."}
          />
        </div>
        <div className='flex justify-end bottom-btn-wrapper'>
          <PTButton variant="contained"
            label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Revert</span>}
            type="submit" className='w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]'
            handleOnClickEvent={() => setEditedDetails(OVERVIEW_TAB_PAYLOAD)} />
          <PTButton variant="contained"
            label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Save & Continue</span>} type="submit"
            className='w-[142px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]'
            handleOnClickEvent={() => !checkValidation() && handleOpenModal()} />
        </div>

        {openModal && <ConfirmModal open={openModal} handleCloseModal={handleCloseModal} handleSucces={updateData} />}
      </div>
    </>
  )
}
