export function raiseTabDTO(data) {
  return {
    id: data?.id || 0,
    defensibility: data?.defensibility || 0,
    model: data?.model || 0,
    network: data?.network || 0,
    technology: data?.technology || 0,
    team: data?.team || 0,
    timing: data?.timing || 0,
    proptechCompanyDetailsId: data?.proptechCompanyDetailsId || 0,
    proptechAnalysisAlignment1: {
      id: data?.proptechAnalysisAlignment1?.id || 0,
      name: data?.proptechAnalysisAlignment1?.name || '',
    },
    proptechAnalysisAlignment2: {
      id: data?.proptechAnalysisAlignment2?.id || 0,
      name: data?.proptechAnalysisAlignment2?.name || '',
    },
    proptechAnalysisAlignment3: {
      id: data?.proptechAnalysisAlignment3?.id || 0,
      name: data?.proptechAnalysisAlignment3?.name || '',
    },
    proptechCompanyDetails: {
      id: data?.proptechCompanyDetails?.id || 0,
      investmentRangeId: data?.proptechCompanyDetails?.investmentRangeId || null,
      investmentRoundId: data?.proptechCompanyDetails?.investmentRoundId || null,
      investmentTypeId: data?.proptechCompanyDetails?.investmentTypeId || null,
      noOfRoundsId: data?.proptechCompanyDetails?.noOfRoundsId || null,
      raiseDate: data?.proptechCompanyDetails?.raiseDate || null,
      valuationRangeId: data?.proptechCompanyDetails?.valuationRangeId || null,
      investabilityAdmin: data?.proptechCompanyDetails?.investabilityAdmin || null,
      investmentRange: {
        id: data?.proptechCompanyDetails?.investmentRange?.id || 0,
        name: data?.proptechCompanyDetails?.investmentRange?.name || '',
      },
      investmentRound: {
        id: data?.proptechCompanyDetails?.investmentRound?.id || 0,
        name: data?.proptechCompanyDetails?.investmentRound?.name || '',
      },
      investmentType: {
        id: data?.proptechCompanyDetails?.investmentType?.id || 0,
        name: data?.proptechCompanyDetails?.investmentType?.name || '',
      },
      noOfRound: {
        id: data?.proptechCompanyDetails?.noOfRound?.id || 0,
        name: data?.proptechCompanyDetails?.noOfRound?.name || '',
      },
      valuationRange: {
        id: data?.proptechCompanyDetails?.valuationRange?.id || 0,
        name: data?.proptechCompanyDetails?.valuationRange?.name || '',
      },
    },
    alignment1: data?.alignment1 || null,
    alignment2: data?.alignment2 || null,
    alignment3: data?.alignment3 || null,
  }
}