import { ADMIN_PROPTECHS, } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { dispatch } from "../../../utils/utility/store"
import { handleError, handleSuccess, } from "../../../utils/utility/snackbar"
import { marketTabDTO } from "../dtos/marketTab"
import { getChargingModelsData, getTechBuyersData, getValueKPIsData } from "../dropdowns"

export const loadMarketTabData = (id, adminAnalysisTab, adminDropdowns) => {
  if (!adminAnalysisTab.marketTabData) {
    getMarketDetails(id)
  }
  loadMarketTabDropdowns(adminDropdowns.techBuyers, adminDropdowns.chargingModels, adminDropdowns.valueKPIs);
}

export const getMarketDetails = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/market`)
    .then(res => {
      dispatch(Actions.AdminUser.SetLoading, false)
      dispatch(Actions.AdminAnalysisTab.SetMarketTabData, marketTabDTO(res.data.data))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err)
    })
}

export const updateMarketDetails = (id, data) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  return ADMIN_API().put(ADMIN_PROPTECHS + `/${id}/market`, data)
    .then(res => {
      handleSuccess(res)
      getMarketDetails(id)
      return res?.status;
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err);
      return err?.response?.status;
    })
}

export const loadMarketTabDropdowns = (techBuyers, chargingModels, valueKPIs) => {
  if (!techBuyers.length) {
    getTechBuyersData();
  }
  if (!chargingModels.length) {
    getChargingModelsData();
  }
  if (!valueKPIs.length) {
    getValueKPIsData();
  }
}