import React from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '../../../../utils/utility/store';
import { Actions } from '../../../../store/actions';
import Loader from '../../../../components/Loading'
import ProptechListCard from './ProptechListCard'
import { setActiveTab } from '../../../utils/middleware/adminInvestors';

// Get for you Component in Proptech Tab
export default function ForYouTab() {
  // Store
  const forYouProptechs = useSelector(state => state.adminUser.forYouProptechs)
  const isLoading = useSelector(state => state.adminUser.isLoading)
  const adminDropdowns = useSelector(state => state.adminDropdowns);
  const adminInvestor = useSelector(state => state.adminInvestor)

  const handleCardClick = (id) => {
    dispatch(Actions.Admin.SetActiveAdminTab, 1)
    dispatch(Actions.AdminInvestor.SetActiveTab, 0)
    dispatch(Actions.Admin.SetIsRedirect, true)
    dispatch(Actions.AdminInvestor.SetIsSelectedInvestor, true)
    dispatch(Actions.AdminInvestor.SetSelectedInvestor, { id })
    setActiveTab(0, id, adminInvestor, adminDropdowns)
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className='overflow-y-auto'>
        {forYouProptechs.map((proptech, index) => {
          return <ProptechListCard key={index} logo={proptech?.vcCompanyDetails?.companyLogo} onCardClick={() => handleCardClick(proptech?.vcCompanyDetails?.id)} title={proptech.vcCompanyDetails.vcCompany.name} updatedAt={proptech.updatedAt} tags={proptech?.vcProptechForYouTag} />
        })}
      </div>
    </>
  )
}
