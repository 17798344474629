import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { CircularProgress, IconButton, Tab, Tabs } from '@mui/material';
import { tabPanels } from '../../utils/tabPanels';
import VideoModal from '../../../components/VideoModal';
import videoIcon from "../../../assets/Icons/ic-video.svg";
import { FREE_MEMBERSHIP_MEETING_COUNT, INTRODUCTRY_VIDEO_URL } from '../../constants';
import '../../pages/Dashboard/dashboard.scss';
import "./navbar.scss";

export default function NavBar({ value, setValue }) {
  // Store
  const vcCountDetails = useSelector(state => state.vcUser.vcCountDetails);

  // State
  const [watchVideo, setWatchVideo] = React.useState(true);
  const [watchVideoModal, setWatchVideoModal] = React.useState(false);

  const handleCloseWatchVideo = () => setWatchVideo(false);
  const openVideoModal = () => setWatchVideoModal(true);
  const closeVideoModal = () => setWatchVideoModal(false);

  const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  // Handling tab change event
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className='h-full'>
      <div className="left-bottom-wrapper bg-[#111D43] h-full flex flex-col justify-between">
        <div className="left-menu">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
          >
            {tabPanels.map((tabPanel, index) => {
              return (
                <Tab key={index} label={
                  <>
                    <div className="w-full flex items-center pl-9 menu-item">
                      <svg {...tabPanel?.style}>
                        <path {...tabPanel?.path} />
                      </svg>
                      <div className="ml-4 flex items-center justify-center text-[#FFFFFF] font-semibold text-base leading-4 not-italic">{tabPanel?.panel}</div>
                    </div>
                  </>
                } {...a11yProps(index)} />
              )
            })}
          </Tabs>
        </div>
        {/*****Meeting Booked Section******/}
        {/* Need to check if user already gets free subscription or not */}
        {!watchVideo && !vcCountDetails?.isFreePlanClaimed && vcCountDetails?.completedMeetingCount >= 0 && vcCountDetails?.completedMeetingCount < FREE_MEMBERSHIP_MEETING_COUNT && <div className="rounded-lg m-4 p-6 flex flex-col content-start bg-[#ffffff0d]">
          <div className="progress-area">
            <CircularProgress variant="determinate" value={Math.floor((vcCountDetails.completedMeetingCount / FREE_MEMBERSHIP_MEETING_COUNT) * 100)} />
            <span className="data">{vcCountDetails.completedMeetingCount}</span>
          </div>
          <div className="mt-2">
            <div className="font-semibold text-sm leading-5 my-1 not-italic font-['Poppins'] text-[#FFFFFF]">Meetings booked</div>
            <div className="font-normal text-xs leading-4 my-1 not-italic font-['Poppins'] text-[#C9CDD4]">Book <span className="text-[#FF4100]">{FREE_MEMBERSHIP_MEETING_COUNT - vcCountDetails.completedMeetingCount} more meetings</span> and get a free platinum plan membership.</div>
            <div className="font-semibold text-xs leading-5 not-italic font-['Poppins'] text-[#FFFFFF] cursor-pointer inline-block">Find out more</div>
          </div>
        </div>}

        {watchVideo && <div className="rounded-lg m-4 p-6 flex flex-col content-start bg-[#ffffff0d] relative">
          <IconButton className="close-btn" onClick={handleCloseWatchVideo}>
            <CloseIcon className='text-white' color='#FFFFFF' />
          </IconButton>
          <img src={videoIcon} alt="Icon" className='w-10' />
          <div className="mt-2">
            <div className="font-semibold text-sm leading-5 my-1 not-italic font-['Poppins'] text-[#FFFFFF]">New to Connect?</div>
            <div className="font-normal text-xs leading-4 my-1 not-italic font-['Poppins'] text-[#C9CDD4]">Watch our introductory explainer video</div>
            <div
              className="font-semibold text-xs leading-5 not-italic font-['Poppins'] text-[#FFFFFF] cursor-pointer inline-block"
              onClick={openVideoModal}
            >
              Watch video
            </div>
          </div>
        </div>}
      </div>
      {watchVideoModal && <VideoModal 
        videoUrl={INTRODUCTRY_VIDEO_URL} 
        openVideoModal={watchVideoModal} 
        closeVideoModal={closeVideoModal} 
      />}
    </div>
  )
}
