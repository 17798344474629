import { loadCompanyProfileTabData } from "./companyProfileTab";
import { loadCompanyTabData } from "./companyTab";
import { loadDesignReviewTabData } from "./designReviewTab";
import { loadMarketInsightsTabData } from "./marketInsightsTab";
import { loadMarketTabData, } from "./marketTab";
import { loadNumbersTabData, } from "./numbersTab";
import { loadOverviewTabData, } from "./overviewTab";
import { loadRaiseOverviewTabData, } from "./raiseOverviewTab";
import { loadRaiseTabData, } from "./raiseTab";
import { loadTeamsTabData, } from "./teamTab";

export function setActiveTabData(tab, id, adminAnalysisTab, adminDropdowns) {
  switch (tab) {
    case 0: loadCompanyTabData(id, adminAnalysisTab, adminDropdowns);
      break;

    case 1: loadOverviewTabData(id, adminAnalysisTab, adminDropdowns);
      break;

    case 2: loadMarketTabData(id, adminAnalysisTab, adminDropdowns);
      break;

    case 3: loadNumbersTabData(id, adminAnalysisTab, adminDropdowns);
      break;

    case 4: loadRaiseTabData(id, adminAnalysisTab, adminDropdowns);
      break;

    case 5: loadTeamsTabData(id, adminAnalysisTab, adminDropdowns);
      break;

    case 6: loadRaiseOverviewTabData(id, adminAnalysisTab, adminDropdowns);
      break;

    case 7: loadDesignReviewTabData(id, adminAnalysisTab)
      break;

    case 8: loadMarketInsightsTabData(id, adminAnalysisTab, adminDropdowns);
      break;

    case 9: loadCompanyProfileTabData(id, adminAnalysisTab, adminDropdowns);
      break;

    default: loadCompanyTabData(id, adminAnalysisTab, adminDropdowns);
      break;
  }
}


export const isUnsaveChanges = (data, setUnsaveChange) => {
  let unsave = data && Object.entries(data)
    .filter(([, value]) => value !== null)?.length

  setUnsaveChange(!!unsave)
}