import { ADMIN_INVESTORS } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { handleError, handleSuccess } from "../../../utils/utility/snackbar"
import { dispatch } from "../../../utils/utility/store"
import { INVESTOR_TAB_FILTERS } from "../../constants"
import { investorInteractionProptechsListDTO, investorListDTO, investorWatchlistProptechsListDTO } from "../dtos/adminInvestor"
import { loadInvestorForYouData } from "./investorForYouTab"
import { loadInvestorProfileTabData } from "./investorProfileTab"

export const getInvestors = (isArchived, activeSort, sortOrder, search, clear = true) => {
  const params = {
    filter: { "isArchived": isArchived },
    ...(!!search ? { search } : {}),
    ...(!!sortOrder ? { 'order': sortOrder } : {}),
    ...(!!activeSort ? { 'orderBy': activeSort } : {}),
  }
  dispatch(Actions.AdminInvestor.SetLoading, true)
  ADMIN_API().get(ADMIN_INVESTORS, { params })
    .then(res => {
      handleSuccess(res)
      let investors = investorListDTO(res?.data?.data?.rows)
      dispatch(Actions.AdminInvestor.InvestorListSet, investors)
      if (clear) {
        dispatch(Actions.AdminInvestor.SetSelectedInvestor, null)
        dispatch(Actions.AdminInvestor.SetIsSelectedInvestor, false)
      }
    }).catch(err => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      handleError(err)
    })
}

export const updateArchiveStatus = (id, status, isArchived, activeSort, sortOrder, search, setOpenModal) => {
  ADMIN_API().put(`${ADMIN_INVESTORS}/${id}/archive`, status)
    .then(res => {
      handleSuccess(res);
      getInvestors(isArchived, activeSort, sortOrder, search);

      setOpenModal(false)
    }).catch(err => {
      handleError(err)
      setOpenModal(false)
    });
}

export const updateActivetatus = (id, status, data, isArchived, activeSort, sortOrder, search, setOpenModal2) => {
  dispatch(Actions.AdminInvestor.SetLoading, true)
  ADMIN_API().put(`${ADMIN_INVESTORS}/${id}/status`, status)
    .then(res => {
      handleSuccess(res);
      getInvestors(isArchived, activeSort, sortOrder, search);
      dispatch(Actions.AdminInvestor.SetSelectedInvestor, { ...data, ...status })
      setOpenModal2(false)
    })
    .catch(err => {
      handleError(err)
      setOpenModal2(false)
    });
}

export const getInteractionsProptech = (id, status) => {
  dispatch(Actions.AdminInvestor.SetLoading, true)

  const params = {
    ...(!!status ? { status } : {}),
  }
  ADMIN_API().get(`${ADMIN_INVESTORS}/${id}/meetingInteractions`, { params })
    .then(res => {
      handleSuccess(res);
      dispatch(Actions.AdminInvestor.SetLoading, false)
      dispatch(Actions.AdminInvestor.SetInterationsProptechs, investorInteractionProptechsListDTO(res.data.data.rows))
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.AdminInvestor.SetInterationsProptechs, [])
      dispatch(Actions.AdminInvestor.SetLoading, false)
    });
}

export const deleteInvestor = (id, isArchived, activeSort, sortOrder, search, handleClose) => {
  dispatch(Actions.AdminInvestor.SetLoading, true)
  ADMIN_API().delete(`${ADMIN_INVESTORS}/permanently-delete/${id}`)
    .then(res => {
      handleSuccess(res);
      getInvestors(isArchived, activeSort, sortOrder, search);
      handleClose(false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.AdminInvestor.SetLoading, false)
    });
}

export const getWatchlistProptech = (id) => {
  dispatch(Actions.AdminInvestor.SetLoading, true)
  ADMIN_API().get(`${ADMIN_INVESTORS}/${id}/watchList`)
    .then(res => {
      handleSuccess(res);
      dispatch(Actions.AdminInvestor.SetLoading, false)
      dispatch(Actions.AdminInvestor.SetWatchlistProptechsData, investorWatchlistProptechsListDTO(res.data.data.rows))
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.AdminInvestor.SetLoading, false)
    });
}

export const setActiveTab = (tab, id, adminInvestor, adminDropdowns) => {
  switch (tab) {
    case 0: id && loadInvestorProfileTabData(id, adminInvestor, adminDropdowns)
      break;

    case 1: id && getInteractionsProptech(id, INVESTOR_TAB_FILTERS.total)
      break;

    case 2: id && loadInvestorForYouData(id, adminInvestor, adminDropdowns)
      break;

    case 3: id && getWatchlistProptech(id, adminInvestor, adminDropdowns)
      break;

    default:
      break;
  }
}