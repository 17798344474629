import { ADMIN_PROPTECHS, } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { dispatch } from "../../../utils/utility/store"
import { handleError, handleSuccess, } from "../../../utils/utility/snackbar"
import { raiseTabDTO } from "../dtos/raiseTab"
import { getInvestmentRangesData, getInvestmentRoundsData, getInvestmentTypesData, getInvestorStyleData, getInvestorTraitsData, getNoOfRoundsData, getValuationRangesData } from "../dropdowns"

export const loadRaiseTabData = (id, adminAnalysisTab, adminDropdowns) => {
  if (!adminAnalysisTab.raiseTabData) {
    getRaiseDetails(id)
  }
  loadRaiseTabDropdowns(adminDropdowns.investmentRounds, adminDropdowns.investmentRanges, adminDropdowns.investmentTypes, adminDropdowns.valuationRanges, adminDropdowns.noOfRounds, adminDropdowns.investorStyle, adminDropdowns.investorTraits)
}

export const getRaiseDetails = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/raise`)
    .then(res => {
      dispatch(Actions.AdminUser.SetLoading, false)
      dispatch(Actions.AdminAnalysisTab.SetRaiseTabData, raiseTabDTO(res.data.data))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err)
    })
}

export const updateRaiseDetails = (id, data) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  return ADMIN_API().put(ADMIN_PROPTECHS + `/${id}/raise`, data)
    .then(res => {
      handleSuccess(res)
      getRaiseDetails(id)
      return res?.status;
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err);
      return err?.response?.status;
    })
}

export const loadRaiseTabDropdowns = (investmentRounds, investmentRanges, investmentTypes, valuationRanges, noOfRounds, investorStyle, investorTraits) => {
  if (!investmentRounds.length) {
    getInvestmentRoundsData();
  }
  if (!investmentRanges.length) {
    getInvestmentRangesData();
  }
  if (!investmentTypes.length) {
    getInvestmentTypesData();
  }
  if (!valuationRanges.length) {
    getValuationRangesData();
  }
  if (!noOfRounds.length) {
    getNoOfRoundsData();
  }
  if (!investorStyle.length) {
    getInvestorStyleData();
  }
  if (!investorTraits.length) {
    getInvestorTraitsData();
  }
}