import React, { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import ProptechCardLabel from '../ProptechCardLabel';
import PTButton from '../../../components/GenericComponents/PTButton';
import { updateActivetatus, updateArchiveStatus } from '../../../utils/middleware/_admin/Proptechs';
import { Store } from '../../../store/configureStore';
import { Actions } from '../../../store/actions';
import { dispatch, dispatchNoPayload } from '../../../utils/utility/store';
import ArrowRightIcon from '../../../assets/Icons/admin-arrow-right.svg';
import ArrowOpened from '../../../assets/Icons/admin-arrow-opened.svg';
import EyesIcon from '../../../assets/Icons/admin-eyes.svg';
import defaultProfile from '../../../assets/Icons/default-profile.svg';
import './ProptechCard.scss';
import Loader from '../../../components/Loading';
import ConfirmModal from '../ConfirmModal';
import { setActivetabData } from '../../utils/middleware/adminProptechTab';
import { showMessage } from '../../../utils/utility/snackbar';
import { UNSAVED_CHANGES_MESSAGE } from '../../constants';
import { MessageType } from '../../../constants/General';

export default function ProptechCard(props) {
  //  **** props ****
  const { companyDetails, unsaveChange } = props

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [activeStatus, setActiveStatus] = useState(null);

  // Store
  const isProptechSelected = useSelector(state => state.adminUser.isProptechSelected)
  const selectedProptech = useSelector(state => state.adminUser.selectedProptech)
  const activeTabIndex = useSelector(state => state.adminUser.activeTabIndex)
  const adminDropdowns = useSelector(state => state.adminDropdowns)
  const adminAnalysisTab = useSelector(state => state.adminAnalysisTab)
  const { activeSort, sortOrder, isArchived, search } = useSelector(state => state.adminUser);

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleActiveStatus = async (statusCode) => {
    setIsLoading(true)
    try {
      await updateActivetatus(companyDetails?.id, { 'status': statusCode }, companyDetails, isArchived, activeSort, sortOrder, search);
      setOpenModal2(false)
      setIsLoading(false)
    } catch {
      setIsLoading(false)
      setOpenModal2(false)
      console.log('error')
    }
  }

  const handleSelected = () => {
    if (!unsaveChange.current) {
      let isSelected = selectedProptech?.id !== companyDetails.id ? true : !isProptechSelected
      Store.dispatch({ type: Actions.AdminUser.SetIsSelectedProptech, payload: isSelected })
      dispatch(Actions.AdminAnalysisTab.SetActiveAnalysisTab, 0)
      if (isSelected) {
        if (companyDetails.id !== selectedProptech?.id) {
          dispatchNoPayload(Actions.AdminAnalysisTab.AdminAnalysisTabReset)
          dispatch(Actions.AdminUser.SetForYouProptechs, [])
          dispatch(Actions.AdminUser.SetWatchlistProptechs, [])
        }
        setActivetabData(activeTabIndex, companyDetails?.id, adminAnalysisTab, adminDropdowns)
      }
      Store.dispatch({ type: Actions.AdminUser.SetSelectedProptech, payload: companyDetails })
    } else {
      showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
    }
  }

  const handleArchive = async () => {
    setIsLoading(true)
    try {
      await updateArchiveStatus(companyDetails?.id, { isArchived: !companyDetails?.isArchived }, companyDetails, isArchived, activeSort, sortOrder, search);
      handleCloseModal()
      setIsLoading(false)
    } catch {
      setIsLoading(false)
      console.log('error')
    }
  }

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className='border-solid border-t border-[#111D43] opacity-40' />
      <div
        id={`proptech-${companyDetails?.id}`}
        className={`w-full cursor-pointer 
        ${companyDetails?.isPublished ? `bg-[#DFEEE2]`       //Publish
            : companyDetails?.status === 0 ? 'bg-[#ffffff]'  //Pending
              : companyDetails?.status === 2 ? 'bg-[#E7E8EC]'//Rejected
                : `bg-[#FF410026]`}
        `}
        onClick={() => handleSelected()}
      >
        <div className='proptech-tab-heading'>
          <div className='flex box-border items-center '>
            <div className='profile-img mr-[15px] w-[35px] h-[35px] xlg:h-[46px] xlg:w-[46px] rounded-full min-w-[35px] xlg:min-w-[46px]'>
              <img
                src={companyDetails?.companyLogo || defaultProfile}
                className="rounded-full bg-[#70778E] h-full w-full object-cover"
                alt="Profile"
              />
            </div>
            <div className='grow'>
              <div className='flex justify-between'>
                <span className='font-medium text-sm leading-5 not-italic text-[#111D43]'>{companyDetails.proptechCompany.name}</span>
                <div className='eye-icon'>
                  <img src={EyesIcon} alt="Icons" width={22} onClick={(e) => {
                    e.stopPropagation()
                    if (!unsaveChange.current) {
                      dispatch(Actions.AdminUser.SetSelectedProptech, companyDetails)
                      dispatch(Actions.AdminUser.SetPreview, true)
                    } else {
                      showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
                    }
                  }} />
                </div>
              </div>
              <div className='flex justify-between'>
                <ProptechCardLabel
                  header="Published"
                  className="xlg:min-w-[104px]"
                  label={companyDetails?.isPublished ? moment.tz(companyDetails?.publishedAt, "Australia/Sydney").format('DD MMM YYYY') : '-'}
                  style={{ marginRight: '4px' }} />
                <ProptechCardLabel
                  header="Edited"
                  className="xlg:min-w-[90px]"
                  label={moment.tz(companyDetails?.updatedAt, "Australia/Sydney").format('DD MMM YYYY')}
                  style={{ marginRight: '4px' }}
                />
                <ProptechCardLabel header="Meetings" label={companyDetails?.totalMeetingCount || 0} />
              </div>
            </div>
            <img src={isProptechSelected && selectedProptech.id === companyDetails.id ? ArrowOpened : ArrowRightIcon} alt="Icon" className='ml-[20px]' />
          </div>
          <div className='w-full box-border items-center flex justify-end proptech-heading-btn'>
            {companyDetails?.status === 0 ?
              <PTButton
                variant="contained"
                label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Reject</span>}
                type="submit"
                className='mx-3 !rounded-xl !border-solid !bg-[#111D43]'
                handleOnClickEvent={(e) => {
                  e.stopPropagation()
                  if (!unsaveChange.current) {
                    setOpenModal2(true)
                    setActiveStatus(2)
                  } else {
                    showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
                  }
                }}
              />
              : null}
            {companyDetails?.status !== 1 ?
              <PTButton
                variant="contained"
                label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Accept</span>}
                type="submit"
                className='mx-3 !rounded-xl !border-solid !bg-[#111D43]'
                handleOnClickEvent={(e) => {
                  e.stopPropagation()
                  if (!unsaveChange.current) {
                    setOpenModal2(true)
                    setActiveStatus(1)
                  } else {
                    showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
                  }
                }}
              />
              : null}
          </div>
        </div>
      </div>
      {
        isProptechSelected && selectedProptech.id === companyDetails.id &&
        <>
          <div className='border-solid border-t border-[#111D43] opacity-40' />
          <div className={` w-full box-border 
            ${companyDetails?.isPublished ? `bg-[#DFEEE2]`
              : companyDetails?.status === 0 ? 'bg-[#ffffff]'
                : companyDetails?.status === 2 ? 'bg-[#E7E8EC]'
                  : `bg-[#FF410026]`} 
            detail-block`}>
            <div className='sidebar-info-wrap'>
              <span className='detail-label'>DATE CREATED</span>
              <p className='text-sm'>{companyDetails?.createdAt ? moment(companyDetails?.createdAt).format('DD MMM YYYY') : '-'}</p>
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>WEBSITE URL</span>
              <p className='text-sm'>{companyDetails?.websiteURL || `-`}</p>
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>FULL NAME</span>
              <p className='text-sm'>{companyDetails?.proptechUser?.primaryUserName || `-`}</p>
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>contact email</span>
              <p className='text-sm'>{companyDetails.primaryUserEmail || `-`}</p>
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>ASSOCIATED CONTACTS</span>
              {companyDetails?.proptechCompany?.proptechUsers.length ? companyDetails.proptechCompany.proptechUsers.map((user) => {
                return (
                  <PTButton
                    key={user?.id}
                    variant="contained"
                    label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">{user?.name}</span>}
                    type="submit"
                    className='!rounded-xl !bg-[rgba(17,29,67,0.1)] mr-3 mb-2 gray-btn'
                    handleOnClickEvent={() => {
                      dispatch(Actions.Admin.SetActiveAdminTab, 4)
                      dispatch(Actions.Contacts.SetAlreadySelectedContact, { id: user?.id, userType: 'Proptech' })
                      dispatch(Actions.Contacts.SetIsSelectedContact, true)
                    }}
                  />
                )
              }) : `-`}
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>LINKEDIN URL</span>
              <p className='text-sm'>{companyDetails?.linkedInURL || `-`}</p>
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>INVESTMENT ROUND</span>
              <p className='text-sm'>{companyDetails?.investmentRound?.name || `-`}</p>
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>INVESTMENT RANGE</span>
              <p className='text-sm'>{companyDetails?.investmentRange?.name || `-`}</p>
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>VENTURE SCOUT CODE (OPTIONAL)</span>
              <p className='text-sm'>{companyDetails?.ventureScoutCode || `n/a`}</p>
            </div>
            <div className='flex justify-between items-center'>
              <PTButton
                label={`${companyDetails?.isArchived ? `UNARCHIVE` : `ARCHIVE`}`}
                color='primary'
                size='large'
                className='text-sm btn'
                handleOnClickEvent={() => {
                  if (!unsaveChange.current) {
                    handleOpenModal()
                  } else {
                    showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
                  }
                }} />
              <p className='font-bold'>{companyDetails?.isPublished ? `PUBLISHED` : `UNPUBLISHED`}</p>
            </div>
          </div>
        </>
      }

      {openModal && <ConfirmModal open={openModal} handleCloseModal={handleCloseModal} handleSucces={handleArchive} />}
      {openModal2 && <ConfirmModal open={openModal2} handleCloseModal={() => setOpenModal2(false)} handleSucces={() => handleActiveStatus(activeStatus)} />}
    </>
  )
}
