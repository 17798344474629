import { VC_FORYOU, VC_INVITE_API, VC_PROPTECH_LISTING, VC_WATCHLIST, VC_FEATURELIST } from "../../../../constants/APIs"
import { Actions } from "../../../../store/actions"
import { Store } from "../../../../store/configureStore"
import { VC_API } from "../../../../utils/utility/API-call"
import { handleError, handleSuccess } from "../../../../utils/utility/snackbar"
import { dispatch } from "../../../../utils/utility/store"
import { forYouDTO } from "../../dtos/forYouDTO"
import { getProptechDetailsAPI } from "../ProptechDetails"
import { featuredListDTO } from "../../dtos/featuredListDTO"

export const getProptechs = async (filterFields, orderBy, order, revenue, page = 1, size = 10) => {
  const params = {
    filter: filterFields || revenue ? { ...filterFields, revenueRangeId: revenue || undefined } : undefined,
    page: page,
    size: size,
    orderBy: orderBy || undefined,
    order: order || undefined,
  }
  return VC_API().get(VC_PROPTECH_LISTING, { params }).then((res) => {
    if (res?.data) {
      if (res?.data?.data?.rows) {
        dispatch(Actions.VCProptechs.VCProptechsSet, res?.data?.data)
        return res?.data?.data
      }
    }
  }).catch((err) => {
    handleError(err)
    return err?.status;
  })
}

export const getWatchlist = async (page, size) => {
  const params = {
    page: page,
    size: size,
  }
  Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: true });
  return VC_API().get(VC_WATCHLIST, { params }).then((res) => {
    Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
    if (res?.data) {
      if (res?.data?.data?.rows) {
        dispatch(Actions.VCProptechs.VCWatchlistSet, res?.data?.data)
        return res?.data?.data
      }
    }
  }).catch((err) => {
    Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
    handleError(err)
    return err?.status;
  })
}

export const setWatchlistStatus = async (id, tab, isFavorite, vcProptechs, vcForYou) => {
  Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: true });
  return VC_API().put(`${VC_PROPTECH_LISTING}/${id}/favorites`, { "isFavorite": !isFavorite }).then((res) => {
    Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
    handleSuccess(res)
    if (res?.data?.code === 200) {
      if (tab === 0) {
        getProptechs(vcProptechs?.filter, vcProptechs?.orderBy, vcProptechs?.order, vcProptechs?.revenueRangeId, vcProptechs?.page + 1, vcProptechs?.size);
      } else if (tab === 1) {
        getForyou(vcProptechs?.filter, vcProptechs?.orderBy, vcProptechs?.order, vcProptechs?.revenueRangeId, vcForYou?.forYouPage + 1, vcForYou?.forYouPageSize)
      } else if (tab === 2) {
        getWatchlist()
      } else if (tab === 'proptechDetails') {
        getProptechDetailsAPI(id)
        getProptechs(vcProptechs?.filter, vcProptechs?.orderBy, vcProptechs?.order, vcProptechs?.revenueRangeId, vcProptechs?.page + 1, vcProptechs?.size);
        getWatchlist()
      }
      return res?.data?.data
    }
  }).catch((err) => {
    Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
    handleError(err)
    return err?.status;
  })
}

export const updateNote = async (id, note, tab, vcProptechs, vcForYou) => {
  Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: true });
  return VC_API().put(`${VC_PROPTECH_LISTING}/${id}/notes`, { "note": note }).then((res) => {
    handleSuccess(res)
    Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
    if (res?.data?.data) {
      if (tab === 0) {
        getProptechs(vcProptechs?.filter, vcProptechs?.orderBy, vcProptechs?.order, vcProptechs?.revenueRangeId, vcProptechs?.page + 1, vcProptechs?.size);
      } else if (tab === 1) {
        getForyou(vcProptechs?.filter, vcProptechs?.orderBy, vcProptechs?.order, vcProptechs?.revenueRangeId, vcForYou?.forYouPage + 1, vcForYou?.forYouPageSize)
      } else if (tab === 2) {
        getWatchlist()
      } else if (tab === 'proptechDetails') {
        getProptechDetailsAPI(id)
        getProptechs(vcProptechs?.filter, vcProptechs?.orderBy, vcProptechs?.order, vcProptechs?.revenueRangeId, vcProptechs?.page + 1, vcProptechs?.size);
        getWatchlist()
      }
      return res?.data?.data?.note
    }
  }).catch((err) => {
    Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
    handleError(err)
    return err?.status;
  })
}

export const getFeaturedlist = async () => {
  // dispatch(Actions.VCProptechs.VCSetLoading, true);
  return VC_API().get(VC_FEATURELIST).then((res) => {
    // dispatch(Actions.VCProptechs.VCSetLoading, false);
    if (res?.data && res?.data?.data?.rows) {
      dispatch(Actions.VCProptechs.VCSetFeaturedList, featuredListDTO(res?.data?.data))
      return res?.data?.data
    }
  }).catch((err) => {
    // dispatch(Actions.VCProptechs.VCSetLoading, false);
    handleError(err)
    return err?.status;
  })
}

export const getForyou = async (filterFields, orderBy, order, revenue, page = 1, size = 10) => {
  const params = {
    filter: filterFields || revenue ? { ...filterFields, revenueRangeId: revenue || undefined } : undefined,
    page: page,
    size: size,
    orderBy: orderBy || undefined,
    order: order || undefined,
  }
  dispatch(Actions.VCProptechs.VCSetLoading, true);
  return VC_API().get(VC_FORYOU, { params }).then((res) => {
    dispatch(Actions.VCProptechs.VCSetLoading, false);
    if (res?.data && res?.data?.data?.rows) {
      dispatch(Actions.VCForYou.VCForyouSet, forYouDTO(res?.data?.data));
      return res?.data?.data
    }
    handleError(res?.data)
  }).catch((err) => {
    dispatch(Actions.VCProptechs.VCSetLoading, false);
    handleError(err)
    return err?.status;
  })
}

export const inviteMember = async (email, message) => {
  dispatch(Actions.VCProptechs.VCSetLoading, true);
  return VC_API().post(`${VC_INVITE_API}`, { "email": email, "message": message.trim() }).then((res) => {
    handleSuccess(res)
    dispatch(Actions.VCProptechs.VCSetLoading, false);
  }).catch((err) => {
    Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
    handleError(err)
    return err?.status;
  })
}

export const shareProptech = (proptechId, emailIds, message) => {
  let params = {
    vcUserIds: emailIds,
    message: message.trim()
  }
  Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: true });
  return VC_API().put(`${VC_PROPTECH_LISTING}/${proptechId}/share`, params).then((res) => {
    Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
    handleSuccess(res)
    return res?.data?.data
  }).catch((err) => {
    Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
    handleError(err)
    return err?.status;
  })
}
