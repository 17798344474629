export function adminForYouProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        updatedAt: datum?.updatedAt || '',
        vcCompanyDetails: datum?.vcCompanyDetails ? {
          id: datum?.vcCompanyDetails?.id || 0,
          companyLogo: datum?.vcCompanyDetails?.companyLogo || '',
          vcCompany: datum?.vcCompanyDetails?.vcCompany ? {
            id: datum?.vcCompanyDetails?.vcCompany?.id || 0,
            name: datum?.vcCompanyDetails?.vcCompany?.name || '',
          } : null
        } : null,
        vcProptechForYouTag: datum?.vcProptechForYouTag?.length !== 0 ? datum?.vcProptechForYouTag.map(tag => {
          return {
            id: tag?.tag?.id || 0,
            name: tag?.tag?.name || '',
          }
        }) : [],
      }
    })
  }

  return proptechs;
}

export function adminWatchlistProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        createdAt: datum?.createdAt || '',
        vcCompanyDetails: datum?.vcCompanyDetails ? {
          id: datum?.vcCompanyDetails?.id || 0,
          companyLogo: datum?.vcCompanyDetails?.companyLogo || '',
          vcCompany: datum?.vcCompanyDetails?.vcCompany ? {
            id: datum?.vcCompanyDetails?.vcCompany?.id || 0,
            name: datum?.vcCompanyDetails?.vcCompany?.name || '',
          } : null
        } : null,
      }
    })
  }

  return proptechs;
}