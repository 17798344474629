import axios from 'axios';
import { configs } from '../../constants/Configs';
import { getAdminAccessToken, getProptechAccessToken, getVcAccessToken, getVsAccessToken } from '../session';

export const PROPTECH_API = () => {
  return axios.create({
    baseURL: '',
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getProptechAccessToken(),
    },
  });
}

export const ADMIN_API = () => {
  return axios.create({
    baseURL: '',
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAdminAccessToken(),
    },
  });
}

export const VC_API = () => {
  return axios.create({
    baseURL: '',
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getVcAccessToken(),
    },
  });
}

export const VS_API = () => {
  return axios.create({
    baseURL: '',
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getVsAccessToken(),
    },
  });
}

export const CALENDLY_API = () => {
  return axios.create({
    baseURL: '',
    timeout: 30000,
    headers: { 'Authorization': 'Bearer ' + configs.calendlyToken }
  });
}
