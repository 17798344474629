export default function adminDropdowns(
  initialState = {
    countries: [],
    revenueRanges: [],
    megaTrends: [],
    languages: [],
    techBuyers: [],
    chargingModels: [],
    valueKPIs: [],
    sectors: [],
    useCases: [],
    techOfferings: [],
    noOfCustomers: [],
    noOfCountries: [],
    grossMargins: [],
    growthBlockers: [],
    investmentRounds: [],
    investmentRanges: [],
    investmentTypes: [],
    valuationRanges: [],
    noOfRounds: [],
    alignments: [],
    noOfEmployees: [],
    teamSkills: [],
    keyHireMappings: [],
    keyBizModels: [],
    trends: [],
    predictions: [],
    growth: [],
    needToKnow: [],
    investorStyle: [],
    investorTraits: [],
    assetLifeCycles: [],
    defensibilityTimings: [],
    tags: [],
  },
  action
) {
  switch (action.type) {
    case 'SET_ADMIN_COUNTRIES_DROPDOWN':
      return {
        ...initialState,
        countries: action.payload,
      };

    case 'SET_ADMIN_REVENUERANGES_DROPDOWN':
      return {
        ...initialState,
        revenueRanges: action.payload,
      };

    case 'SET_ADMIN_MEGATRENDS_DROPDOWN':
      return {
        ...initialState,
        megaTrends: action.payload,
      };

    case 'SET_ADMIN_LANGUAGES_DROPDOWN':
      return {
        ...initialState,
        languages: action.payload,
      };

    case 'SET_ADMIN_TECH_BUYERS_DROPDOWN':
      return {
        ...initialState,
        techBuyers: action.payload,
      };

    case 'SET_ADMIN_CHARGING_MODELS_DROPDOWN':
      return {
        ...initialState,
        chargingModels: action.payload,
      };

    case 'SET_ADMIN_VALUE_KPIS_DROPDOWN':
      return {
        ...initialState,
        valueKPIs: action.payload,
      };

    case 'SET_ADMIN_SECTORS_DROPDOWN':
      return {
        ...initialState,
        sectors: action.payload,
      };

    case 'SET_ADMIN_USE_CASES_DROPDOWN':
      return {
        ...initialState,
        useCases: action.payload,
      };

    case 'SET_ADMIN_TECH_OFFERINGS_DROPDOWN':
      return {
        ...initialState,
        techOfferings: action.payload,
      };

    case 'SET_ADMIN_NO_OF_CUSTOMERS_DROPDOWN':
      return {
        ...initialState,
        noOfCustomers: action.payload,
      };

    case 'SET_ADMIN_NO_OF_COUNTRIES_DROPDOWN':
      return {
        ...initialState,
        noOfCountries: action.payload,
      };

    case 'SET_ADMIN_GROSS_MARGINS_DROPDOWN':
      return {
        ...initialState,
        grossMargins: action.payload,
      };

    case 'SET_ADMIN_GROWTH_BLOCKERS_DROPDOWN':
      return {
        ...initialState,
        growthBlockers: action.payload,
      };


    case 'SET_ADMIN_INVESTMENT_ROUNDS_DROPDOWN':
      return {
        ...initialState,
        investmentRounds: action.payload,
      };


    case 'SET_ADMIN_INVESTMENT_RANGES_DROPDOWN':
      return {
        ...initialState,
        investmentRanges: action.payload,
      };


    case 'SET_ADMIN_INVESTMENT_TYPES_DROPDOWN':
      return {
        ...initialState,
        investmentTypes: action.payload,
      };


    case 'SET_ADMIN_VALUATION_RANGES_DROPDOWN':
      return {
        ...initialState,
        valuationRanges: action.payload,
      };


    case 'SET_NO_OF_ROUNDS_DROPDOWN':
      return {
        ...initialState,
        noOfRounds: action.payload,
      };

    case 'SET_ALIGNMENTS_DROPDOWN':
      return {
        ...initialState,
        alignments: action.payload,
      };

    case 'SET_NO_OF_EMPLOYEES_DROPDOWN':
      return {
        ...initialState,
        noOfEmployees: action.payload,
      };

    case 'SET_TEAM_SKILLS_DROPDOWN':
      return {
        ...initialState,
        teamSkills: action.payload,
      };

    case 'SET_KEY_HIRE_MAPPINGS_DROPDOWN':
      return {
        ...initialState,
        keyHireMappings: action.payload,
      };

    case 'SET_KEY_BIZ_MODELS_DROPDOWN':
      return {
        ...initialState,
        keyBizModels: action.payload,
      };

    case 'SET_TRENDS_DROPDOWN':
      return {
        ...initialState,
        trends: action.payload,
      };

    case 'SET_PREDICTIONS_DROPDOWN':
      return {
        ...initialState,
        predictions: action.payload,
      };

    case 'SET_GROWTH_DROPDOWN':
      return {
        ...initialState,
        growth: action.payload,
      };

    case 'SET_NEED_TO_KNOW_DROPDOWN':
      return {
        ...initialState,
        needToKnow: action.payload,
      };

    case 'SET_INVESTOR_STYLE_DROPDOWN':
      return {
        ...initialState,
        investorStyle: action.payload,
      };

    case 'SET_INVESTOR_TRAITS_DROPDOWN':
      return {
        ...initialState,
        investorTraits: action.payload,
      };

    case 'SET_ASSET_LIFE_CYCLES_DROPDOWN':
      return {
        ...initialState,
        assetLifeCycles: action.payload,
      };

    case 'SET_DEFENSIBILITY_TIMINGS_DROPDOWN':
      return {
        ...initialState,
        defensibilityTimings: action.payload,
      };

    case 'SET_TAGS_DROPDOWN':
      return {
        ...initialState,
        tags: action.payload,
      };

    case 'SET_ADMIN_DROPDOWNS_RESET':
      return {
        ...initialState,
        countries: [],
        revenueRanges: [],
        megaTrends: [],
        languages: [],
        techBuyers: [],
        chargingModels: [],
        valueKPIs: [],
        sectors: [],
        useCases: [],
        techOfferings: [],
        noOfCustomers: [],
        noOfCountries: [],
        grossMargins: [],
        growthBlockers: [],
        investmentRounds: [],
        investmentRanges: [],
        investmentTypes: [],
        valuationRanges: [],
        noOfRounds: [],
        alignments: [],
        noOfEmployees: [],
        teamSkills: [],
        keyHireMappings: [],
        keyBizModels: [],
        trends: [],
        predictions: [],
        growth: [],
        needToKnow: [],
        investorStyle: [],
        investorTraits: [],
        assetLifeCycles: [],
        defensibilityTimings: [],
        tags: [],
      };

    default:
      return initialState;
  }
}
