import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import ContactsTab from '../../components/Contacts/ContactsTab';
import InvestorTab from '../../components/Investor/InvestorTab';
import LeftNavbar from '../../components/LeftNavbar';
import NewsfeedTab from '../../components/Newsfeed/NewsfeedTab';
import TopBar from '../../components/TopBar';
import VentureScoutTab from '../../components/VentureScout/VentureScoutTab';
import ProptechTab from '../ProptechTab';
import './Dashboard.scss';

export default function Dashboard() {
  // Store
  const activeTab = useSelector(state => state.admin.activeTab)

  const unsaveChange = useRef(false);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        className='h-[calc(100%-75px)] md:h-full overflow-auto md:overflow-inherit'
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className='md:h-[calc(100%-90px)] xlg:h-[calc(100%-120px)]'>{children}</div>
        )}
      </div>
    );
  }

  const setUnsaveChange = value => {
    unsaveChange.current = value
  }

  return (
    <>
      <div className='w-screen h-screen'>
        <div className="h-screen w-screen flex admin-dashboard-wrapper">
          <LeftNavbar value={activeTab} unsaveChange={unsaveChange} />
          <div className='w-full right-col'>
            <TopBar />
            <TabPanel value={activeTab} index={0}>
              <ProptechTab unsaveChange={unsaveChange} setUnsaveChange={setUnsaveChange} />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <InvestorTab unsaveChange={unsaveChange} setUnsaveChange={setUnsaveChange} />
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <NewsfeedTab unsaveChange={unsaveChange} setUnsaveChange={setUnsaveChange} />
            </TabPanel>
            <TabPanel value={activeTab} index={3}>
              <VentureScoutTab unsaveChange={unsaveChange} setUnsaveChange={setUnsaveChange} />
            </TabPanel>
            <TabPanel value={activeTab} index={4}>
              <ContactsTab unsaveChange={unsaveChange} setUnsaveChange={setUnsaveChange} />
            </TabPanel>
          </div>
        </div >
      </div >
    </>
  )
}
