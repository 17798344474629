import React from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '../../../../utils/utility/store';
import { Actions } from '../../../../store/actions';
import Loader from '../../../../components/Loading'
import ProptechListCard from '../ForYouTab/ProptechListCard'
import { setActiveTab } from '../../../utils/middleware/adminInvestors';

export default function WatchlistTab() {
  // Store
  const watchlistProptechs = useSelector(state => state.adminUser.watchlistProptechs)
  const isLoading = useSelector(state => state.adminUser.isLoading)
  const adminDropdowns = useSelector(state => state.adminDropdowns);
  const adminInvestor = useSelector(state => state.adminInvestor)

  const handleCardClick = (id) => {
    dispatch(Actions.Admin.SetActiveAdminTab, 1)
    dispatch(Actions.AdminInvestor.SetActiveTab, 0)
    dispatch(Actions.Admin.SetIsRedirect, true)
    dispatch(Actions.AdminInvestor.SetIsSelectedInvestor, true)
    dispatch(Actions.AdminInvestor.SetSelectedInvestor, { id })
    setActiveTab(0, id, adminInvestor, adminDropdowns)
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className='overflow-y-auto'>
        {!!watchlistProptechs?.length && watchlistProptechs.map((proptech, index) => {
          return <ProptechListCard key={index} logo={proptech?.vcCompanyDetails?.companyLogo} onCardClick={() => handleCardClick(proptech?.vcCompanyDetails?.id)} title={proptech.vcCompanyDetails.vcCompany.name} updatedAt={proptech.createdAt} />
        })}
      </div>
    </>
  )
}
